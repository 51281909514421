import { useSelector } from "react-redux";
import FirstRegistryForm from "src/components/staticForms/first";

const AddUserByOtherAdmin = () => {

    const admin = useSelector((store) => store.admin.data);


    return (
        <>
            <FirstRegistryForm
                {...{
                    
                    miniForm : true
                }} />
        </>
    );
}

export default AddUserByOtherAdmin;