export default function RemoveNullObjectValue(params) {
  return Object.entries(params).reduce((prev, [key, value]) => {
    // console.log('* * * RemoveNullObjectValue : ', { key, value });
    return ![null, undefined].includes(value) ? { ...prev, [key]: value } : prev;
  }, {});
}

export function removeFalseValues(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => value !== false)
  );
}