/* eslint-disable camelcase */
import { LoadingButton } from "@mui/lab";
import { Box, Tooltip } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import api from "src/services/api";
import axiosInstance from "src/utils/axios";

/* eslint-disable arrow-body-style */
const TaahodNameBtn = ({client_id}) => {
    const queryParams = useParams();
    const { id, travel } = queryParams;

    const [IsLoading, setIsLoading] = useState(false)

    const handleDownloadZamenWord = (e) => {
        e.preventDefault();
        setIsLoading(true);
        axiosInstance
            .get(`${api.client.word}?travel_id=${travel}&client_id=${client_id}`, {
                responseType: 'blob'
            })
            .then(({ data }) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;

                link.setAttribute('download', 'تعهدنامه.docx');

                document.body.appendChild(link);
                link.click();

                link.remove();
                window.URL.revokeObjectURL(url);
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
                console.log({ wordErr: err.response, err, message: err.response.data?.message })
                toast.error(err.response?.message)
                if (err.response && err.response.status === 404) {
                    // Convert the error blob to text to read the error message
                    const reader = new FileReader();
                    reader.onload = () => {
                        const errorMessage = JSON.parse(reader.result)?.message;
                        toast.error(errorMessage)
                    };
                    reader.onerror = () => {
                        console.log("Failed to read error message");
                    };
                    reader.readAsText(err.response.data); // Reads the error blob as text
                } else {
                    console.log("Error:", err.message); // General error message
                }

            });
    };

    return (
        <>
            <Box
                onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
            >
                <Tooltip title={"بعد از ثبت اطلاعات ضامن و اطلاعات تکمیلی خود میتوانید فرم تعهدنامه را دانلود کنید."} arrow>
                    <LoadingButton variant="contained" color={'success'} onClick={e => handleDownloadZamenWord(e)} loading={IsLoading} sx={{
                        position:"relative",
                        zIndex:999,
                        mt:1
                    }}>
                        دانلود تعهدنامه
                    </LoadingButton>
                </Tooltip>

            </Box>
        </>
    );
}

export default TaahodNameBtn;