import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';
import { haveHasType, caravanType, travelTypeObject } from '../../../../../enumeration';
import { fDate, fDateJalali, fDateTime } from '../../../../../utils/formatTime';
import Enum from '../../../enum';
import MoreTableItem from '../../../../../components/table/MoreTable/MoreTableItem';
import MoreTable from '../../../../../components/table/MoreTable/index';

export const tableData = ({ baseRoute }) => [
  {
    key: 'type',
    header: 'حج',
    Component: ({ param }) => travelTypeObject[param?.travel?.type]?.label || '-',
  },
  {
    key: 'type',
    header: 'سفر',
    Component: ({ param }) => param?.travel?.name || '-',
  },
  {
    key: 'status',
    header: 'وضعیت',
    Component: ({ param }) => param?.status || '-',
  },
  // {
  //   key: 'createdAt',
  //   header: 'تاریخ',
  //   Component: ({ param }) => fDateTime(param?.client?.created_at) || '-',
  // },
  // {
  //   key: 'actions',
  //   header: '',
  //   Component: ({ param }) => (
  //     <MoreTable>
  //       <Link to={`${baseRoute + Enum.routes.edit}/${param?.id}`}>
  //         <MoreTableItem isEdit />
  //       </Link>
  //       {/* <MoreTableItem isDelete onClick={() => onDelete(param)} /> */}
  //     </MoreTable>
  //   ),
  //   free: true,
  // },
  {
    key: 'show',
    header: '',
    Component: ({ param }) => (
      <Link to={`${baseRoute}/${param.id}?travel=${param?.travel?.id}`}>
        <KeyboardBackspaceIcon
          sx={{
            cursor: 'pointer',
          }}
        />
      </Link>
    ),
    free: true,
  },
];
