import { PATH_DASHBOARD } from "src/routes/paths";
import api from "src/services/api";

const EAgent = {
  title: {
    name: ['تاریخچه گزارش گیری ها', 'تاریخچه گزارش گیری ها'],
  },
  routes: PATH_DASHBOARD.report,
  api: api.report,
};

export default EAgent;
