import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Modal } from '@mui/material';
import { Link, Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../../../utils/axios';
import { useMutationCustom, useQueryCustom } from '../../../../../utils/reactQueryHooks';
import useQueryString from '../../../../../utils/useQueryString';
import Enum from '../enum';
import Page from '../../../../../components/Page';
import HeaderBreadcrumbs from '../../../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../../../routes/paths';
import useSettings from '../../../../../hooks/useSettings';
import Iconify from '../../../../../components/Iconify';
import TableComponent from '../../../../../components/table/TableComponent';
import { tableData } from './tableInfo';
import useTable from '../../../../../hooks/useTable';
import { hasAccess } from '../../../../../permission/utiles';
import ShowCaravansManagementLayout from '../../Layout';

const List = ({ access }) => {
  const { t } = useTranslation();
  const queryParams = useParams();
  const queryString = useQueryString();
  const backUrl = `${Enum.routes.root}`;
  const paramId = queryParams?.id;
  const currentBase = `${backUrl}/show/${paramId}`;

  // ---------------------------------------------------------------------
  const getApi = async () =>
    axiosInstance.get(`${Enum?.api?.base}`, { params: { ...queryString, client_id: queryParams.id } });

  const deleteApi = async ({ id }) => axiosInstance.delete(`${Enum?.api?.base}/${id}`);
  // ---------------------------------------------------------------------

  const { themeStretch } = useSettings();

  const tableSetting = useTable({ queryString });
  console.log({ tableSetting, queryParams });
  // ------------------------------------------------------- query Get
  const { data: { data = {} } = {}, isLoading } = useQueryCustom({
    name: `${Enum?.api?.base}_list`,
    url: getApi,
    params: { ...queryString },
  });
  // ------------------------------------------------------- query delete

  const deleteInput = useMutationCustom({
    url: deleteApi,
    name: `${Enum?.api?.base}_delete`,
    invalidQueries: [`${Enum?.api?.base}_list`],
    onSuccess: () => {
      toast.success(t('question.delete.successfully'));
    },
    onError: (error) => {
      console.log({ error });
      const errorTitle = error.response.data.message || t('errorTryAgain');

      const errors = Object.values(error?.response?.data?.errors || {});
      if (errors?.length) {
        errors?.map((x) => {
          return toast.error(x?.[0]);
        });
      } else toast.error(errorTitle);
    },
  });
  const checkAccess = useMemo(() => {
    return {
      // update: hasAccess(access?.update),
      delete: hasAccess(access?.delete),
      create: hasAccess(access?.create),
      confirm: hasAccess(access?.confirm),
    };
  }, [access]);

  const title = `مدیریت ${Enum.title.name[1]} `;
  const tableOptions = tableData({
    baseRoute: `${Enum.routes.root}`,
    onDelete: (x) => deleteInput.mutate({ id: x?.id }),
    disabled: {
      update: !checkAccess?.update,
      delete: !checkAccess?.delete,
    },
  });

  console.log({ tableOptions, data });

  return (
    <>
      {/* <ShowCaravansManagementLayout> */}
      <Box sx={{
        mt: 2
      }}>
        <TableComponent
          active={{
            // selectable: true,
            rowNumber: true,
          }}
          headerSx={{
            width : "100%"
          }}
          topOfHeaderFilter={
            <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  ml: 'auto',
                }}
              >
                <Button
                  component={Link}
                  to={`${currentBase}/message/add?travel=${queryParams?.course}`}
                  variant="contained"
                  color={'success'}
                  startIcon={<Iconify icon={'eva:plus-fill'} />}
                >
                  {'ارسال پیام'}
                </Button>
              </Box>
            </>
          }
          options={tableOptions}
          data={data?.data}
          tableSetting={tableSetting}
          pagination={{
            totalPage: data?.meta?.last_page,
            total: data?.meta?.total,
          }}
          loading={isLoading}
        />
      </Box>
      {/* </ShowCaravansManagementLayout> */}
    </>
  );
};

export default List;
