import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { haveHasType, caravanType } from '../../../enumeration';
import { fDate, fDateJalali, fDateTime } from '../../../utils/formatTime';
import Enum from '../enum';
import TableMoreMenu from '../../../components/table/TableMoreMenu';
import Iconify from '../../../components/Iconify';
import MoreTable from '../../../components/table/MoreTable';
import MoreTableItem from '../../../components/table/MoreTable/MoreTableItem';

export const tableData = ({ baseRoute, onDelete, disabled }) => [
  {
    key: 'name',
    header: 'سفر',
    Component: ({ param }) => param?.name || '-',
  },
  {
    key: 'start_date',
    header: 'تاریخ شروع ثبت نام',
    Component: ({ param }) => (param?.registration_start_at && fDate(param?.registration_start_at)) || '-',
  },
  {
    key: 'finish_date',
    header: 'تاریخ پایان ثبت نام',
    Component: ({ param }) => (param?.registration_finish_at && fDate(param?.registration_finish_at)) || '-',
  },
  {
    key: 'show',
    header: '',
    Component: ({ param }) => (
      <>
        <Link to={`${baseRoute}/${param.id}/list`}>
          <KeyboardBackspaceIcon
            sx={{
              cursor: 'pointer',
            }}
          />
        </Link>
      </>
    ),
    free: true,
  },
];