import { Box, Button, Chip } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { acceptFinallyStatus, documentStatus } from 'src/enumeration';

import { Link } from 'react-router-dom';
import EProfile from 'src/pages/profiles/enum';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { fDateTime } from '../../../utils/formatTime';
import EVisitorManagement from '../../visitorManagement/enum';

export const tableData = () => [
  {
    key: 'name',
    header: 'نام و نام خانوادگی',
    Component: ({ param }) =>
      param?.client?.name || param?.client?.last_name ? (
        <Link
          to={`${PATH_DASHBOARD.profile.root}${PATH_DASHBOARD.profile.show}/${param?.client?.id}/${EProfile.enumTab.object.travels.value}/${param.id}`}
        >
          <Box sx={{ color: 'grey.900' }}>{`${param?.client?.name || '-'} ${param?.client?.last_name || '-'} ${
            param?.members > 1 ? `(${param?.members})` : ''
          }`}</Box>
        </Link>
      ) : (
        '-'
      ),
  },
  {
    key: 'nationalCode',
    header: 'کدملی',
    Component: ({ param }) => param?.client?.code_melli || '-',
  },
  {
    key: 'phone',
    header: 'تلفن همراه',
    Component: ({ param }) => param?.client?.phone || '-',
  },
  {
    key: 'shenasname_number',
    header: 'شماره شناسنامه',
    Component: ({ param }) => param?.client?.shenasname_number || '-',
  },
  // {
  //   key: 'reason',
  //   header: 'تشرف به صورت',
  //   Component: ({ param }) => param?.travel?.name || '-',
  // },

  {
    key: 'bank_status',
    header: ' وضعیت بانکی',
    filter: {
      lookup: Object.values(documentStatus),
    },
    Component: ({ param }) => (
      <Chip label={param?.bank_status} color={EVisitorManagement.status.object[param?.bank_status_num]?.color} />
    ),
  },
  {
    key: 'status',
    header: ' وضعیت',
    filter: {
      lookup: Object.values(acceptFinallyStatus),
      multiple: true,
    },
    Component: ({ param }) => (
      <Chip label={param?.status} color={EVisitorManagement.status.object[param?.status_num]?.color} />
    ),
  },
  {
    key: 'createdAt',
    header: 'تاریخ',
    Component: ({ param }) => param?.client && fDateTime(param?.client?.created_at) || '-',
  },
  {
    key: 'action',
    header: '',
    Component: ({ param }) => (
      <Button
        LinkComponent={Link}
        to={`${PATH_DASHBOARD.profile.root}${PATH_DASHBOARD.profile.show}/${param?.client?.id}/${EProfile.enumTab.object.travels.value}/${param.id}&travel=${param?.travel?.id}`}
        color="grey"
        size="small"
        // state={{ query: queryString, route: baseRoute }}
      >
        <KeyboardBackspaceIcon
          // fontSize="17px !important"
          sx={{
            cursor: 'pointer',
            color: 'grey.700',
            fontSize: '20px !important',
          }}
        />
      </Button>
    ),
  },
];
