import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Button, MenuItem, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { haveHasType, caravanType } from '../../../enumeration';
import { fDate, fDateJalali, fDateTime } from '../../../utils/formatTime';
import Enum from '../enum';
import TableMoreMenu from '../../../components/table/TableMoreMenu';
import Iconify from '../../../components/Iconify';
import MoreTable from '../../../components/table/MoreTable';
import MoreTableItem from '../../../components/table/MoreTable/MoreTableItem';

export const tableData = ({ baseRoute, onDelete, disabled }) => [
  {
    key: 'client',
    header: 'کاربر',
    Component: ({ param }) => `${param?.client?.name || ""} ${param?.client?.last_name || ""}`,
  },
  // {
  //   key: '',
  //   header: 'نتیجه ارسال',
  //   Component: ({ param }) => param?.status || '-',
  // },
  {
    key: 'seen',
    header: 'وضعیت',
    filter: {
      lookup: Object.values({
        0: { label: 'خوانده نشده', value: 0 },
        1: { label: 'خوانده شده', value: 1 },
      }),
    },

    Component: ({ param }) =>
      param?.seen ? (
        <Button variant="outlined" color="success">
          <Iconify icon="eva:done-all-fill" width={20} height={20} />
          <Typography
            sx={{
              fontSize: '11px',
            }}
          >
            {'خوانده شده'}
          </Typography>
        </Button>
      ) : (
        <Button variant="contained" color="success">
          <Iconify icon="eva:done-all-fill" width={20} height={20} />
          <Typography
            sx={{
              fontSize: '11px',
            }}
          >
            {'خوانده نشده'}
          </Typography>
        </Button>
      ),
      free: true,
  },
];
