import { Box, Grid, Typography } from "@mui/material";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import EAgent from "../enum";
import { separateNumberWithComma } from "src/utils";
import ReactApexChart from "react-apexcharts";
import { merge } from "lodash";
import { BaseOptionChart } from "src/components/chart";
import { useParams } from "react-router";
import axiosInstance from "src/utils/axios";
import api from "src/services/api";
import { useQueryCustom } from "src/utils/reactQueryHooks";
import { useState } from "react";
import WaitingBox from "src/components/WaitingBox";
import { fDateTime } from "src/utils/formatTime";

const ShowPage = () => {
    const backUrl = `${EAgent.routes.root}`;
    const queryParams = useParams();
    const [ChartData, setChartData] = useState([])

    const getApi = async () =>
        axiosInstance.get(`${EAgent.api.base}/${queryParams?.id}`);

    const handleSuccessData = (resData) => {
        const helpArr = []
        helpArr.push(Math.abs(resData?.data?.marriedClients));
        helpArr.push(Math.abs(resData?.data?.manClients));
        helpArr.push(Math.abs(resData?.data?.womanClients));
        helpArr.push(Math.abs(resData?.data?.ostadClients));
        setChartData(helpArr)
    }

    const { data, isLoading } = useQueryCustom({
        name: `${EAgent?.api?.base}_show_${queryParams?.id}`,
        url: getApi,
        staleTime: 0, // Data becomes stale immediately
        cacheTime: 0,
        onSuccess: handleSuccessData,
        // params: { ...queryString },
    });


    return (
        <>

            <Box
                sx={{
                    width: '100%',
                    // display: { xs: 'block', md: 'flex' },
                    gap: 2,
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    p: 4,
                }}
            >
                <HeaderBreadcrumbs
                    heading={`${data?.data?.admin?.name || ""} ${data?.data?.admin?.last_name || ""}`}
                    back={backUrl}
                />

                {isLoading ? <WaitingBox /> :
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <Typography>{data?.data?.description}</Typography>
                            </Grid>
                            {data?.data?.client &&
                                <>
                                    <Grid item xs={12} md={12}>
                                        <Typography>کاربر : {data?.data?.client?.name || "-"} {data?.data?.client?.last_name} با کد ملی {data?.data?.client?.code_melli}</Typography>
                                        {/* <Typography sx={{fontWeight:500,mt:2}}>کدملی کاربر : {data?.data?.client?.code_melli}</Typography> */}
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12} md={12}>
                                <Typography sx={{fontWeight:500}}>تاریخ : {data?.data?.created_at && fDateTime(data?.data?.created_at)}</Typography>
                            </Grid>
                        </Grid>
                    </>
                }



            </Box>
        </>
    );
}

export default ShowPage;