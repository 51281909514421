import { travelTypeObject } from '../../enumeration';
import { PATH_DASHBOARD } from '../../routes/paths';
import api from '../../services/api';

const EAgent = {
  title: {
    name: ['گزارش گیری', 'گزارش گیری'],
  },
  routes: PATH_DASHBOARD.report,
  api: api.report,
};

export default EAgent;
