import TableComponent from 'src/components/table/TableComponent';
import { Alert, Box, Grid, Typography } from '@mui/material';
import axiosInstance from 'src/utils/axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useTable from 'src/hooks/useTable';
import Enum from '../../../enum';
import { useMutationCustom, useQueryCustom } from '../../../../../utils/reactQueryHooks';
import { tableData } from './tableInfo';
import Layout from '../../Layout';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';

const List = ({
  user
}) => {
  const queryParams = useParams();
  const { t } = useTranslation();
  const tableSetting = useTable();
  const backUrl = `${Enum.routes.root}`;
  const paramId = queryParams?.id;
  const baseRoute = `${backUrl}/show/${paramId}/${Enum.enumTab.object.travels.value}`;
  // ------------------------------------------------------- query Get
  const getting = async () =>
    axiosInstance.get(`${Enum.api.register}`, {
      params: {
        client_id: queryParams.id,
      },
    });

  const {
    data: { data = {} } = {},
    error,
    isError,
    isLoading,
    refetch,
    isFetching,
  } = useQueryCustom({
    name: `${Enum.api.register}_get_${queryParams.id}`,
    url: getting,
    // params: { ...queryString },
  });

  // ------------------------------------------------------- Mutation Delete
  const deleteApi = async ({ id }) => axiosInstance.delete(`${Enum?.api?.base}/${id}`);

  const deleteInput = useMutationCustom({
    url: deleteApi,
    name: `${Enum?.api?.base}_delete`,
    invalidQueries: [`${Enum?.api?.base}_list`],
    onSuccess: () => {
      toast.success(t('question.delete.successfully'));
    },
    onError: (error) => {
      console.log({ error });
      const errorTitle = error.response.data.message || t('errorTryAgain');

      const errors = Object.values(error?.response?.data?.errors || {});
      if (errors?.length) {
        errors?.map((x) => {
          return toast.error(x?.[0]);
        });
      } else toast.error(errorTitle);
    },
  });
  const tableOptions = tableData({
    baseRoute: baseRoute,
    onDelete: (x) => deleteInput.mutate({ id: x?.id }),
    // disabled: {
    //   update: !checkAccess?.update,
    //   delete: !checkAccess?.delete,
    // },
  });
  console.log('* * * List :', { data, location: window.location });

  return (
    <Layout>
      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
          backgroundColor: 'grey.main',
          borderRadius: '8px',
        }}
      >
        <Typography>{'فرم های ثبت نام'}</Typography>
      </Box> */}
      <HeaderBreadcrumbs heading={`لیست سفر های  ${user?.name || ''} ${user?.last_name || ''} ` } back={backUrl} />
      <TableComponent
        active={
          {
            // selectable: true,
            // rowNumber: true,
          }
        }
        options={tableOptions}
        data={data}
        tableSetting={tableSetting}
        // pagination={
        //   {
        //     // totalPage: data?.meta?.last_page,
        //     // total: data?.meta?.total,
        //     // totalPage: data?.length,
        //     // total: data?.meta?.total,
        //   }
        // }
        loading={isLoading}
      />{' '}
    </Layout>
  );
};

export default List;
