import { useEffect, useMemo } from 'react';
import { Box, Container, Typography } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { Link, NavLink, useLocation, useNavigate, useParams, useSearchParams, Routes, Route } from 'react-router-dom';
import Enum from '../enum';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import useSettings from '../../../hooks/useSettings';
import axiosInstance from '../../../utils/axios';
import { useQueryCustom } from '../../../utils/reactQueryHooks';
import WaitingBox from '../../../components/WaitingBox/index';
import useQueryString from '../../../utils/useQueryString';
import api from '../../../services/api';
import Add from './Add';
import List from './List';

const Lottery = () => {
  const queryParams = useParams();
  const queryString = useQueryString();
  const location = useLocation();
  const { themeStretch } = useSettings();
  const baseUrl = `${Enum.routes.root(queryParams.type, queryParams.travel)}`;
  const paramId = queryParams?.id;

  const getTravelById = () => axiosInstance.get(`${api.travel.base}/${queryParams.travel}`);

  const dataById = useQueryCustom({
    name: `get_by_Id_${api.travel.base}_${queryParams.travel}`,
    url: getTravelById,
    // enabled: !!paramId,
  });

  console.log({ dataById, location });

  const title = `قرعه کشی برای ${dataById?.data?.data?.name} `;
  return dataById.isLoading ? (
    <WaitingBox />
  ) : (
    <Page title={`${title}`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={title}
          back={baseUrl + "/registrations"}
          links={[{ name: 'داشبورد', href: '/' }, { name: title, href: Enum.routes.list }, { name: 'لیست' }]}
        >
          <Typography
            mt={1}
          >{`شما در حال قرعه کشی برای دوره  ${dataById?.data?.data?.name} هستید معیار های این عمل را تکمیل کنید.`}</Typography>
        </HeaderBreadcrumbs>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 2,
            backgroundColor: 'background.neutral',
            borderRadius: '8px',
          }}
        >
          <Typography>{'قرعه کشی'}</Typography>
          {/* <PrintIcon
            // onClick={handleToggleSectionModal}
            sx={{
              cursor: 'pointer',
            }}
          /> */}
        </Box>

        <Routes>
          <Route path={`/list`} element={<List />} />
          <Route path={`/`} element={<Add />} />
        </Routes>
      </Container>
    </Page>
  );
};

export default Lottery;
