import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Modal, Typography } from '@mui/material';
import { Link as RouterLink, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axiosInstance from 'src/utils/axios';
import { useMutationCustom, useQueryCustom } from 'src/utils/reactQueryHooks';
import useQueryString from 'src/utils/useQueryString';
import Enum from '../enum';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'src/routes/paths';
import useSettings from 'src/hooks/useSettings';
import Iconify from 'src/components/Iconify';
import TableComponent from 'src/components/table/TableComponent';
import { tableData } from './tableInfo';
import useTable from 'src/hooks/useTable';
import { hasAccess } from 'src/permission/utiles';
import api from 'src/services/api';
import WaitingBox from 'src/components/WaitingBox';
import { travelTypeObject } from 'src/enumeration';
import { downloadFileFromAxios } from 'src/utils';

const List = ({ access }) => {
  const { t } = useTranslation();
  const queryParams = useParams();
  const queryString = useQueryString();

  const location = useLocation();
  const { showHelpText } = location.state || {}; 
  const [searchParams] = useSearchParams();

  // ---------------------------------------------------------------------
  const getApi = async () =>
    axiosInstance.get(`${api?.excel?.base(queryParams?.travel)}`);

  const deleteApi = async ({ id }) => axiosInstance.delete(`${Enum?.api?.base}/${id}`);
  // ---------------------------------------------------------------------

  const { themeStretch } = useSettings();

  const tableSetting = useTable({ queryString });
  console.log({ tableSetting, queryParams });
  // ------------------------------------------------------- query Get
  const { data : { data = {} } = {}, isLoading } = useQueryCustom({
    name: `${Enum?.api?.base}_list`,
    url: getApi,
    staleTime: 0, // Data becomes stale immediately
    cacheTime: 0,
    params: { ...queryString },
  });
  // ------------------------------------------------------- query delete

  const deleteInput = useMutationCustom({
    url: deleteApi,
    name: `${Enum?.api?.base}_delete`,
    invalidQueries: [`${Enum?.api?.base}_list`],
    onSuccess: () => {
      toast.success(t('question.delete.successfully'));
    },
    onError: (error) => {
      console.log({ error });
      const errorTitle = error.response.data.message || t('errorTryAgain');

      const errors = Object.values(error?.response?.data?.errors || {});
      if (errors?.length) {
        errors?.map((x) => {
          return toast.error(x?.[0]);
        });
      } else toast.error(errorTitle);
    },
  });
  const checkAccess = useMemo(() => {
    return {
      // update: hasAccess(access?.update),
      delete: hasAccess(access?.delete),
      create: hasAccess(access?.create),
      confirm: hasAccess(access?.confirm),
    };
  }, [access]);

  // DOWNLOAD EXCEL
  const [DownloadExcelLoading, setDownloadExcelLoading] = useState(false)

  const handleDownloadExcel = async () => {
    const { filters, ...others } = queryString;
    const newFilters = [];
    // if (filters) newFilters = JSON.parse(filters || '')?.map((x) => ({ input_id: x.input_id, value: x.value }));
    // console.log('* * * getting : ', { newFilters , others});
    delete newFilters?.page;
    delete newFilters?.perPage;
    delete others?.page;
    delete others?.perPage;

    // const stateFilters = CustomFilterObj?.filters
    // for (const key in stateFilters) {
    //   if (Object.prototype.hasOwnProperty.call(stateFilters, key)) {
    //     const element = stateFilters[key];
    //     newFilters[key] = element?.value
    //   }
    // }

    // console.log('* * * getting : ', { newFilters, filters, CustomFilterObj, stateFilters });

    try {
      setDownloadExcelLoading(true)
      const { data } = await axiosInstance.get(`${Enum?.api?.excel(queryParams.travel)}`, {
        responseType: 'blob',
        params: {
          ...others,
          // ...(newFilters && (newFilters)),
          // ...(filters && { filters: JSON.stringify(newFilters) }),
        },
      });

      await downloadFileFromAxios(data, "excel");
      setDownloadExcelLoading(false)

    } catch (error) {
      console.log(error);
      setDownloadExcelLoading(false)
    }

  }

  const title = `${Enum.title.name[1]} `;
  const tableOptions = tableData({
    baseRoute: `${Enum.routes.root}`,
    onDelete: (x) => deleteInput.mutate({ id: x?.id }),
    // download:handleDownloadExcel,
    // DownloadExcelLoading : DownloadExcelLoading,
    disabled: {
      update: !checkAccess?.update,
      delete: !checkAccess?.delete,
    },
  });

  console.log({ tableOptions, data , location });

  return (
    <Page title={`${title}`}>
      <Container maxWidth={themeStretch ? false : 'lg'} key={`excel-list`}>
        <HeaderBreadcrumbs
          heading={title}
          links={[
            { name: 'داشبورد', href: Enum.routes.root },
            { name: title, href: Enum.routes.list },
            { name: 'لیست' },
          ]}
          action={
            checkAccess?.create ? (
              <>
                {/* <Button
                  variant="contained"
                  color={'success'}
                  component={RouterLink}
                  to={`${Enum.routes.root}${Enum.routes.add}`}
                  startIcon={<Iconify icon={'eva:plus-fill'} />}
                >
                  {t('addAuthor', { author: Enum.title.name[0] })}
                </Button> */}
              </>
            ) : (
              ''
            )
          }
        >
          {(showHelpText || searchParams.get("show")) &&
            <>
              <Typography sx={{
                fontSize:15,
                color:"#777",
                mt:1
              }}>
                دریافت گزارش اکسلی چند دقیقه طول میکشد.
              </Typography>
            </>
          }
        </HeaderBreadcrumbs>
        {isLoading ? <WaitingBox /> :
          <>
            <TableComponent
              active={{
                // selectable: true,
                rowNumber: true,
              }}
              hiddenSearchText
              options={tableOptions}
              data={data || []}
              tableSetting={tableSetting}
              pagination={{
                // totalPage: data?.meta?.last_page,
                // total: data?.meta?.total,
                totalPage: data?.data?.length || 0,
                total: data?.data?.length || 0,
              }}
              loading={isLoading}
            />{' '}
            
          </>
        }
      </Container>
    </Page>
  );
};

export default List;
