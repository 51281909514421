import errorsText from 'src/utils/errorsText';
import * as yup from 'yup';

const validation = {
  defaultValue:{
    key: '',
    translate: '',
    value: null,
  },
  fieldNames: {
    key: 'key',
    translate: 'translate',
    value: 'value',
  },
  schema: () =>
    yup.object().shape({
      [validation.fieldNames.key]: yup.string().required(errorsText.blankError()),
      [validation.fieldNames.translate]: yup.string().required(errorsText.blankError()),
      [validation.fieldNames.value]: yup.mixed().required(errorsText.blankError()),
    }),
};
// "Passwords must match"
export default validation;
