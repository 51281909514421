import { Checkbox, TableCell, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { RHFCheckbox } from 'src/components/hook-form';

const RowItem = ({ x, basename, props }) => {
  const [allConfirmed, setAllConfirmed] = useState(false);
  const { watch, setValue } = useFormContext();
  const allSupportedName = ['create', 'read', 'update', 'delete', 'confirm'];
  const WCreate = watch(basename + '.' + x?.object?.create?.id?.toString());
  const WRead = watch(basename + '.' + x?.object?.read?.id?.toString());
  const WUpdate = watch(basename + '.' + x?.object?.update?.id?.toString());
  const WDelete = watch(basename + '.' + x?.object?.delete?.id?.toString());
  const WConfirm = watch(basename + '.' + x?.object?.confirm?.id?.toString());

  useEffect(() => {
    setAllConfirmed(WCreate && WRead && WUpdate && WUpdate && WDelete && WConfirm);
  }, [WCreate, WRead, WUpdate, WDelete, WConfirm]);

  // console.log({ allConfirmed });

  return (
    <TableRow>
      <TableCell sx={{ width: 150 }}>{x?.label}</TableCell>
      <TableCell sx={{ width: 50 }}>
        <Checkbox
          checked={allConfirmed}
          // onChange={() => field.onChange(onSelected(option))}
          color={'success'}
          onChange={() => {
            const checked = !allConfirmed;
            allSupportedName?.forEach((y) => setValue(basename + '.' + x?.object?.[y]?.id?.toString(), checked));
          }}
        />
        {/* <RHFCheckbox {...props} name={basename + '.' + x?.object?.create?.id?.toString()} label="" /> */}
      </TableCell>
      <TableCell sx={{ width: 50 }}>
        {' '}
        {x?.object?.create?.id ? (
          <RHFCheckbox {...props} name={basename + '.' + x?.object?.create?.id?.toString()} label="" />
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell sx={{ width: 50 }}>
        {' '}
        {x?.object?.read?.id ? (
          <RHFCheckbox {...props} name={basename + '.' + x?.object?.read?.id?.toString()} label="" />
        ) : (
          ''
        )}
      </TableCell>
      <TableCell sx={{ width: 50 }}>
        {' '}
        {x?.object?.update?.id ? (
          <RHFCheckbox {...props} name={basename + '.' + x?.object?.update?.id?.toString()} label="" />
        ) : (
          ''
        )}
      </TableCell>
      <TableCell sx={{ width: 50 }}>
        {' '}
        {x?.object?.delete?.id ? (
          <RHFCheckbox {...props} name={basename + '.' + x?.object?.delete?.id?.toString()} label="" />
        ) : (
          ''
        )}
      </TableCell>
      {/* <TableCell sx={{ width: 50 }}>
        {' '}
        {x?.object?.confirm?.id ? (
          <RHFCheckbox {...props} name={basename + '.' + x?.object?.confirm?.id?.toString()} label="" />
        ) : (
          ''
        )}
      </TableCell> */}
    </TableRow>
  );
};

export default RowItem;
