import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, MenuItem, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { haveHasType, caravanType } from '../../../../../enumeration';
import { fDate, fDateJalali, fDateTime } from '../../../../../utils/formatTime';
import Enum from '../enum';
import TableMoreMenu from '../../../../../components/table/TableMoreMenu';
import Iconify from '../../../../../components/Iconify';
import MoreTable from '../../../../../components/table/MoreTable';
import MoreTableItem from '../../../../../components/table/MoreTable/MoreTableItem';
import { Interweave } from 'interweave';

export const tableData = ({ baseRoute, onDelete, disabled }) => [
  {
    key: '',
    header: 'متن پیام',
    Component: ({ param }) => (
      <Box sx={{ width: '200px' }}>
        <Typography
          noWrap
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            height: '52px',
          }}
        >
          {/*{param?.message || '-'}*/}
          <Interweave
            content={param?.message || '-'}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'block',
            }}
          />
        </Typography>
      </Box>
    ),
  },
  // {
  //   key: '',
  //   header: 'نتیجه ارسال',
  //   Component: ({ param }) => param?.status || '-',
  // },
  {
    key: '',
    header: 'تاریخ ارسال',
    Component: ({ param }) => (param?.created_at && fDateTime(param?.created_at)) || '-',
  },
  {
    key: '',
    header: 'ارسال کننده',
    Component: ({ param }) => `${param?.admin_id?.name || '-'} ${param?.admin_id?.last_name || ""}`,
  },
  // {
  //   key: '',
  //   header: 'ارسال شده به',
  //   Component: ({ param }) => <Box style={{ direction: 'ltr' }}>{param?.send_to?.replace(/\n/g, ` , `) || '-'}</Box>,
  // },
];
