import errorsText from 'src/utils/errorsText';
import * as yup from 'yup';

const validation = {
  // defaultValue:{},
  fieldNames: {
    team: 'team_id',
    message: 'message',
    clients: 'clients',
    status: 'status',
    province_id: 'province_id',
    type: 'type',
    nezam_vazife_status: 'nezam_vazife_status',
    tasharof_be_soorat: 'tasharof_be_soorat',
  },
  schema: () =>
    yup.object().shape({
      [validation.fieldNames.message]: yup.string().required(errorsText.blankError()),
      [validation.fieldNames.team]: yup.mixed(),
      [validation.fieldNames.clients]: yup.mixed(),
      [validation.fieldNames.status]: yup.mixed(),
      [validation.fieldNames.province_id]: yup.mixed(),
      [validation.fieldNames.type]: yup.mixed(),
      [validation.fieldNames.nezam_vazife_status]: yup.mixed(),
      [validation.fieldNames.tasharof_be_soorat]: yup.mixed(),
    }),
};
// "Passwords must match"
export default validation;
