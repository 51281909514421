/* eslint-disable arrow-body-style */
import { Box, Button, Container, InputAdornment, LinearProgress, Modal, Stack, TextField, Typography } from '@mui/material';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo, useRef, useState } from 'react';
import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ModalLayout from 'src/components/ModalLayout';
import QuestionComponent from 'src/components/ui/Question';
import api from 'src/services/api';
import axiosInstance from '../../../utils/axios';
import { useMutationCustom, useQueryCustom } from '../../../utils/reactQueryHooks';
import useQueryString from '../../../utils/useQueryString';
import Enum from '../enum';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Iconify from '../../../components/Iconify';
import AddComponent from '../../Course/Add';
import TableComponent from '../../../components/table/TableComponent';
import { tableData } from './tableInfo';
import useTable from '../../../hooks/useTable';
import { hasAccess } from '../../../permission/utiles';
import LotteryModalComponent from '../LotteryModalComponent';
import { travelTypeObject } from '../../../enumeration/index';
import VisitorFilters from '../component/Filters';
import ShowFilters from '../component/ShowFilters';
import { LoadingButton } from '@mui/lab';
import { downloadFileFromAxios } from 'src/utils';
import { Download } from '@mui/icons-material';
import axiosInstanceV2 from 'src/utils/axiosV2';
import persianToEnglishNumber from 'src/utils/persianToEnglishNumber';
import useDebounce from 'src/hooks/useDebounce';
import buildAccess from 'src/permission/buildAccess';

const List = ({ access }) => {
  const accessLottery = buildAccess("Lottery");
  const chartAccess = buildAccess("Chart");
  const ModalLayoutQuestionInputRef = useRef();
  const ModalLayoutDownloadStatusRef = useRef();
  const queryParams = useParams();
  const withExcel = [travelTypeObject.omre.value, travelTypeObject.tamato.value].includes(queryParams.type);

  const [toggleModal, setToggleModal] = useState();
  const queryString = useQueryString();
  const baseUrl = `${Enum.routes.root(queryParams.type, queryParams.travel)}`;
  const location = useLocation();
  const isRecord = queryParams.isRecord;
  const previous = location?.state;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const checkAccess = useMemo(() => {
    return {
      update: hasAccess(access?.update),
      delete: hasAccess(access?.delete),
      create: hasAccess(access?.create),
      confirm: hasAccess(access?.confirm),
      read: hasAccess(access?.read),
    };
  }, [access]);

  const { themeStretch } = useSettings();

  const tableSetting = useTable({ queryString });
  console.log({ tableSetting, queryParams, previous });
  const handleQuestionModal = () => ModalLayoutQuestionInputRef.current.show((p) => !p);
  const handleShowDownloadStausModal = () => ModalLayoutDownloadStatusRef.current.show((p) => !p , {} , { disableBackDrop: true });

  const [CustomFilterObj, setCustomFilterObj] = useState({})

  const { debounce } = useDebounce({
    setDebounce: (x) => {
      // setCustomFilterObj({ filters: reqData });
      if (x !== "") {
        setCustomFilterObj(prev => ({
          ...prev,
          filters: {
            ...prev?.filters,
            main_client_code_melli: {
              label: "کد ملی",
              value: persianToEnglishNumber(x),
              name: "codeMeli",
              filterName: "main_client_code_melli",
              hidden: true
            }
          }
        }));
      } else {
        setCustomFilterObj({ filters: null })
      }
    }
  });

  // ------------------------------------------------------- query lottery
  const onSuccessMutating = () => {
    ModalLayoutQuestionInputRef.current.show((p) => false);
    toast.success(t('successfully'));
  };
  // const onErrorMutating = (error) => {
  //   console.log('* * * onErrorMutating :', { error });
  //   const errorTitle = error.response.data.message || t('errorTryAgain');
  //   const errors = Object.values(error?.response?.data?.errors || {});
  //   if (errors?.length) {
  //     errors?.map((x) => {
  //       return toast.error(x?.[0]);
  //     });
  //   } else toast.error(errorTitle);
  // };
  const postLottery = async (params) => axiosInstance.post(`${api?.lottery?.base}`, params);
  const lotteryMutation = useMutationCustom({
    url: postLottery,
    name: `${api?.lottery?.base}_post`,
    // invalidQuery: `${api?.lottery?.base}_get`,
    onSuccess: onSuccessMutating,
    // onError: onErrorMutating,
  });
  const handleLottery = () => {
    // alert();
    lotteryMutation.mutate({
      travel_id: queryParams.travel,
    });
  };
  // ------------------------------------------------------- query Get
  const getTravel = async () => axiosInstance.get(`${api.travel.base}/${queryParams.travel}`);

  const resTravel = useQueryCustom({
    name: `${api.travel.base}_get_${queryParams.travel}`,
    url: getTravel,
    params: { id: queryParams.travel },
  });
  // ------------------------------------------------------- query Get

  // const getting = async (customParams = {}) => {
  //   // axiosInstance.get(`${Enum?.api?.base}/${queryParams.travel}`, { params: { ...queryString, status: 1 } });
  //   const { filters, ...others } = {...queryString , ...customParams};
  //   let newFilters;
  //   if (filters) newFilters = JSON.parse(filters || '')?.map((x) => ({ input_id: x.input_id, value: x.value }));
  //   console.log('* * * getting : ', { newFilters , filters , customParams });
  //   return axiosInstanceV2.get(`${Enum?.api?.base}/${queryParams.travel}`, {
  //     params: {
  //       ...others,
  //       // 'status[]': 2,
  //       ...(filters && { filters: JSON.stringify(newFilters) }),
  //     },
  //   });
  // };
  const getting = async () => {
    const { filters, ...others } = { ...queryString };

    const stateFilters = CustomFilterObj?.filters

    const newFilters = {};
    for (const key in stateFilters) {
      if (Object.prototype.hasOwnProperty.call(stateFilters, key)) {
        const element = stateFilters[key];
        newFilters[key] = element?.value
      }
    }

    console.log('* * * getting : ', { newFilters, filters, CustomFilterObj, stateFilters });

    return axiosInstanceV2.get(`${Enum?.api?.base}/${queryParams.travel}`, {
      params: {
        ...others,
        ...(newFilters && (newFilters)),
      },
    });
  };

  const {
    data: { data = {} } = {},
    error,
    isError,
    isLoading,
    refetch,
    isFetching,
  } = useQueryCustom({
    name: `${Enum?.api?.base}_get_${queryParams.travel}_registrations`,
    url: getting,
    params: { ...queryString },
    enabled: checkAccess.read,
  });

  // Custom refetch function with additional parameters
  const customRefetch = (customParams = {}) => {
    console.log({ customParams: customParams?.filters })
    // setCustomFilterObj(customParams?.filters)
    return refetch();
  };

  useEffect(() => {
    customRefetch();
  }, [CustomFilterObj])

  const [DownloadExcelLoading, setDownloadExcelLoading] = useState(false)
  const [DownloadExcelUniLoading, setDownloadExcelUniLoading] = useState(false)
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [fileSize, setFileSize] = useState(0);

  const handleDownloadExcel = async () => {
    const { filters, ...others } = queryString;
    const newFilters = [];
    // if (filters) newFilters = JSON.parse(filters || '')?.map((x) => ({ input_id: x.input_id, value: x.value }));
    // console.log('* * * getting : ', { newFilters , others});
    delete newFilters?.page;
    delete newFilters?.perPage;
    delete others?.page;
    delete others?.perPage;

    const stateFilters = CustomFilterObj?.filters
    for (const key in stateFilters) {
      if (Object.prototype.hasOwnProperty.call(stateFilters, key)) {
        const element = stateFilters[key];
        newFilters[key] = element?.value
      }
    }

    console.log('* * * getting : ', { newFilters, filters, CustomFilterObj, stateFilters });

    // handleShowDownloadStausModal();

    try {
      setDownloadExcelLoading(true)
      const response = await axiosInstance.get(`${Enum?.api?.excel(queryParams.travel)}`, {
        // responseType: 'blob',
        params: {
          // travel_id : queryParams.travel,
          ...others,
          ...(newFilters && (newFilters)),
          // ...(filters && { filters: JSON.stringify(newFilters) }),
        },
        // onDownloadProgress: (progressEvent) => {
        //   const { loaded, total } = progressEvent;
          
        //   // Calculate the percentage of the file downloaded
        //   // const progressPercentage = (loaded / total) * 100;
        //   const loadedInMB = (loaded / 1024 / 1024).toFixed(2); // Loaded size in MB

        //   setDownloadProgress(loadedInMB);
          
        //   // Set the total size of the file (only once)
        //   if (fileSize === 0 && total) {
        //     setFileSize(total); // Set total size in bytes
        //   }
        // },
      });

      console.log({downloadExcelData : response?.data})

      // const blob = new Blob([response.data]);
      
      // // Create a link element
      // const link = document.createElement('a');
      // link.href = window.URL.createObjectURL(blob);
      // link.download = 'clients.xlsx';
      // link.click();

      // // Clean up and revoke the object URL after download
      // window.URL.revokeObjectURL(link.href);
      setTimeout(() => {
        navigate(`${PATH_DASHBOARD.report.excel.root}/${queryParams.travel}?show=true`);
        setDownloadExcelLoading(false)
      }, 10000);

      // // await downloadFileFromAxios(data , "client");
      // handleShowDownloadStausModal();
      // setDownloadProgress(0)
    } catch (error) {
      console.log(error);
      // handleShowDownloadStausModal();
      setDownloadProgress(0)
      setDownloadExcelLoading(false)
    }

  }

  const handleDownloadExcelUniversity = async () => {
    const { filters, ...others } = queryString;
    const newFilters = [];
    // if (filters) newFilters = JSON.parse(filters || '')?.map((x) => ({ input_id: x.input_id, value: x.value }));
    // console.log('* * * getting : ', { newFilters , others});
    delete newFilters?.page;
    delete newFilters?.perPage;
    delete others?.page;
    delete others?.perPage;

    const stateFilters = CustomFilterObj?.filters
    for (const key in stateFilters) {
      if (Object.prototype.hasOwnProperty.call(stateFilters, key)) {
        const element = stateFilters[key];
        newFilters[key] = element?.value
      }
    }

    console.log('* * * getting : ', { newFilters, filters, CustomFilterObj, stateFilters });
    // handleQuestionModal();
    // handleShowDownloadStausModal();
    try {
      setDownloadExcelUniLoading(true)
      const response = await axiosInstance.get(`${Enum?.api?.universityExcel}`, {
        responseType: 'blob',
        params: {
          travel_id : queryParams.travel,
          ...others,
          ...(newFilters && (newFilters)),
          // ...(filters && { filters: JSON.stringify(newFilters) }),
        },
        onDownloadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          
          // Calculate the percentage of the file downloaded
          // const progressPercentage = (loaded / total) * 100;
          const loadedInMB = (loaded / 1024 / 1024).toFixed(2); // Loaded size in MB

          setDownloadProgress(loadedInMB);
          
          // Set the total size of the file (only once)
          if (fileSize === 0 && total) {
            setFileSize(total); // Set total size in bytes
          }
        },
      });

      console.log({downloadExcelData : response?.data})

      const blob = new Blob([response.data]);
      
      // Create a link element
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'registers.xlsx';
      link.click();

      // Clean up and revoke the object URL after download
      window.URL.revokeObjectURL(link.href);
      // navigate(`${PATH_DASHBOARD.report.excel.root}/${queryParams.travel}?show=true`);

      // await downloadFileFromAxios(data , "client");
      // handleShowDownloadStausModal();
      setDownloadProgress(0)
      setDownloadExcelUniLoading(false)
      handleQuestionModal();

    } catch (error) {
      console.log(error);
      // handleShowDownloadStausModal();
      setDownloadProgress(0)
      setDownloadExcelUniLoading(false)
    }

  }

  const handleToggleModal = () => setToggleModal((p) => !p);

  // const data = [
  //   {
  //     name: 'hasan',
  //     lastName: 'jafari',
  //     nationalCode: '23561484155',
  //     phoneNumber: '09111111111',
  //     reason: 'jafari',
  //     createdAt: '1403/04/05',
  //   },
  //   {
  //     name: 'hasan',
  //     lastName: 'jafari',
  //     nationalCode: '23561484155',
  //     phoneNumber: '09111111111',
  //     reason: 'jafari',
  //     createdAt: '1403/04/05',
  //   },
  // ];

  const tableOptions = tableData({
    disabled: {
      update: !checkAccess?.update,
      delete: !checkAccess?.delete,
    },
  });

  const title = `مدیریت ${Enum.title.name[0]}`;
  console.log({ access, tableOptions, data, withExcel });

  const tab = [
    {
      label: 'ثبت نامی ها',
      value: 'registrations',
      active: true,
    },
    {
      label: 'تایید شده',
      value: '',
      active: false,
    },
  ];

  return (
    <Page title={title}>
      <Modal
        open={toggleModal}
        onClose={handleToggleModal}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 6,
        }}
      >
        <AddComponent onClose={handleToggleModal} />
      </Modal>

      {/* <ModalLayout ref={ModalLayoutQuestionInputRef}>
        {withExcel ? (
          <LotteryModalComponent />
        ) : (
          <QuestionComponent
            {...{
              loading: lotteryMutation?.isLoading,
              title: 'آیا از قرعه کشی مطمئن هستید؟',
              description: 'پس از تایید قرعه کشی انجام خواهد شد . آیا ادامه میدهید؟',
              button: {
                confirm: {
                  label: 'question.yesProcess',
                  onClick: handleLottery,
                },
                reject: {
                  label: 'question.no',
                  onClick: handleQuestionModal,
                },
              },
            }}
          // onClose={handleQuestionModal}
          />
        )}
      </ModalLayout> */}
      <ModalLayout ref={ModalLayoutQuestionInputRef}>

        <Box
          sx={{
            width: '100%',
            maxWidth: '550px',
            // display: { xs: 'block', md: 'flex' },
            gap: 2,
            bgcolor: 'background.paper',
            borderRadius: 1,
            px: 3,
            py: 3,
          }}
        >

          {/* 
            button: {
                confirm: {
                  label: 'قرعه کشی با فرم',
                  onClick: () => navigate(`${baseUrl}${Enum?.routes?.lottery}`),
                },
                reject: {
                  label: 'قرعه کشی با اکسل دانشگاهیان',
                  onClick: () => navigate(`${baseUrl}${Enum?.routes?.lottery}?with_excel=true`),
                  // onClick: handleQuestionModal,
                },
              },
          */}

          <Typography sx={{
            fontWeight:700,
            fontSize:18,
            textAlign:"center",
            mb:4
          }}>لطفا نوع قرعه کشی خود را انتخاب کنید</Typography>

          <Stack direction={"row"} gap={1} sx={{
            flexWrap:"wrap",
            mt:2
          }}>
            <Button variant='contained' color='success'
              onClick={() => navigate(`${baseUrl}${Enum?.routes?.lottery}`)}
              disabled
              sx={{
                width:"calc(50% - 4px)"
              }}
            >
              قرعه کشی با فرم
            </Button>
            <Button variant='outlined' color='success'
              onClick={() => navigate(`${baseUrl}${Enum?.routes?.lottery}?with_excel=true`)}
              sx={{
                width:"calc(50% - 4px)"
              }}
            >
              قرعه کشی با اکسل دانشگاهیان
            </Button>
            <LoadingButton variant='outlined' color='info'
              onClick={handleDownloadExcelUniversity}
              loading={DownloadExcelUniLoading}
              // disabled
              sx={{
                width:"calc(50% - 4px)"
              }}
            >
              تعداد ثبت نامی های دانشگاه ها
            </LoadingButton>
            <Button variant='contained' color='success'
              onClick={() => navigate(`/lottery/${queryParams.travel}`)}
              sx={{
                width:"calc(50% - 4px)"
              }}
            >
              صفحه نمایش قرعه کشی
            </Button>
          </Stack>

        </Box>

      </ModalLayout>
      <ModalLayout ref={ModalLayoutDownloadStatusRef} >

        <Box
          sx={{
            width: '100%',
            maxWidth: '550px',
            // display: { xs: 'block', md: 'flex' },
            gap: 2,
            bgcolor: 'background.paper',
            borderRadius: 1,
            px: 3,
            py: 3,
          }}
        >

          <Typography sx={{
            fontWeight:700,
            fontSize:18,
            textAlign:"center",
            mb:4
          }}>در حال دانلود اکسل درخواستی</Typography>

          <LinearProgress variant="indeterminate" color={"success"} />

          <Typography sx={{
            fontWeight:700,
            fontSize:18,
            textAlign:"center",
            my:4
          }}>
            حجم دانلود شده : {downloadProgress} مگابایت
          </Typography>







        </Box>

      </ModalLayout>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={isRecord ? resTravel?.data?.data?.name : title}
          back={
            previous?.pathname
              ? { pathname: previous?.pathname, search: new URLSearchParams(previous?.query || {}).toString() }
              : false
          }
          links={[
            { name: 'داشبورد', href: PATH_DASHBOARD.root },
            { name: title, href: Enum.routes.list },
            { name: 'لیست' },
          ]}
          action={
            checkAccess?.confirm && !isRecord ? (
              <Box sx={{ display: "flex", gap: 2 }}>
                {hasAccess(chartAccess?.read) &&
                  <LoadingButton
                    onClick={handleDownloadExcel}
                    variant="outlined"
                    color="success"
                    loading={DownloadExcelLoading}
                    // component={Link}
                    // to={baseUrl + Enum.routes.lottery}
                    endIcon={<Download />}
                  >
                    {'دریافت اکسل'}
                  </LoadingButton>
                }
                {/* <Link 
                  to={{
                    pathname: `${PATH_DASHBOARD.report.excel.root}/${queryParams.travel}`,
                    search: `?show=true`
                  }}
                > */}
                {/* <Button
                    onClick={handleDownloadExcel}
                    variant="outlined"
                    color="success"
                    // loading={DownloadExcelLoading}
                    // component={Link}
                    // to={baseUrl + Enum.routes.lottery}
                    endIcon={<Download />}
                  >
                    {'دریافت اکسل'}
                  </Button> */}
                {/* </Link> */}
                {hasAccess(accessLottery.create) &&
                  <>
                    {/* <Link to={`${baseUrl}${Enum?.routes?.lottery}`}> */}
                    <Button
                      onClick={handleQuestionModal}
                      variant="contained"
                      color="success"
                      // component={Link}
                      // to={baseUrl + Enum.routes.lottery}
                      endIcon={<BlurCircularIcon />}
                    >
                      {'قرعه کشی'}
                    </Button>
                    {/* </Link> */}
                  </>
                }
              </Box>
            ) : (
              ''
            )
          }
        >
          {isRecord ? <Typography sx={{ mt: 1 }}>{title}</Typography> : ''}
        </HeaderBreadcrumbs>
        <Box
          sx={{
            // display: 'flex',
            alignItems: 'center',
            gap: 2,
            display: { xs: 'none', md: 'flex' },
          }}
        >
          {tab?.map((x) => {
            return (
              <Link to={`${baseUrl}/${x.value}`}>
                <Typography
                  sx={{
                    color: 'grey.800',
                    p: 1,
                    borderBottom: '2px solid transparent',
                    fontWeight: '300',
                    fontSize: '13px',

                    ...(x.active
                      ? {
                        borderColor: 'grey.800',
                        fontWeight: '900',
                      }
                      : {
                        '&:hover': {
                          color: 'grey.600',
                        },
                      }),
                  }}
                >
                  {x.label}
                </Typography>
              </Link>
            );
          })}
        </Box>
        <TableComponent
          active={{
            // selectable: true,
            rowNumber: true,
            filter: {
              component: <VisitorFilters customRefetch={customRefetch} setCustomFilterObj={setCustomFilterObj} CustomFilterObj={CustomFilterObj} />,
            },
          }}
          show={{
            filters: <ShowFilters {...{ queryString }} CustomFilterObj={CustomFilterObj} setCustomFilterObj={setCustomFilterObj} stateMode />,
          }}
          hiddenSearchText
          topOfHeaderFilter={
            <>
              <TextField
                placeholder="جستجو ..."
                onChange={(e) => debounce(e.target.value)}
                // defaultValue={search}
                InputProps={{
                  // className: ' !rounded-l-[50px] !border-gray-50',
                  sx: {
                    borderRadius: '8px',
                  },
                  inputProps: {
                    // className: ' p-[10px]',
                    sx: {
                      padding: '10px',
                    },
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  ),
                }}
              // sx={{mr: 1, fontWeight: 'fontWeightBold'}}
              />
            </>
          }
          options={tableOptions}
          data={data?.data}
          tableSetting={tableSetting}
          hasRowSx
          pagination={{
            totalPage: data?.meta?.last_page,
            total: data?.meta?.total,
          }}
          loading={isLoading}
        />{' '}
      </Container>
    </Page>
  );
};

export default List;
