/* eslint-disable consistent-return */
/* eslint-disable arrow-body-style */
import { Box, Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import Image from "src/components/Image";
import ModalLayout from "src/components/ModalLayout";
import WaitingBox from "src/components/WaitingBox";
import api from "src/services/api";
import { separateNumberWithComma } from "src/utils";
import axiosInstance from "src/utils/axios";
import { useQueryCustom } from "src/utils/reactQueryHooks";

const DetailBox = ({
    title = "دانشجویان",
    greenTitle = "",
    count = 0,
    inputName = "",
    helpText = "عدد را وارد نمایید",
    value = "",
    setValue = () => null
}) => {
    return (
        <>
            <Box
                sx={{
                    backgroundImage: "url(/images/border.svg)",
                    backgroundRepeat: "no-repeat",
                    minWidth: "258px",
                    maxWidth: "258px",
                    minHeight: "412px",
                    maxHeight: "412px",
                    aspectRatio: "5/8",
                    color: "#4E3503",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    p: 4,
                    py: 5,
                    pb: 9
                }}
            >

                <Box sx={{
                    textAlign: "center",
                    minHeight: 72,
                    fontWeight: 800,
                    fontSize: 24
                }}>
                    <Typography sx={{ fontWeight: 800, fontSize: 24 }}>{title}</Typography>
                    <Typography sx={{ fontWeight: 800, fontSize: 24, color: "#00AC5D" }}>{greenTitle}</Typography>
                </Box>

                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Box
                        sx={{
                            width: "18px",
                            height: "18px",
                            borderRadius: "2px",
                            border: `2px solid #4E3503`,
                            transform: "rotate(45deg)"
                        }}
                    />
                    <Typography sx={{ fontWeight: 800, fontSize: 36 }}>{separateNumberWithComma(count)}</Typography>
                    <Box
                        sx={{
                            width: "18px",
                            height: "18px",
                            borderRadius: "2px",
                            border: `2px solid #4E3503`,
                            transform: "rotate(45deg)"
                        }}
                    />
                </Stack>

                <input
                    style={{
                        width: "100%",
                        border: `1px solid #4E3503`,
                        borderRadius: "8px",
                        backgroundColor: "#F4F1EA",
                        color: "#4E3503",
                        textAlign: "center",
                        fontWeight: 800,
                        fontSize: "30px",
                        fontFamily: "IRANYekan,sans-serif",
                        padding: 8,
                    }}
                    placeholder={helpText}
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    className="lotteryInput"
                />
                {/* <Typography
                        sx={{ opacity: 0.38 }}
                    >
                        {helpText}
                    </Typography> */}

            </Box>
        </>
    )
}

const LotterySitePage = () => {

    const queryParams = useParams();


    const ModalLayoutLotteryStatusRef = useRef();
    const ModalLayoutSMSStatusRef = useRef();

    const handleShowLotteryStausModal = () => ModalLayoutLotteryStatusRef.current.show((p) => !p, {}, { disableBackDrop: true });
    const handleShowSMSStausModal = () => ModalLayoutSMSStatusRef.current.show((p) => !p, {}, { disableBackDrop: true });


    const [FirstNum, setFirstNum] = useState("")
    const [SecNum, setSecNum] = useState("")
    const [ThirdNum, setThirdNum] = useState("")
    const [FouthNum, setFouthNum] = useState("")


    const getApi = async () =>
        axiosInstance.get(`${api?.travel.chart}?travel_id=${queryParams?.id}`);


    const { data, isLoading } = useQueryCustom({
        name: `${api.report?.base}_list_chart_data_lottery`,
        url: getApi,
        staleTime: 0, // Data becomes stale immediately
        cacheTime: 0,
        // onSuccess: (res) => {

        // },
        // params: { ...queryString },
    });


    const handleStartLottery = () => {
        console.log({
            FirstNum, SecNum, ThirdNum, FouthNum
        })
        if (FirstNum?.toString()?.trim() === "") {
            toast.error("لطفا همه ی اعداد را وارد کنید!")
            return;
        }
        if (SecNum?.toString()?.trim() === "") {
            toast.error("لطفا همه ی اعداد را وارد کنید!")
            return;
        }
        if (ThirdNum?.toString()?.trim() === "") {
            toast.error("لطفا همه ی اعداد را وارد کنید!")
            return;
        }
        if (FouthNum?.toString()?.trim() === "") {
            toast.error("لطفا همه ی اعداد را وارد کنید!")
            return;
        }
        handleShowLotteryStausModal();
        setTimeout(() => {
            handleShowLotteryStausModal();
            handleShowSMSStausModal()
        }, 15000);
    }


    return (
        <>
            <ModalLayout ref={ModalLayoutLotteryStatusRef} >

                <Box
                    sx={{
                        width: '100%',
                        maxWidth: '550px',
                        // display: { xs: 'block', md: 'flex' },
                        gap: 2,
                        bgcolor: 'background.paper',
                        borderRadius: 1,
                        px: 3,
                        py: 3,
                    }}
                >

                    <Stack >

                        <CircularProgress
                            sx={{
                                color: "#4E3503",
                                mx: "auto"
                            }}
                        />

                        <Typography sx={{
                            fontWeight: 700,
                            fontSize: 18,
                            textAlign: "center",
                            my: 4
                        }}>
                            درحال قرعه کشی زائرین
                        </Typography>
                    </Stack>


                    {/* برنده های قرعه کشی مشخص شده اند */}

                    {/*<Typography sx={{
                        fontWeight: 700,
                        fontSize: 18,
                        textAlign: "center",
                        my: 4
                    }}>
                        حجم دانلود شده : {downloadProgress} مگابایت
                    </Typography> */}

                </Box>

            </ModalLayout>
            <ModalLayout ref={ModalLayoutSMSStatusRef} >

                <Box
                    sx={{
                        width: '100%',
                        maxWidth: '550px',
                        // display: { xs: 'block', md: 'flex' },
                        gap: 2,
                        bgcolor: 'background.paper',
                        borderRadius: 1,
                        px: 3,
                        py: 3,
                    }}
                >

                    <Stack >

                        {/* <CircularProgress
                            sx={{
                                color: "#4E3503",
                                mx: "auto"
                            }}
                        /> */}

                        <Typography sx={{
                            fontWeight: 700,
                            fontSize: 18,
                            textAlign: "center",
                            mb: 2
                        }}>
                            قرعه کشی به پایان رسید.
                        </Typography>

                        <Typography sx={{
                            fontWeight: 700,
                            fontSize: 18,
                            textAlign: "center",
                            mb: 4
                        }}>
                            در حال ارسال پیامک برای برندگان قرعه کشی
                        </Typography>
                    </Stack>


                    {/* برنده های قرعه کشی مشخص شده اند */}

                    {/*<Typography sx={{
                        fontWeight: 700,
                        fontSize: 18,
                        textAlign: "center",
                        my: 4
                    }}>
                        حجم دانلود شده : {downloadProgress} مگابایت
                    </Typography> */}


                    <Stack direction={"row"} justifyContent={"center"}>
                        <Button variant='contained' color='success'
                            onClick={handleShowSMSStausModal}
                            sx={{
                                width: "",
                            }}
                        >
                            بستن
                        </Button>
                    </Stack>

                </Box>

            </ModalLayout>

            <Box sx={{
                backgroundImage: "url(/images/texture.png)",
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
                bgcolor: "#FFF9EC",
                minHeight: "100vh",
                py: 5,
            }}>

                <Image src="/images/makeh.png"
                    sx={{
                        maxWidth: "700px",
                        mx: "auto",
                        px: 1
                    }}
                />
                <Typography
                    sx={{
                        color: "#4E3503",
                        fontWeight: 800,
                        fontSize: 42,
                        textAlign: "center",
                        mt: 2
                    }}
                >
                    قرعه کشی بیستمین دوره عمره دانشگاهیان
                </Typography>

                <Stack direction={"row"} sx={{ mt: 5 }}>

                    <Image src={"/images/imageSq.svg"}
                        sx={{
                            alignSelf: "center",
                            margin: "auto"
                        }}
                    />

                </Stack>

                <Stack direction={{ xs: "column", md: "row" }} justifyContent={"center"} gap={2} sx={{ mt: isLoading ? 2 : -2 }}>


                    <DetailBox
                        title="دانشجویان"
                        greenTitle="پسرمجرد"
                        count={31224}
                        key={`section-1`}
                        value={FirstNum}
                        setValue={setFirstNum}
                    />

                    <DetailBox
                        title="دانشجویان"
                        greenTitle="دخترمجرد"
                        count={30050}
                        key={`section-2`}
                        value={SecNum}
                        setValue={setSecNum}
                    />

                    <DetailBox
                        title="دانشجویان"
                        greenTitle="متاهل"
                        count={48875}
                        key={`section-3`}
                        value={ThirdNum}
                        setValue={setThirdNum}
                    />

                    <DetailBox
                        title="استاد دانشگاه"
                        greenTitle="باهمراه"
                        count={19292}
                        key={`section-4`}
                        value={FouthNum}
                        setValue={setFouthNum}
                    />



                </Stack>

                {!isLoading &&
                    <>
                        <Stack direction={"row"} justifyContent={"center"} mt={8}
                            sx={{
                                position: "relative"
                            }}
                        >
                            <Box
                                sx={{
                                    position: "relative",
                                    display: "flex",
                                    // alignItems: "center",
                                    "&:hover > span:first-of-type": {
                                        transform: "translateX(100%)",
                                    },
                                    "&:hover > span:last-of-type": {
                                        transform: "translateX(-100%) rotate(180deg)",
                                    },
                                }}
                            >
                                <Image
                                    src={"/images/imageSq2.svg"}
                                    sx={{
                                        transform: "translateX(0%)",
                                        height: 70,
                                        transition: "all .5s ease",
                                        position: "relative",
                                        zIndex: 0,
                                    }}
                                />
                                <Button
                                    sx={{
                                        minWidth: "230px",
                                        backgroundColor: "#4E3503",
                                        color: "#fff",
                                        textAlign: "center",
                                        cursor: "pointer",
                                        alignContent: "center",
                                        position: "relative",
                                        zIndex: 9,
                                        fontSize: 19,
                                        borderRadius: 0,
                                        p: 1,
                                        "&:hover": {
                                            bgcolor: "#4E3503"
                                        }
                                    }}
                                    onClick={handleStartLottery}
                                >
                                    قرعه کشی
                                </Button>
                                <Image
                                    src={"/images/imageSq2.svg"}
                                    sx={{
                                        transform: "translateX(-10%) rotate(180deg)",
                                        height: 70,
                                        transition: "all .5s ease",
                                        position: "relative",
                                        zIndex: 0,
                                    }}
                                />
                            </Box>
                        </Stack>
                    </>
                }



            </Box>

        </>
    );
}

export default LotterySitePage;