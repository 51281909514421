import { travelTypeObject } from '../../enumeration';
import { PATH_DASHBOARD } from '../../routes/paths';
import api from '../../services/api';

const EAgent = {
  title: {
    name: ['تنظیمات', 'تنظیمات'],
  },
  routes: PATH_DASHBOARD.settings,
  api: api.settings,
};

export default EAgent;
