import * as yup from 'yup';
import errorsText from '../../../utils/errorsText';

const validation = {
  fieldNames: {
    old_password: 'old_password',
    password: 'password',
    confirm_password: 'confirm_password',
  },
  defaultValue:{
    old_password : "",
    password : "",
    confirm_password : "",
  },
  schema: (isEdit) => {
    const obj = {
      [validation.fieldNames.old_password]: yup.string().required(errorsText.blankError()),
      [validation.fieldNames.password]: yup.string().min(8, errorsText.min(8)).required(errorsText.blankError()),
      [validation.fieldNames.confirm_password]: yup
        .string()
        .oneOf([yup.ref(validation.fieldNames.password), null], errorsText.passwordNotMatch()),
    };
    return yup.object().shape(obj);
  },
};
// "Passwords must match"
export default validation;
