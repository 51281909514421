import { travelTypeObject } from '../../enumeration';
import { PATH_DASHBOARD } from '../../routes/paths';
import api from '../../services/api';

const EAgent = {
  title: {
    name: ['فعالیت ادمین ها', 'فعالیت ادمین ها'],
  },
  routes: PATH_DASHBOARD.log,
  api: api.log,
};

export default EAgent;
