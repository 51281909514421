import { Button } from '@mui/material';
import { Box } from '@mui/system';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';
import { haveHasType, caravanType } from '../../../enumeration';
import { fDate, fDateApi } from '../../../utils/formatTime';
import Enum from '../enum';
import MoreTableItem from '../../../components/table/MoreTable/MoreTableItem';
import MoreTable from '../../../components/table/MoreTable/index';
import { separateNumberWithComma } from '../../../utils/index';

export const tableData = ({ baseRoute, queryString, disabled , onDelete }) => {
  const tableOptions = [
    {
      header: 'نام',
      Component: ({ param }) => param?.name || '-',
    },
    // {
    //   key: 'university',
    //   header: 'دانشگاه',
    //   Component: ({ param }) => param?.university?.name || '-',
    // },
    // {
    //   key: 'university_category',
    //   header: 'نوع دانشگاه',
    //   Component: ({ param }) => param?.university_category?.name || '-',
    // },
    // {
    //   key: 'flight_station',
    //   header: 'ایستگاه پروازی',
    //   Component: ({ param }) => param?.flight_station || '-',
    // },
    // {
    //   key: 'price',
    //   header: 'قیمت',
    //   Component: ({ param }) => separateNumberWithComma(param?.price) || '-',
    // },
    // {
    //   key: 'storage_capacity',
    //   header: 'ظرفیت ذخیره',
    //   Component: ({ param }) => param?.storage_capacity || '-',
    // },
    // {
    //   key: 'storage_max',
    //   header: 'سقف ذخیره',
    //   Component: ({ param }) => separateNumberWithComma(param?.storage_max) || '-',
    // },
    // {
    //   key: 'team_grade',
    //   header: 'درجه کاروان',
    //   Component: ({ param }) => param?.team_grade || '-',
    // },
    // {
    //   key: 'capacity',
    //   header: 'ظرفیت',
    //   Component: ({ param }) => separateNumberWithComma(param?.capacity) || '-',
    // },
    // {
    //   key: 'team_type',
    //   header: 'نوع کاروان',
    //   Component: ({ param }) => caravanType[param?.team_type]?.label || '-',
    // },
    // {
    //   key: 'is_lottery',
    //   header: 'قرعه کشی',
    //   Component: ({ param }) => haveHasType[param?.is_lottery]?.label || '-',
    // },

    // {
    //   key: 'baj_number',
    //   header: 'شماره باج',
    //   Component: ({ param }) => param?.baj_number || '-',
    // },
    // {
    //   key: 'border',
    //   header: 'مرز',
    //   Component: ({ param }) => param?.border || '-',
    // },
    // {
    //   key: 'border_code',
    //   header: 'کد مرز',
    //   Component: ({ param }) => param?.border_code || '-',
    // },
    {
      key: 'dispatch_date',
      header: 'تاریخ اعزام',
      Component: ({ param }) => (param?.dispatch_date ? fDateApi(param?.dispatch_date) : '-'),
    },
    // {
    //   key: 'period_from',
    //   header: 'بازه از تاریخ',
    //   Component: ({ param }) => (param?.dispatch_date ? fDateApi(param?.period_from) : '-'),
    // },
    // {
    //   key: 'period_to',
    //   header: 'بازه تا تاریخ',
    //   Component: ({ param }) => (param?.dispatch_date ? fDateApi(param?.period_to) : '-'),
    // },
    // {
    //   key: 'meeting_date',
    //   header: 'تاریخ جلسه',
    //   Component: ({ param }) => (param?.dispatch_date ? fDateApi(param?.meeting_date) : '-'),
    // },
    // {
    //   key: 'address',
    //   header: 'آدرس',
    //   Component: ({ param }) => param?.address || '-',
    // },
    {
      key: 'created_at',
      header: 'تاریخ ایجاد',
      Component: ({ param }) => fDate(param?.created_at) || '-',
    },
    // {
    //   key: 'actions',
    //   header: '',
    //   Component: ({ param }) => (
    //     <MoreTable>
    //       <Link to={`${baseRoute + Enum.routes.edit}/${param?.id}`}>
    //         <MoreTableItem isEdit />
    //       </Link>
    //       {/* <MoreTableItem isDelete onClick={() => onDelete(param)} /> */}
    //     </MoreTable>
    //   ),
    //   free: true,
    // },
    {
      key: 'show',
      header: '',
      Component: ({ param }) => (
        <Box
          sx={{
            display: 'flex',
            // gap: 3,
            alignItems: 'center',
          }}
        >
          {!disabled?.delete ? (
              <MoreTableItem isDelete disabled={{ label: true }} onClick={() => onDelete(param)}/>
          ) : (
            ''
          )}
          {!disabled?.update ? (
            <Link to={`${baseRoute + Enum.routes.edit}/${param?.id}`}>
              <MoreTableItem isEdit disabled={{ label: true }} />
            </Link>
          ) : (
            ''
          )}

          {!disabled?.read ? (
            <Link
              to={`${baseRoute}${Enum.routes.show}/${param.id}/${Enum.enumTab.array[0]?.value}`}
              state={{ query: queryString, route: baseRoute }}
            >
              <MoreTableItem
                icon={
                  <KeyboardBackspaceIcon
                    // fontSize="17px !important"
                    sx={{
                      cursor: 'pointer',
                      color: 'grey.700',
                      fontSize: '20px !important',
                    }}
                  />
                }
                disabled={{ label: true }}
              />
            </Link>
          ) : (
            ''
          )}
        </Box>
      ),
      free: true,
    },
  ];
  // console.log({ AgentsInData });
  // if (AgentsInData?.length) {
  //   AgentsInData?.forEach((x) => {
  //     tableOptions.unshift({
  //       key: 'aa',
  //       header: x?.name,
  //       Component: ({ param }) => {
  //         // console.log('aaaaaaaa', { param }, param?.agents?.length);
  //         const dat = param?.agents?.find((y) => y?.agent?.id === x.id);
  //         return dat?.name || '-';
  //       },
  //     });
  //   });
  // }

  return tableOptions;
};
