import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Box, Button, Container, Modal } from '@mui/material';
import QuestionComponent from 'src/components/ui/Question';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../../../utils/axios';
import { useMutationCustom, useQueryCustom } from '../../../../../utils/reactQueryHooks';
import useQueryString from '../../../../../utils/useQueryString';
import Enum from '../../../enum';
import Page from '../../../../../components/Page';
import HeaderBreadcrumbs from '../../../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../../../routes/paths';
import useSettings from '../../../../../hooks/useSettings';
import Iconify from '../../../../../components/Iconify';
import TableComponent from '../../../../../components/table/TableComponent';
import { tableData } from './tableInfo';
import useTable from '../../../../../hooks/useTable';
import ShowCaravansManagementLayout from '../../Layout';

const Client = () => {
  const [toggleModal, setToggleModal] = useState();

  const { t } = useTranslation();
  const queryParams = useParams();
  const queryString = useQueryString();
  const paramId = queryParams?.id;

  const { themeStretch } = useSettings();

  const tableSetting = useTable({ queryString });
  console.log({ tableSetting, queryParams });
  // ------------------------------------------------------- query Get

  const getById = () => axiosInstance.get(`${Enum?.api?.base}/${paramId}`);

  const teamById = useQueryCustom({
    name: `get_by_Id_${Enum?.api?.base}_${paramId}`,
    url: getById,
    enabled: !!paramId,
  });

  const getting = async () => {
    const { filters, ...others } = queryString;
    let newFilters;
    if (filters) newFilters = JSON.parse(filters || '')?.map((x) => ({ input_id: x.input_id, value: x.value }));
    return axiosInstance.get(`${Enum.api.client}`, {
      params: { ...others, ...(filters && { filters: JSON.stringify(newFilters) }), team_id: queryParams.id },
    });
  };
  // return  axiosInstance.get(`${Enum.api.client}`, { params: { ...queryString, team_id: queryParams.id } });}
  // ---------
  const creating = (params) => axiosInstance.post(Enum.api.client, params);

  const onSuccessMutating = () => {
    toast.success(t('successfully'));
    setToggleModal(false);
    tableSetting.onResetSelectedRow();
    // navigate(backUrl);
  };

  // const onErrorMutating = (error) => {
  //   console.log('* * * onErrorMutating :', { error });
  //   const errorTitle = error.response.data.message || t('errorTryAgain');
  //   const errors = Object.values(error?.response?.data?.errors || {});
  //   if (errors?.length) {
  //     errors?.map((x) => {
  //       return toast.error(x?.[0]);
  //     });
  //   } else toast.error(errorTitle);
  // };

  const mutateAdd = useMutationCustom({
    url: creating,
    name: `${Enum?.api?.base}_update`,
    invalidQuery: `${Enum.api.client}_get_${queryParams.id}`,
    // invalidQuery: `${Enum?.api?.base}_get`,
    onSuccess: onSuccessMutating,
    // onError: onErrorMutating,
  });

  const onSubmit = async () => {
    console.log('* * * onSubmit : ', { tableSetting });
    // const objectId = data?.data?.reduce((prev, curr) => {
    //   prev[curr?.id] = curr;
    //   return prev;
    // }, {});
    const reqData = {
      team_id: queryParams.id,
    };
    tableSetting?.selected?.forEach((x, i) => {
      reqData[`clients[${i}][id]`] = x?.client?.id;
      reqData[`clients[${i}][bed]`] = 0;
      // if (objectId?.[x]?.client?.id) reqData[`clients[${i}][id]`] = objectId?.[x]?.client?.id;
    });

    const formData = new FormData();
    Object.keys(reqData || {})?.map((x) => {
      formData.append(x, reqData[x]);
    });
    // if (paramId) {
    //   formData.append('_method', 'put');
    // }

    console.log('* * * onSubmit : ', { reqData, tableSetting });
    mutateAdd.mutate(formData);
  };
  // ---------

  const {
    data: { data = {} } = {},
    error,
    isError,
    isLoading,
    refetch,
    isFetching,
  } = useQueryCustom({
    name: `${Enum.api.client}_get_${queryParams.id}`,
    url: getting,
    params: { ...queryString },
  });

  const handleToggleModal = () => setToggleModal((p) => !p);

  const tableOptions = tableData({
    baseRoute: `${Enum.routes.root(queryParams.type, queryParams.travel)}`,
    travel: queryParams.travel,
  });

  console.log({ teamById, data });

  return (
    <ShowCaravansManagementLayout
      showClientButton={
        tableSetting?.selected?.length && {
          onClick: handleToggleModal,
          label: 'حذف از کاروان',
        }
      }
    >
      <Modal
        open={toggleModal}
        onClose={handleToggleModal}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 6,
        }}
      >
        <QuestionComponent
          {...{
            loading: mutateAdd?.isLoading,
            title: 'آیا از حذف مطمئن هستید؟',
            description: 'پس از تایید حذف انجام خواهد شد . آیا ادامه میدهید؟',
            button: {
              confirm: {
                label: 'question.yesProcess',
                onClick: onSubmit,
              },
              reject: {
                label: 'question.no',
                onClick: handleToggleModal,
              },
            },
          }}
          // onClose={handleQuestionModal}
        />
      </Modal>
      <TableComponent
        active={{
          selectable: true,
          // selectable: {
          //   find: (data) => data?.client,
          // },
          rowNumber: true,
        }}
        options={tableOptions}
        data={data?.data}
        tableSetting={tableSetting}
        pagination={{
          totalPage: data?.meta?.last_page,
          total: data?.meta?.total,
        }}
        loading={isLoading}
      />
    </ShowCaravansManagementLayout>
  );
};

export default Client;
