/* eslint-disable no-lonely-if */
/* eslint-disable no-plusplus */
import { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Box, Container, Modal, Button, Typography, CircularProgress, Grid, FormHelperText } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import CircularJSON from 'circular-json';

import { CheckCircle, ControlPoint } from '@mui/icons-material';
import { yupResolver } from '@hookform/resolvers/yup';
import { isArray } from 'lodash';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import axiosInstance from '../../../../utils/axios';
import Enum, { optionsSelect } from '../../enum';
import { useMutationCustom, useQueryCustom } from '../../../../utils/reactQueryHooks';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import useSettings from '../../../../hooks/useSettings';
import WaitingBox from '../../../../components/WaitingBox/index';
import SectionPublic from '../../Section';
import FormProvider from '../../../../components/hook-form/FormProvider';
import { formTypesObject, travelTypeObject } from '../../../../enumeration';
import errorsText from '../../../../utils/errorsText';
import validation from './validation';
import api from '../../../../services/api';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import persianToEnglishNumber from '../../../../utils/persianToEnglishNumber';
import { fDateForApi } from '../../../../utils/formatTime';
import ModalLayout from '../../../../components/ModalLayout';
import QuestionComponent from '../../../../components/ui/Question/index';
import CaravanInput from '../../Section/InputsCore/Caravan';
import CaravanSelector from 'src/pages/caravansManagement/selector';
import { newDate } from '../../../../utils/dating';
import { persianToLatinNumbers } from '../../../../utils';
import axiosInstanceV2 from 'src/utils/axiosV2';

const RegistrantForm = ({ data, refetch, isHistory, travelId, clientId, handleSuccess }) => {
  const ModalLayoutQuestionInputRef = useRef();
  const ModalLayoutSuccessRef = useRef();

  const handleQuestionModal = (params) => ModalLayoutQuestionInputRef.current.show((p) => !p, params);
  const handleSuccessModal = (params) => ModalLayoutSuccessRef.current?.show((p) => !p, params);

  const queryParams = useParams();
  const { themeStretch } = useSettings();
  const { t } = useTranslation();
  const [validationState, setValidationState] = useState({});
  const [TravelApi, setTravelApi] = useState();
  const [inputsById, setInputsById] = useState();
  const [beforeRegistrant, setBeforeRegistrant] = useState();
  const [successBeforeRegistrant, setSuccessBeforeRegistrant] = useState();
  const [caravanOptions, setCaravanOptions] = useState();

  const [HideForWemen, setHideForWemen] = useState(false)
  const [CampnionArrState, setCampnionArrState] = useState([])

  const [companionSectionId, setCompanionSectionId] = useState();
  // const ModalSectionFormRef = useRef();
  const isAttabat = [travelTypeObject.atabat_aliat.value, travelTypeObject.other.value].includes(
    // queryParams.type
    data?.form?.travel?.type
  );

  const isFirst = data.form.order == 0;
  const members = !isFirst && data.members;

  console.log({ isAttabat, caravanOptions, inputsById });
  const navigate = useNavigate();
  const [SearchParams] = useSearchParams();

  const methods = useForm({
    resolver: yupResolver(validation.schema(validationState)),
    // mode: 'onChange',
    mode: 'all',
    shouldUnregister: false,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;

  const isError = Object.keys(errors)?.length;

  const values = getValues();
  console.log({ errors, values });
  // ------------------------------------------------------------------------------ modal ref

  // ------------------------------------------------------------------------------ modal toggle

  // ------------------------------------------------------------------------------ Mutation service
  // ----------------------------------------------------------------------------- SERVICE
  const creating = (params) => axiosInstance.post(api.travelRegister.base, params);
  const updating = (params) =>
    axiosInstance.post(`${api.travelRegister.base}/${travelId || data?.form?.travel?.id}`, params);
  // const getById = async ({ queryKey }) => {
  //   const [_, params] = queryKey || [];
  //   return axiosInstance.get(`${Enum?.api?.base}/${id}`);
  // };
  // const getById = () => axiosInstance.get(`${api.input.base}/${data}`);
  // ------------------------------------------------------------------------------ Mutation
  const onSuccessMutating = (data) => {
    handleSuccessModal();
    refetch?.();
    handleSuccess?.(data);
  };

  const onErrorMutating = (error) => {
    console.log({ error });
    const errorTitle = error.response.data.message || t('errorTryAgain');

    const errors = Object.values(error?.response?.data?.errors || {});
    if (errors?.length) {
      errors?.map((x) => {
        return toast.error(x?.[0]);
      });
    } else toast.error(errorTitle);
  };

  const { isLoading, mutate } = useMutationCustom({
    url: beforeRegistrant ? updating : creating,
    name: `${api.public.travelRegister.base}_add`,
    // invalidQuery: `${Enum?.api?.base}_get_${id}_${travel}`,
    onSuccess: onSuccessMutating,
    // onError: onErrorMutating,
  });
  // -------
  // ------------------------------------------------------------------------------ handler

  const [FirstFormData, setFirstFormData] = useState({})


  const onSubmit = async () => {
    handleQuestionModal();
    const { team, ...values } = getValues();
    const final = {};
    let i = 0;
    let companionsIndex = 1;
    let companionsItemIndex = 1;

    console.log('* * * onSubmit : ', { values, companionSectionId });

    const formData = new FormData();
    formData.append('form_id', data?.form?.id);
    formData.append('travel_id', data?.form?.travel?.id);
    if (isAttabat) formData.append('team_id', team?.value);
    if (beforeRegistrant) formData.append('_method', 'put');
    if (clientId) formData.append('client_id', clientId);

    Object.keys(values || {})?.forEach((x) => {
      const currentX = values[x];
      if (+x === +companionSectionId) {
        currentX?.forEach((current) => {
          formData.append(`companions[${companionsIndex}][0][client_id]`, FirstFormData?.clients[companionsIndex]?.id);
          Object.keys(current || {})?.forEach((y) => {
            console.log('* * * onSubmit isCompanion : ', { y });
            const currentY = current[y];
            if (currentY !== undefined) {
              let newVal = currentY?.value || currentY;

              try {
                if (newVal instanceof Date) {
                  console.log('* * * onSubmit isCompanion The value is a Date object.');
                  newVal = fDateForApi(newVal);
                }
              } catch (error) {
                console.log({ error });
              }
              const [_, id] = y?.split('_');
              if (inputsById[id]?.type === formTypesObject.IMAGE.value) {
                if (typeof newVal === 'string') return;
              }
              const val = persianToEnglishNumber(newVal);

              formData.append(`companions[${companionsIndex}][${companionsItemIndex}][input_id]`, id);
              if (isArray(newVal)) {
                newVal = newVal?.forEach((x, j) => {
                  const val = persianToEnglishNumber(newVal);
                  formData.append(`companions[${companionsIndex}][${companionsItemIndex}][value][${j}]`, x?.value);
                });
              } else {
                formData.append(`companions[${companionsIndex}][${companionsItemIndex}][value]`, val);
              }

              // console.log("* * * onSubmit : ",{id,val},   isArray(val));
              final[x] = {
                ...final[x],
                [id]: val,
              };
              companionsItemIndex += 1;
            }
          });
          companionsIndex += 1;
        });
      } else {
        Object.keys(currentX || {})?.forEach((y) => {
          console.log('* * * onSubmit : ', { y });
          const currentY = currentX[y];
          if (currentY !== undefined) {
            let newVal = currentY?.value || currentY;

            try {
              if (newVal instanceof Date) {
                console.log('* * * onSubmit The value is a Date object.');
                newVal = fDateForApi(newVal);
              }
            } catch (error) {
              console.log({ error });
            }
            const [_, id] = y?.split('_');

            if (inputsById[id]?.type === formTypesObject.IMAGE.value) {
              if (typeof newVal === 'string') return;
            }

            const val = persianToEnglishNumber(newVal);

            formData.append(`array[${i}][input_id]`, id);
            if (isArray(newVal)) {
              newVal = newVal?.forEach((x, j) => {
                formData.append(`array[${i}][value][${j}]`, x?.value);
              });
            } else {
              formData.append(`array[${i}][value]`, val);
            }

            // console.log("* * * onSubmit : ",{id,val},   isArray(val));
            final[x] = {
              ...final[x],
              [id]: val,
            };
            i += 1;
          }
        });
      }

      return true;
    });

    // Object.values(formData)?.forEach((x)=>{
    //   console.log("* * * onSubmit values: ",{x});
    //   })

    console.log('* * * onSubmit : ', { final, entries: formData.values() });

    for (const keys of formData.keys()) {
      console.log('* * * onSubmit : ', { keys, value: formData.get(keys) });
    }

    mutate(formData);
    // setSuccessBeforeRegistrant(beforeRegistrant);
  };

  // const persianNumber = 'asdad۱۲۳۴۵۶';
  // const englishNumber = persianToEnglishNumber(persianNumber);
  // console.log({englishNumber});
  // const getting = async () =>
  //   axiosInstance.get(api.section.base, {
  //     params: {
  //       form_id: id,
  //     },
  //   });

  const [UserCanSeeInputs, setUserCanSeeInputs] = useState([])

  const onSuccess = (firstFormData) => {
    // console.log('* * * onSuccess : ', { data });

    // const missafirRooms = yup
    // 	.object({
    // 		// [fieldNames.address]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.address)).label(fieldNames.address),
    // 		// [fieldNames.roomNumber]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.roomNumber)).label(fieldNames.roomNumber),
    // 	})
    // 	.defined();

    const userInputCanSee = []
    const validationsObject = {};
    const resetData = {};
    const companionInfo = {
      section: null,
      id: null,
      count: members || 0,
    };

    const caravanInfo = {
      companions: {
        name: '',
      },
      // marital:{
      //   name:''
      // },
      gender: {
        name: '',
      },
    };

    let isBeforeRegistrant = false;

    const allInputLabels = {};
    const formData = data?.form;
    const inputIdsObject = {};
    const companionClientArr = []
    let filterCompanionClientArr = [];

    console.log('* * * onSuccess : ', { formData });
// 
  setFirstFormData(firstFormData?.data);

    if (data?.form?.name === "اطلاعات نظام وظیفه") {
      const firstFormUserData = firstFormData?.data?.clients[0];
      // const userMaritalStatus = firstFormUserData?.sections[0]?.inputs?.find(item => item?.label === "جنسیت");
      const userMaritalStatus = firstFormUserData?.gender;

      const userDutyStatus = firstFormUserData?.nezam_vazife_status;

      for (let i = 1; i < firstFormData?.data?.clients?.length; i += 1) {
        const element = firstFormData?.data?.clients[i];
        if (element?.gender === "مذکر") {
          companionClientArr.push({
            ...element,
            sectionName : i == 1 ? "همسر" : `فرزند ${i-1}`
          })
        }else{
          companionClientArr.push({
            ...element,
            hideThisCompanion : true,
          })
        }
      }
      filterCompanionClientArr = companionClientArr?.filter(item => !item?.hideThisCompanion);
      companionInfo.count = filterCompanionClientArr?.length;

      setCampnionArrState(companionClientArr)

      // if(userMaritalStatus?.client_inputs?.[0]?.value === "مونث"){
      if (userMaritalStatus === "مونث" && companionClientArr?.length === 0) {
        setHideForWemen(true)
      } else if (userMaritalStatus === "مونث" && companionClientArr?.length > 0) {
        for (let i = 0; i < formData.sections?.length; i++) {
          const element = formData.sections[i];
          if (element?.label !== "اطلاعات همراه") {
            element.hideThisSection = true;
          }
        }
      } else if (userDutyStatus !== "معافیت تحصیلی" && userMaritalStatus === "مذکر" && filterCompanionClientArr?.length === 0) {
        setHideForWemen(true)
      }
    } else {
      setHideForWemen(false)
    }

    console.log({firstFormData})

    for (let i = 0; i < formData?.sections?.length; i += 1) {
      const section = { ...formData?.sections?.[i] };
      for (let j = 0; j < section?.inputs?.length; j += 1) {
        const input = section.inputs[j];
        let options;
        try {
          options = JSON.parse(input.options || '{}');
        } catch (error) {
          options = input.options;
        }
        if (data?.form?.name?.trim() === "بارگزاری مدارک") {


          // const firstFormUserData = firstFormData?.data?.form ;
          const firstFormUserData = firstFormData?.data?.clients[0];

          // const userMaritalStatus = firstFormUserData?.sections[0]?.inputs?.find(item => item?.label === "وضعیت تاهل");
          // const userTasharofBeSorat = firstFormUserData?.sections[0]?.inputs?.find(item => item?.label === "تشرف به صورت")
          const userMaritalStatus = firstFormUserData?.marital_status;
          const userTasharofBeSorat = firstFormUserData?.tasharof_be_soorat;
          const userHamrahCount = firstFormUserData?.hamrah_count || 0;

          for (let i = 1; i < firstFormData?.data?.clients?.length; i += 1) {
            const element = firstFormData?.data?.clients[i];
            companionClientArr.push(element)
          }

          if(section?.inputs?.[j]?.client_inputs?.length > 0 && section?.inputs?.[j]?.type === "IMAGE"){
            userInputCanSee.push(section.inputs[j])
          }

          setCampnionArrState(companionClientArr)

          if (userMaritalStatus) {
            // if(userMaritalStatus?.client_inputs?.[0]?.value === "مجرد"){
            if (
              (userMaritalStatus === "مجرد") ||
              (userHamrahCount == 0)
            ) {
              if (
                section.inputs[j]?.label === "اسکن صفحه ازدواج شناسنامه" ||
                section.inputs[j]?.label === "اسکن عقد نامه" ||
                section.inputs[j]?.label === "اسکن صفحه ازدواج شناسنامه یا عقد نامه" ||
                section.inputs[j]?.label === "اسکن صفحه ازدواج شناسنامه یا اسکن عقدنامه"
              ) {
                options.hideThisItem = true;
                options.required = false
              }
            }
          }

          if (userTasharofBeSorat) {
            if (userTasharofBeSorat !== "استاد دانشگاه") {
              if (
                section.inputs[j]?.label === "حکم کارگزینی یا گواهی اشتغال به تدریس"
              ) {
                options.hideThisItem = true
                options.required = false
              }
            }else{
              if (
                section.inputs[j]?.label === "اسکن گواهی اشتغال به تحصیل"
              ) {
                options.hideThisItem = true
                options.required = false
              }
            }
          }

        }
        input.options = options;
        allInputLabels[input?.label?.trim()] = {
          section: {id : section?.id},
          input: input,
          sectionIndex: i,
          inputIndex: j,
          name: `${section.id}.${Enum.bseName}${input.id}`,
        };
        if (input?.client_inputs?.[0]?.value) isBeforeRegistrant = true;
        inputIdsObject[input?.id] = input;
      }
    }
    setInputsById(inputIdsObject);
    console.log('* * * dynamicly', { allInputLabels, validationsObject });
    console.log({userInputCanSee})
    setUserCanSeeInputs(userInputCanSee);
    // const aaa=["وضعیت تاهل","تاریخ عقد","ازدواج دانشجویی","همسر دانشجو"]

    const gender = allInputLabels['جنسیت'];
    const genderCompanion = allInputLabels['جنسیت همراه'];
    const companion = allInputLabels['تعداد همراه'];
    const maritalStatus = allInputLabels['وضعیت تاهل'];
    const marriageDate = allInputLabels['تاریخ عقد'];
    const studentMarriage = allInputLabels['ازدواج دانشجویی'];
    const studentWife = allInputLabels['همسر دانشجو'];
    const withCompanion = allInputLabels['اعزام با همراه'];
    const withCompanion1 = allInputLabels['نام'];
    const tasharofBeSorat = allInputLabels['تشرف به صورت'];
    const hasDisability = allInputLabels['دچار معلولیت جسمی هستم'];
    const disabilityText = allInputLabels['توضیحات معلولیت'];
    const typeOfEmployment = allInputLabels['نوع حکم کارگزینی'];
    const typeOfEmploymentState = allInputLabels['مرتبه دانشگاهی'];
    const dutySystem = allInputLabels['وضعیت نظام وظیفه'];
    const dutySystemCompanion = allInputLabels['وضعیت نظام وظیفه همراه'];
    const RelativeToTheApplicant = allInputLabels['نسبت با متقاضی'];
    const universityTypeCompanion = allInputLabels['نوع دانشگاه همراه'];
    const universityProvinceCompanion = allInputLabels['استان محل تحصیل همراه'];
    const universityCodeCompanion = allInputLabels['کد دانشگاه همراه'];
    const passportStatus = allInputLabels['وضعیت گذرنامه'];


    const AChilds = [withCompanion, tasharofBeSorat]?.filter((x) => x?.name);
    const AChildObject = AChilds?.reduce((prev, curr) => {
      prev[curr?.input?.id] = curr;
      return prev;
    }, {});

    const AChilds1 = [withCompanion]?.filter((x) => x?.name);
    const AChildObject1 = AChilds1?.reduce((prev, curr) => {
      const curr1 = {...curr};
      // delete curr1.section;
      // delete curr1.input.options;
      prev[curr?.input?.id] = [curr?.input?.id];
      return prev;
    }, {});

    const BChilds = [companion]?.filter((x) => x?.name);
    const BChildObject = BChilds?.reduce((prev, curr) => {
      prev[curr?.input?.id] = curr;
      return prev;
    }, {});

    // const CChilds = [tasharofBeSorat, dutySystem, passportStatus]?.filter((x) => x?.name);
    const CChilds = [tasharofBeSorat, dutySystem]?.filter((x) => x?.name);
    const CChildObject = CChilds?.reduce((prev, curr) => {
      prev[curr?.input?.id] = curr;
      return prev;
    }, {});
    const CChilds1 = [dutySystem]?.filter((x) => x?.name);
    const CChildObject1 = CChilds1?.reduce((prev, curr) => {
      prev[curr?.input?.id] = [curr?.input?.id];
      return prev;
    }, {});

    const CChildsCom = [dutySystemCompanion]?.filter((x) => x?.name);
    const CChildObjectCom = CChildsCom?.reduce((prev, curr) => {
      prev[curr?.input?.id] = [curr?.input?.id];
      return prev;
    }, {});
    const CChilds1Com = [dutySystemCompanion]?.filter((x) => x?.name);
    const CChildObject1Com = CChilds1Com?.reduce((prev, curr) => {
      prev[curr?.input?.id] = [curr?.input?.id];
      return prev;
    }, {});

    const DChilds = [disabilityText]?.filter((x) => x?.name);
    const DChildObject = DChilds?.reduce((prev, curr) => {
      prev[curr?.input?.id] = curr;
      return prev;
    }, {});

    const EChilds = [marriageDate, studentMarriage, studentWife, typeOfEmployment, typeOfEmploymentState]?.filter((x) => x?.name);
    const EChildObject = EChilds?.reduce((prev, curr) => {
      prev[curr?.input?.id] = curr;
      return prev;
    }, {});
    const EChildObjectFirstPart = [typeOfEmployment, typeOfEmploymentState]?.reduce((prev, curr) => {
      prev[curr?.input?.id] = [curr?.input?.id];
      return prev;
    }, {});
    const EChildObjectSeconfPart = [marriageDate, studentMarriage, studentWife]?.reduce((prev, curr) => {
      prev[curr?.input?.id] = [curr?.input?.id];
      return prev;
    }, {});

    const FChilds = [passportStatus]?.filter((x) => x?.name);
    const FChildObject = FChilds?.reduce((prev, curr) => {
      prev[curr?.input?.id] = curr;
      return prev;
    }, {});

    const GChilds = [RelativeToTheApplicant]?.filter((x) => x?.name);
    const GChildObject = GChilds?.reduce((prev, curr) => {
      prev[curr?.input?.id] = curr;
      return prev;
    }, {});

    const GChilds1 = [universityTypeCompanion, universityProvinceCompanion, universityCodeCompanion]?.filter((x) => x?.name);
    const GChildObject1 = GChilds1?.reduce((prev, curr) => {
      prev[curr?.input?.id] = [curr?.input?.id];
      return prev;
    }, {});

    console.log('* * * dynamicly', { AChildObject1, AChildObject });

    // eslint-disable-next-line no-debugger
    // debugger; 

    const events = {
      ...(maritalStatus?.input?.id && {
        [maritalStatus.input.id]: {
          value: maritalStatus,
          childs: AChildObject,
          afterChange: {
            type: 'resetOptions',
            opType: 'resetOptions',
            on: ['مجرد', 'متاهل'],
            withHidden: false,
            by: 'child',
            filterIndex: 0,
            removeWord: ["متاهل", 'a'],
            hiddenOn: "مجرد",
            hiddenItem: AChildObject1,
            otherParentControll: ["مذکر", "مونث"],
            otherParentControllFilter: ["دختران", "پسران"],
            otherParentFilterIndex: 1
          },
          // validation:{
          //   ne:  "مجرد",
          //   required:AChilds?.map((x)=>x.name),
          // }
        },
      }),
      ...(tasharofBeSorat?.input?.id && {
        [tasharofBeSorat.input.id]: {
          value: tasharofBeSorat,
          childs: EChildObject,
          afterChange: {
            type: 'resetChilds',
            on: ['استاد دانشگاه' , 'دانشجوی متاهل'],
            showOn: true,
            hiddenItem : [EChildObjectFirstPart , EChildObjectSeconfPart] ,
            withHidden: true,
            by: 'child',
          },
          // validation:{
          //   ne:  "مجرد",
          //   required:AChilds?.map((x)=>x.name),
          // }
        },
      }),
      ...(hasDisability?.input?.id && {
        [hasDisability.input.id]: {
          value: hasDisability,
          childs: DChildObject,
          afterChange: {
            type: 'resetChilds',
            on: 'خیر',
            withHidden: true,
            by: 'child',
          },
          // validation:{
          //   ne:  "مجرد",
          //   required:AChilds?.map((x)=>x.name),
          // }
        },
      }),
      ...(withCompanion?.input?.id && {
        [withCompanion.input.id]: {
          value: withCompanion,
          childs: BChildObject,
          afterChange: {
            type: 'resetChilds',
            on: 'خیر',
            withHidden: true,
            by: 'child',
          },
          // validation:{
          //   ne:  "خیر",
          //   required:AChilds?.map((x)=>x.name),
          // }
        },
      }),
      ...(gender?.input?.id && {
        [gender.input.id]: {
          value: gender,
          childs: CChildObject,
          afterChange: {
            type: 'resetOptions',
            on: ["مذکر", "مونث"],
            removeWord: ["دختران", "پسران"],
            filterIndex: 0,
            withHidden: false,
            hiddenOn: "مونث",
            hiddenItem: CChildObject1,
            // removeFilter: "وضعیت گذرنامه(حداقل ۶ ماه اعتبار)",
            by: 'child',
          },
          // validation:{
          //   ne:  "خیر",
          //   required:AChilds?.map((x)=>x.name),
          // }
        },
      }),
      ...(genderCompanion?.input?.id && {
        [genderCompanion.input.id]: {
          value: genderCompanion,
          childs: CChildObjectCom,
          afterChange: {
            type: 'resetOptions',
            on: ["مذکر", "مونث"],
            removeWord: ["دختران", "پسران"],
            filterIndex: 0,
            withHidden: false,
            hiddenOn: "مونث",
            hiddenItem: CChildObject1Com,
            // removeFilter: "وضعیت گذرنامه(حداقل ۶ ماه اعتبار)",
            by: 'child',
          },
          // validation:{
          //   ne:  "خیر",
          //   required:AChilds?.map((x)=>x.name),
          // }
        },
      }),
      ...(studentWife?.input?.id && {
        [studentWife.input.id]: {
          value: studentWife,
          childs: GChildObject,
          afterChange: {
            type: 'resetOptions',
            on: ["بله", "خیر"],
            removeWord: ["aaa", "bbbb"],
            filterIndex: 0,
            withHidden: false,
            hiddenOn: "خیر",
            hiddenItem: GChildObject1,
            checkParentInOtherSection : false,
            by: 'child',
          },
        },
      }),
    };

    if (isAttabat) {
      if (companion?.input?.id) {
        events[companion?.input?.id] = {
          value: companion,
          childs: { team: { name: 'team' } },
          afterChange: {
            type: 'resetChilds',
            by: 'parent',
          },
        };
      }
      if (gender?.input?.id) {
        events[gender?.input?.id] = {
          value: gender,
          childs: { team: { name: 'team' } },
          afterChange: {
            type: 'resetChilds',
            by: 'parent',
          },
        };
      }
    }

    Object.keys(events)?.forEach((x) => {
      const current = events[x];
      if (!current?.value) return;
      const childs = {};
      delete current?.value?.section;
      Object.values(current?.childs || {})?.map((x, i) => {
        // delete current?.childs?.[x?.id]?.section?.[i]?.inputs;
        const { section, ...newChilds } = x || {};
        childs[x?.input?.id] = { ...newChilds, section: { id: section?.id } };
        const options = x?.input?.options || {};
        delete x?.section;
        // try {
        //   options = JSON.parse(x?.input?.options || '{}');
        // } catch (error) {
        //   options = x?.input?.options;
        // }
        if (!options) return;
        // options.parentId = current?.value?.name;
        // options.parentInputName = `${Enum.bseName}${current?.value?.input?.id}`;
        if (options.parentId && options.parentId !== current?.value?.name) {
          options.parentId1 = current?.value?.name;
          options.parentInputName1 = `${Enum.bseName}${current?.value?.input?.id}`;
        } else {
          options.parentId = current?.value?.name;
          options.parentInputName = `${Enum.bseName}${current?.value?.input?.id}`;
        }
        options.required = false;
        options.validation = current.validation;
        options.afterChangeParent = current.afterChange;
        // console.log ("* * * dynamicly",{a: x.input.options,options,current123 : current , x})
        return x;
      });

      const baseInput = formData?.sections?.[current?.value?.sectionIndex]?.inputs?.[current?.value?.inputIndex];
      // console.log('* * * dynamicly 1', { baseInput, current });

      baseInput.options = {
        ...baseInput.options,
        afterChange: current.afterChange,
        childs: childs,
        validation: current.validation,
      };
      console.log('* * * dynamicly 2', { baseInput, current });
    });

    // console.log('* * * dynamicly events', { events, data });
    // let sectionIndex=0

    for (let i = 0; i < formData?.sections?.length; i += 1) {
      let resetValue = null;
      let findItem;
      if (
        formData?.sections?.[i]?.label?.trim() === 'اطلاعات همراه'?.trim() ||
        formData?.sections?.[i]?.label?.trim() === 'مدارک هویتی همراهان'?.trim()
      ) {
        // console.log({ companion: formData?.sections?.[i] });
        formData.sections[i].isCompanions = true;
        formData.sections[i].parentId = companionInfo.id;
        setCompanionSectionId(formData.sections[i].id);
        companionInfo.section = formData.sections[i].id;
      }
      const section = { ...formData?.sections?.[i] };

      validationsObject[section.id] = validationsObject[section.id] || {};

      let parentProvinceName;
      let parentProvinceIndex;
      let parentProvinceNames;
      let parentProvinceIndexes;

       // University
       let parentUniversityProvinceName;
       let parentUniversityProvinceIndex;
       let parentUniversityProvinceNames;
       let parentUniversityProvinceIndexes;
 
       // University Category
       let parentUniversityCategoryName;
       let parentUniversityCategoryIndex;
       let parentUniversityCategoryNames;
       let parentUniversityCategoryIndexes;

      for (let j = 0; j < section?.inputs?.length; j += 1) {
        resetValue = null;

        const input = section.inputs[j];
        input.client_inputs = input.client_inputs?.sort(
          (a, b) => parseFloat(a.companion_number) - parseFloat(b.companion_number)
        );
        const options = input.options || {};
        // const options= JSON.parse(input.options||'{}');

        if (section.inputs[j]?.label?.trim() === 'جنسیت'?.trim()) {
          if (isAttabat) {
            // section.inputs[j].isGender=true;
            caravanInfo.gender.name = `${section.id}.${Enum.bseName}${section.inputs[j].id}`;
          }
        }
        if (section.inputs[j]?.label?.trim() === 'وضعیت تاهل'?.trim()) {
          // if(isAttabat){
          //     section.inputs[j].isMaritalStatus=true;
          //     caravanInfo.marital.name=`${section.id}.${Enum.bseName}${section.inputs[j].id}`
          // }
        }
        if (section.inputs[j]?.label?.trim().includes('تولد')) {
          // if(isAttabat){
          //     section.inputs[j].isMaritalStatus=true;
          //     caravanInfo.marital.name=`${section.id}.${Enum.bseName}${section.inputs[j].id}`
          // }
          options.inputProps = {
            ...options?.inputProps,
            maxDate: new Date(),
          };
        }

        if (section.inputs[j]?.label?.trim() === 'تعداد همراه'?.trim()) {
          section.inputs[j].isCompanions = true;
          companionInfo.id = `${section.id}.${Enum.bseName}${section.inputs[j].id}`;
          companionInfo.count = section.inputs[j]?.client_inputs?.[0]?.value !== "null" ? section.inputs[j]?.client_inputs?.[0]?.value : null;
          caravanInfo.companions.name = `${section.id}.${Enum.bseName}${section.inputs[j].id}`;
          // companionsId
        }

        let valid;
        formData.sections[i].inputs[j].options = options;

        if (options?.isUniversityCategory) {
          if (section.isCompanions) {

            for (let k = 0; k < companionInfo.count; k += 1) {
              parentUniversityCategoryNames = parentUniversityCategoryNames || {};
              parentUniversityCategoryNames[k] = `${section.id}.${k}.${Enum.bseName + input.id}`;
              parentUniversityCategoryIndexes = parentUniversityCategoryIndexes || {};
              parentUniversityCategoryIndexes[k] = j;
            }
          } else {
            parentUniversityCategoryName = `${section.id}.${Enum.bseName + input.id}`;
            parentUniversityCategoryIndex = j;
          }
        }

        if (options?.isProvince) {
          if (section.isCompanions) {
            for (let k = 0; k < companionInfo.count; k += 1) {
              parentProvinceNames = parentProvinceNames || {};
              parentProvinceNames[k] = `${section.id}.${k}.${Enum.bseName + input.id}`;
              parentProvinceIndexes = parentProvinceIndexes || {};
              parentProvinceIndexes[k] = j;

              if (formData.sections[i].inputs[j]?.label?.trim()?.includes("استان محل تحصیل")) {
                parentUniversityProvinceNames = parentUniversityProvinceNames || {};
                parentUniversityProvinceNames[k] = `${section.id}.${k}.${Enum.bseName + input.id}`;
                parentUniversityProvinceIndexes = parentUniversityProvinceIndexes || {};
                parentUniversityProvinceIndexes[k] = j;
              }
            }
          } else {
            parentProvinceName = `${section.id}.${Enum.bseName + input.id}`;
            parentProvinceIndex = j;
            if (formData.sections[i].inputs[j]?.label?.trim()?.includes("استان محل تحصیل")) {
              parentUniversityProvinceName = `${section.id}.${Enum.bseName + input.id}`;
              parentUniversityProvinceIndex = j;
            }
          }
        }
        if (options?.isCity) {
          if (parentProvinceName) {
            formData.sections[i].inputs[j].parentProvinceName = parentProvinceName;
            formData.sections[i].inputs[parentProvinceIndex].childCityName = `${section.id}.${Enum.bseName + input.id}`;
          }
          // console.log('ffff', section.isCompanions && parentProvinceNames);

          if (section.isCompanions && parentProvinceNames) {
            const parentProvinceNamesLength = Object.values(parentProvinceNames);
            for (let k = 0; k < parentProvinceNamesLength?.length; k += 1) {
              // console.log('ffff', k);
              formData.sections[i].inputs[j].parentProvinceNames = {
                ...(formData.sections[i].inputs[j].parentProvinceNames || {}),
                [k]: parentProvinceNames[k],
              };
              formData.sections[i].inputs[parentProvinceIndexes[k]].childCityNames = {
                ...(formData.sections[i].inputs[parentProvinceIndexes[k]].childCityNames || {}),
                [k]: `${section.id}.${k}.${Enum.bseName + input.id}`,
              };
            }
          }

          if (section.isCompanions && parentUniversityCategoryNames) {
            const parentUniversityCategoryNamesLength = Object.values(parentUniversityCategoryNames);
            for (let k = 0; k < parentUniversityCategoryNamesLength?.length; k += 1) {
              // console.log('ffff', k);
              formData.sections[i].inputs[j].parentUniversityCategoryNames = {
                ...(formData.sections[i].inputs[j].parentUniversityCategoryNames || {}),
                [k]: parentUniversityCategoryNames[k],
              };
              formData.sections[i].inputs[parentUniversityCategoryIndexes[k]].childCityNames = {
                ...(formData.sections[i].inputs[parentUniversityCategoryIndexes[k]].childCityNames || {}),
                [k]: `${section.id}.${k}.${Enum.bseName + input.id}`,
              };
            }
          }
        }
        if (options?.isUniversity) {
          if (parentUniversityProvinceName) {
            formData.sections[i].inputs[j].parentUniversityProvinceName = parentUniversityProvinceName;
            formData.sections[i].inputs[parentUniversityProvinceIndex].childCityName = `${section.id}.${Enum.bseName + input.id}`;
          }
          if (parentUniversityCategoryName) {
            formData.sections[i].inputs[j].parentUniversityCategoryName = parentUniversityCategoryName;
            formData.sections[i].inputs[parentUniversityCategoryIndex].childCityName = `${section.id}.${Enum.bseName + input.id}`;
          }
          // console.log('ffff', section.isCompanions && parentUniversityProvinceNames);

          if (section.isCompanions && parentUniversityProvinceNames) {
            const parentUniversityProvinceNamesLength = Object.values(parentUniversityProvinceNames);
            for (let k = 0; k < parentUniversityProvinceNamesLength?.length; k += 1) {
              // console.log('ffff', k);
              formData.sections[i].inputs[j].parentUniversityProvinceNames = {
                ...(formData.sections[i].inputs[j].parentUniversityProvinceNames || {}),
                [k]: parentUniversityProvinceNames[k],
              };
              formData.sections[i].inputs[parentUniversityProvinceIndexes[k]].childCityNames = {
                ...(formData.sections[i].inputs[parentUniversityProvinceIndexes[k]].childCityNames || {}),
                [k]: `${section.id}.${k}.${Enum.bseName + input.id}`,
              };
            }
          }

          if (section.isCompanions && parentUniversityCategoryNames) {
            const parentUniversityCategoryNamesLength = Object.values(parentUniversityCategoryNames);
            for (let k = 0; k < parentUniversityCategoryNamesLength?.length; k += 1) {
              // console.log('ffff', k);
              formData.sections[i].inputs[j].parentUniversityCategoryNames = {
                ...(formData.sections[i].inputs[j].parentUniversityCategoryNames || {}),
                [k]: parentUniversityCategoryNames[k],
              };
              formData.sections[i].inputs[parentUniversityCategoryIndexes[k]].childCityNames = {
                ...(formData.sections[i].inputs[parentUniversityCategoryIndexes[k]].childCityNames || {}),
                [k]: `${section.id}.${k}.${Enum.bseName + input.id}`,
              };
            }
          }
        }
        // if (options?.isProvince) {
        //   parentProvinceName = `${section.id}.${Enum.bseName + input.id}`;
        //   parentProvinceIndex = j;
        // }
        // if (options?.isCity && parentProvinceName) {
        //   formData.sections[i].inputs[j].parentProvinceName = parentProvinceName;
        //   formData.sections[i].inputs[parentProvinceIndex].childCityName = `${section.id}.${Enum.bseName + input.id}`;
        // }

        const phoneRegex = /^(۰۹|09)[0-9\u06F0-\u06F9]{9}$/;
        const nationalCodeRegex = /^[\u06F0-\u06F90-9]{10}$/;
        const emailRegex = /^(?=.{1,256})(?=.{1,64}@.{1,255})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        // const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        switch (input?.type) {
          case formTypesObject.DATE.value:
            // options.required
            // console.log("* * * onSuccess - STRING",{ options },options.required);
            valid = yup.mixed();
            if (options?.validation && options?.parentInputName) {
              valid = valid.when(options.parentInputName, {
                is: (val) => {
                  return options?.validation.is
                    ? val?.value === options?.validation.is
                    : options?.validation.ne
                      ? options?.validation.ne !== val?.value
                      : false;
                },
                then: yup.mixed().nullable().required(errorsText.blankError()),
                otherwise: yup.mixed(),
              });
            } else if (options.required) valid = valid.required(errorsText.blankError());
            if (section.isCompanions)
              resetValue = input?.client_inputs
                // ?.sort((a, b) => parseFloat(a.companion_number) - parseFloat(b.companion_number))
                ?.map((x) => newDate(x.value));
            else resetValue = newDate(input?.client_inputs?.[0]?.value);
            break;
          case formTypesObject.STRING.value:
            // options.required
            // console.log("* * * onSuccess - STRING",{ options },options.required);
            if (section.inputs[j]?.label?.trim().includes('ایمیل')) {
              valid = yup.string().matches(emailRegex, errorsText.invalidValue(input.label));
            } else valid = yup.string().nullable();
            if (options?.validation && options?.parentInputName) {
              valid = valid.when(options.parentInputName, {
                is: (val) => {
                  return options?.validation.is
                    ? val?.value === options?.validation.is
                    : options?.validation.ne
                      ? options?.validation.ne !== val?.value
                      : false;
                },
                then: yup.string().nullable().required(errorsText.blankError()),
                otherwise: yup.string(),
              });
            } else if (options.required) valid = valid.required(errorsText.blankError());
            if (section.isCompanions)
              resetValue = input?.client_inputs
                // ?.sort((a, b) => parseFloat(a.companion_number) - parseFloat(b.companion_number))
                ?.map((x) => x.value);
            else resetValue = input?.client_inputs?.[0]?.value;
            break;
          case formTypesObject.CHECKBOX.value:
            // console.log("* * * onSuccess - CHECKBOX",{ options },options.required);
            valid = yup.array();
            if (options?.validation) {
              if (options.parentId)
                valid.when(options.parentId, {
                  is: (val) => val?.value === options?.validation.is,
                  then: yup.array().required(errorsText.blankError()),
                  otherwise: yup.array(),
                });
            } else if (options.required) valid = valid.required(errorsText.blankError());
            if (section.isCompanions) {
              resetValue = input?.client_inputs
                // ?.sort((a, b) => parseFloat(a.companion_number) - parseFloat(b.companion_number))
                ?.map((x) =>
                  x.value
                    .split(',')
                    ?.map((x) => ({ label: x?.trim(), value: x?.trim(), companion_number: x.companion_number }))
                );
            } else {
              resetValue = input?.client_inputs?.[0]?.value
                ?.split(',')
                ?.map((x) => ({ label: x?.trim(), value: x?.trim() }));
            }
            // console.log('* * * onSuccess - CHECKBOX', { resetValue });

            break;

          case formTypesObject.RADIO.value:
            // console.log("* * * onSuccess - RADIO",{ options },options.required);
            valid = yup.mixed();

            if (options?.validation && options?.parentInputName) {
              valid = valid.when(options.parentInputName, {
                is: (val) => {
                  return options?.validation.is
                    ? val?.value === options?.validation.is
                    : options?.validation.ne
                      ? options?.validation.ne !== val?.value
                      : false;
                },
                then: yup.object().nullable().required(errorsText.blankError()),
                otherwise: yup.mixed(),
              });
            } else if (options.required) valid = valid.required(errorsText.blankError());

            if (section.isCompanions) {
              resetValue = input?.client_inputs
                // ?.sort((a, b) => parseFloat(a.companion_number) - parseFloat(b.companion_number))
                ?.map((x) => ({
                  label: x.value,
                  value: x.value,
                }));
              // console.log('neeed RADIO', { input, resetValue, options });
            } else {
              findItem = options?.items?.filter((x) => input?.client_inputs?.some((y) => y?.value === x.label));
              resetValue = findItem?.length && { label: findItem[0]?.label, value: findItem[0]?.label };
            }

            break;

          case formTypesObject.SELECT.value:
            valid = yup.mixed();
            if (options?.validation && options?.parentInputName) {
              valid = valid.when(options.parentInputName, {
                is: (val) => {
                  return options?.validation.is
                    ? val?.value === options?.validation.is
                    : options?.validation.ne
                      ? options?.validation.ne !== val?.value
                      : false;
                },
                then: yup.object().nullable().required(errorsText.blankError()),
                otherwise: yup.mixed(),
              });
            } else if (options.required) {
              // console.log('* * * aaaaa :', input?.label); // Log the value for debugging
              valid = valid.required(errorsText.blankError());
            }
            // const haveEvent = events[input.id];
            // console.log('* * * dynamicly - SELECT', input?.label, { options }, options.required);
            // companion_number
            findItem = options?.items?.filter((x) => input?.client_inputs?.some((y) => persianToLatinNumbers(y?.value) === persianToLatinNumbers(x.label)));

            if (section.isCompanions) {
              resetValue =
                options?.items?.length &&
                input?.client_inputs
                  // ?.sort((a, b) => parseFloat(a.companion_number) - parseFloat(b.companion_number))
                  ?.map((x) => ({
                    label: x.value,
                    value: x.value,
                  }));

              // resetValue = findItem?.map((x) => ({
              //   label: x.label,
              //   value: x.label,
              //   // companion_number: x.companion_number,
              // }));
              // console.log('neeed SELECT', { input, resetValue, options });
            } else resetValue = findItem?.length && { label: findItem[0]?.label, value: findItem[0]?.label };
            optionsSelect?.map((x) => {
              if (x?.value && options[x.value]) {
                options.selectType = x;
              }
            });
            // console.log('* * * bbb final:', input?.label); // Log the value for debugging

            break;

          case formTypesObject.IMAGE.value:
            // console.log("* * * onSuccess - IMAGE",{ options },options.required);
            valid = yup.mixed();
            if (options?.validation && options?.parentInputName) {
              valid = valid.when(options.parentInputName, {
                is: (val) => {
                  return options?.validation.is
                    ? val?.value === options?.validation.is
                    : options?.validation.ne
                      ? options?.validation.ne !== val?.value
                      : false;
                },
                then: yup.mixed().nullable().required(errorsText.blankError()),
                otherwise: yup.mixed(),
              });
            } else if (options.required) valid = valid.required(errorsText.blankError());
            if (section.isCompanions)
              resetValue = input?.client_inputs
                // ?.sort((a, b) => parseFloat(a.companion_number) - parseFloat(b.companion_number))
                ?.map((x) => x.value);
            else resetValue = input?.client_inputs?.[0]?.value;
            break;

          case formTypesObject.NUMBER.value:
            // console.log('* * * onSuccess - NUMBER', { options, input }, options.required);

            valid = yup.number().nullable().typeError(errorsText.blankError());
            //  .typeError(errorsText.blankError())
            if (options.min >= 0) valid = valid.min(options.min, errorsText.min(options.min));
            if (options.max >= 0) valid = valid.max(options.max, errorsText.max(options.max));

            if (options?.validation && options?.parentInputName) {
              valid = valid.when(options.parentInputName, {
                is: (val) => {
                  return options?.validation.is
                    ? val?.value === options?.validation.is
                    : options?.validation.ne
                      ? options?.validation.ne !== val?.value
                      : false;
                },
                then: yup.number().nullable().required(errorsText.blankError()),
                otherwise: yup.number(),
              });
            } else if (options.required) valid = valid.required(errorsText.blankError());
            else valid.nullable();
            if (section.isCompanions)
              resetValue = input?.client_inputs
                // ?.sort((a, b) => parseFloat(a.companion_number) - parseFloat(b.companion_number))
                ?.map((x) => x.value);
            else resetValue = input?.client_inputs?.[0]?.value;
            options.inputProps = {
              type: 'number',
              disableComma: true,
              min: options.min || 0,
              // defaultValue: 0,
            };
            options.min = options.min || 0;
            // if (!resetValue) resetValue = 0;
            break;

          case formTypesObject.SOCIAL_MEDIA.value:
            // console.log("* * * onSuccess - SOCIAL_MEDIA",{ options },options.required);
            valid = yup.string();
            if (options?.validation && options?.parentInputName) {
              valid = valid.when(options.parentInputName, {
                is: (val) => {
                  return options?.validation.is
                    ? val?.value === options?.validation.is
                    : options?.validation.ne
                      ? options?.validation.ne !== val?.value
                      : false;
                },
                then: yup.string().nullable().required(errorsText.blankError()),
                otherwise: yup.string(),
              });
            } else if (options.required) valid = valid.required(errorsText.blankError());
            break;

          case formTypesObject.LOCATION.value:
            // console.log("* * * onSuccess - LOCATION",{ options },options.required);
            valid = yup.mixed();
            if (options?.validation && options?.parentInputName) {
              valid = valid.when(options.parentInputName, {
                is: (val) => {
                  return options?.validation.is
                    ? val?.value === options?.validation.is
                    : options?.validation.ne
                      ? options?.validation.ne !== val?.value
                      : false;
                },
                then: yup.mixed().nullable().required(errorsText.blankError()),
                otherwise: yup.mixed(),
              });
            } else if (options.required) valid = valid.required(errorsText.blankError());
            // if(options?.isAddress){ resetValue=input?.client_inputs?.[0]?.value;}
            // else  resetValue=null
            //  resetValue=input?.client_inputs?.[0]?.value;
            //  if(options?.isProvince){}
            //  if(options?.isCity){}
            if (options?.isAddress) resetValue = input?.client_inputs?.[0]?.value;
            break;

          case formTypesObject.code_melli.value:
            // console.log("* * * onSuccess - code_melli",{ options },options.required);
            valid = yup.string().matches(nationalCodeRegex, errorsText.invalidValue(input.label));
            if (options?.validation && options?.parentInputName) {
              valid = valid.when(options.parentInputName, {
                is: (val) => {
                  return options?.validation.is
                    ? val?.value === options?.validation.is
                    : options?.validation.ne
                      ? options?.validation.ne !== val?.value
                      : false;
                },
                then: yup.string().nullable().required(errorsText.blankError()),
                otherwise: yup.string(),
              });
            } else if (options.required) valid = valid.required(errorsText.blankError());
            if (section.isCompanions)
              resetValue = input?.client_inputs
                // ?.sort((a, b) => parseFloat(a.companion_number) - parseFloat(b.companion_number))
                ?.map((x) => x.value);
            else resetValue = input?.client_inputs?.[0]?.value;
            options.inputProps = {
              ...options?.inputProps,
              type: 'number',
              disableComma: true,
              withZero: true,
            };
            break;

          case formTypesObject.phone.value:
            // console.log("* * * onSuccess - phone",{ options },options.required);
            valid = yup.string().matches(phoneRegex, errorsText.invalidValue(input.label));
            if (options?.validation && options?.parentInputName) {
              valid = valid.when(options.parentInputName, {
                is: (val) => {
                  return options?.validation.is
                    ? val?.value === options?.validation.is
                    : options?.validation.ne
                      ? options?.validation.ne !== val?.value
                      : false;
                },
                then: yup.string().nullable().required(errorsText.blankError()),
                otherwise: yup.string(),
              });
            } else if (options.required) valid = valid.required(errorsText.blankError());
            if (section.isCompanions)
              resetValue = input?.client_inputs
                // ?.sort((a, b) => parseFloat(a.companion_number) - parseFloat(b.companion_number))
                ?.map((x) => x.value);
            else resetValue = input?.client_inputs?.[0]?.value;

            options.inputProps = {
              ...options?.inputProps,
              type: 'number',
              disableComma: true,
              isPhone: true,
              withZero: true,
            };
            break;

          default:
            // console.log("* * * onSuccess - ANY",{ options },options.required);
            valid = yup.mixed();
            if (options?.validation && options?.parentInputName) {
              valid = valid.when(options.parentInputName, {
                is: (val) => {
                  return options?.validation.is
                    ? val?.value === options?.validation.is
                    : options?.validation.ne
                      ? options?.validation.ne !== val?.value
                      : false;
                },
                then: yup.mixed().nullable().required(errorsText.blankError()),
                otherwise: yup.mixed(),
              });
            } else if (options.required) valid = valid.required(errorsText.blankError());
            if (section.isCompanions)
              resetValue = input?.client_inputs
                // ?.sort((a, b) => parseFloat(a.companion_number) - parseFloat(b.companion_number))
                ?.map((x) => x.value);
            else resetValue = input?.client_inputs?.[0]?.value;
            break;
        }
        // if(section.isCompanions){
        //   validationsObject[section.id]
        // }
        // else
        validationsObject[section.id][`${Enum.bseName + input.id}`] = valid;
        if (!section.isCompanions) {
          resetData[section.id] = resetData[section.id] || {};
          resetData[section.id][`${Enum.bseName + input.id}`] = resetValue;
        } else {
          resetData[section.id] = resetData[section.id] || [];
          // console.log({ companionInfo, resetValue }, companionInfo.count);
          for (let k = 0; k < companionInfo.count; k += 1) {
            if (resetValue) {
              const currentValue = resetValue?.[k];
              resetData[section.id][k] = resetData[section.id][k] || {};
              resetData[section.id][k][`${Enum.bseName + input.id}`] = currentValue;
            }
            // const element = array[k];
          }
        }
        if (resetValue) {
          // console.log({resetValue});
          // isBeforeRegistrant=true
        }
      }
    }
    if (resetData[companionInfo?.section])
      resetData[companionInfo.section] = resetData[companionInfo.section]?.map((x) => ({ ...x, id: uuidv4() }));

    // const haveReset= Object.values(resetData||{})?.length
    // const resetValues= Object.values(resetData||{})
    console.log('* * * onSuccess', {
      convertorTeam: CaravanSelector.convertor.object(data?.team),
      resetData,
      validationsObject,
      companionInfo,
      isBeforeRegistrant,
    });
    if (isBeforeRegistrant) reset({ ...resetData, team: CaravanSelector.convertor.object(data?.team) });
    else reset({ [companionInfo.id]: 0 });
    setBeforeRegistrant(isBeforeRegistrant);
    setCaravanOptions(caravanInfo);
    // const handly={
    //   1:yup.object().shape({2:yup.string().required(errorsText.blankError())})
    // }
    const finalValidation = {};
    if (isAttabat) finalValidation.team = yup.mixed().required(errorsText.blankError());

    Object.keys(validationsObject || {})?.forEach((key) => {
      // console.log('* * * onSuccess', { key, value: validationsObject[key] }, +key === +companionInfo.section);
      if (+key === +companionInfo.section)
        finalValidation[key] = yup.array().of(yup.object(validationsObject[key]).defined());
      else finalValidation[key] = yup.object().shape(validationsObject[key]);
    });
    if (data?.form?.name === "اطلاعات نظام وظیفه") {
      const firstFormUserData = firstFormData?.data?.clients[0];
      const userMaritalStatus = firstFormUserData?.gender;
      filterCompanionClientArr = companionClientArr?.filter(item => !item?.hideThisCompanion);


      if (userMaritalStatus === "مونث" && filterCompanionClientArr?.length === 0) {
        setHideForWemen(true)
      } else if (userMaritalStatus === "مونث" && filterCompanionClientArr?.length > 0) {

        setValidationState({});
      }else if(userMaritalStatus === "مذکر" && filterCompanionClientArr?.length > 0){
        setValidationState(finalValidation);
      } 
      else {
        setValidationState(finalValidation);
      }
    } else {

      setValidationState(finalValidation);
    }
    // setValidationState(finalValidation);
    // console.log('* * * onSuccess', {
    //   formData,
    //   validationsObject,
    //   finalValidation,
    //   obj: Object.keys(validationsObject || {}),
    // });
    if (data?.form?.name?.trim() === "بارگزاری مدارک") {
      if (members === 0) {
        const index = formData.sections.findIndex(item => item?.label === "مدارک هویتی همراهان");
        if (index > -1) {
          formData.sections[index].hideThisSection = true
        }
      }
    }
    setTravelApi(formData);
  };

  const getById = () => axiosInstanceV2.get(`${api.travelRegister.register.base}/${queryParams?.["*"]}`);

  const firstFormOfTravel = useQueryCustom({
    url: getById,
    name: `${api.public.travelRegister.base}_getById_re`,
    params: { id: data?.form?.id },
    // invalidQuery: `${Enum?.api?.base}_get_${id}_${travel}`,
    onSuccess: onSuccess,
    staleTime: 0, // Data becomes stale immediately
    cacheTime: 0,
    enabled: !!data?.form?.name
    //  onError: onErrorMutating,
  });

  useEffect(() => {
    if (!data) return;
    onSuccess();
    firstFormOfTravel.refetch();
  }, [data]);

  // const resQuery = useQueryCustom({
  //   name: `${Enum?.api?.base}_get_${id}_${travel}`,
  //   url: getById,
  //   params: { id },
  //   onSuccess: onSuccess,
  // });
  // useEffect(()=>{
  //   handleSuccessModal()
  // },[ModalLayoutSuccessRef])

  // const sectionsQuery = useQueryCustom({
  //   name: `${api.section.base}_get_${id}`,
  //   url: getting,
  // });

  // console.log({ resQuery, travel, TravelApi });

  // if (!TravelApi)
  // return <>{'sas'}</>;
  // return null
  return (
    <Box
      sx={
        {
          // py:3
        }
      }
    >
      {
        <Page
          sx={{
            py: 3,
          }}
          title={data?.form?.name}
        >
          <ModalLayout ref={ModalLayoutQuestionInputRef}>
            <QuestionComponent
              {...{
                title: beforeRegistrant ? 'آیا از تغییر اطلاعات مطمئن هستید؟' : 'آیا از تایید ثبت نام مطمئن هستید؟',
                description: beforeRegistrant
                  ? 'پس از تایید اطلاعات جدید زائر در لیست قرار خواهد گرفت . آیا ادامه میدهید؟'
                  : 'پس از تایید اطلاعات زائر در لیست قرار خواهد گرفت . آیا ادامه میدهید؟',
                button: {
                  confirm: {
                    label: 'question.yesProcess',
                    onClick: (_, data) => onSubmit(data),
                  },
                  reject: {
                    label: 'question.no',
                    onClick: () => handleQuestionModal(),
                  },
                },
              }}
            // onClose={() => handleQuestionModal()}
            />
          </ModalLayout>

          <ModalLayout ref={ModalLayoutSuccessRef} onClosed={refetch}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                border: '1px solid #ccc',
                borderRadius: 1,
                backgroundColor: 'common.white',
              }}
            >
              {/* <Box sx={{
                            "svg":{
                                width:"80px",
                                height:"80px",
                                color:"success.main"
                            }
                        }}>
                            <CheckCircle />
                        </Box> */}
              <Box
                sx={{
                  mt: '-20px',
                  svg: {
                    width: '65px',
                    height: '65px',
                    color: 'success.main',
                    backgroundColor: 'common.white',
                    borderRadius: '100%',
                  },
                }}
              >
                <CheckCircle />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  p: 3,
                  gap: 2,
                  position: 'relative',
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    color: 'success.main',
                  }}
                >
                  {beforeRegistrant ? 'تغییر اطلاعات شما با موفقیت انجام شد.' : 'ثبت نام شما با موفقیت انجام شد.'}
                </Typography>

                {/* {true ? <Typography variant="h6">{'منتظر قرعه کشی باشید.'}</Typography> : ''} */}

                <Button
                  color="success"
                  variant="contained"
                  onClick={() => {
                    handleSuccessModal();
                    navigate(PATH_DASHBOARD.visitorManagement.root("omre" ,SearchParams.get("travel"))+"/registrations")
                  }}
                >
                  {'بستن'}
                </Button>
              </Box>
            </Box>
          </ModalLayout>

          <Container maxWidth={themeStretch ? false : 'lg'}>
            {!isHistory ? (
              <HeaderBreadcrumbs heading={data?.form?.name}>
                <Typography>{data?.form?.travel?.name}</Typography>
              </HeaderBreadcrumbs>
            ) : (
              ''
            )}

              {(HideForWemen) ?
                <>
                  <Typography>کاربر نیازی به تکمیل این فرم ندارد.</Typography>
                </>
                :
                <>
                  <FormProvider methods={methods} onSubmit={handleSubmit(handleQuestionModal)}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                      }}
                    >
                      {TravelApi?.sections?.map((x) => {
                        return (
                          !x?.hideThisSection &&
                          <SectionPublic
                            key={x.id}
                            data={x}
                            loading={isLoading}
                            caravanOptions={caravanOptions}
                            isHistory={isHistory}
                            isFirst={isFirst}
                            members={members}
                            refetch={refetch}
                            formName={data?.form?.name}
                            CampnionArrState={CampnionArrState}
                            UserCanSeeInputs={UserCanSeeInputs}
                          // disabled={beforeRegistrant}
                          />
                        );
                      })}

                      {isAttabat ? <CaravanInput {...{ caravanOptions, travelId: data?.form?.travel?.id }} /> : ''}
                    </Box>

                    {
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          mt: 5,
                        }}
                      >
                        <LoadingButton loading={isLoading} type="submit" variant="contained" color={'success'}>
                          <Box
                            sx={{
                              display: 'flex',
                              gap: 2,
                              alignItems: 'center',
                            }}
                          >
                            <Typography>{beforeRegistrant ? 'ویرایش اطلاعات' : 'ثبت اطلاعات'}</Typography>
                            <ControlPoint />
                          </Box>
                        </LoadingButton>
                      </Box>
                    }
                    {isError ? (
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'center',
                          mt: 1,
                        }}
                      >
                        <FormHelperText error>{`لطفا تمام فیلد ها را با دقت پر نمایید`}</FormHelperText>
                      </Box>
                    ) : (
                      ''
                    )}
                  </FormProvider>
                </>
              }

          </Container>
        </Page>
      }
    </Box>
  );
};

export default RegistrantForm;
