import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Button, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { haveHasType, caravanType } from '../../../enumeration';
import { fDate, fDateJalali, fDateTime } from '../../../utils/formatTime';
import Enum from '../enum';
import TableMoreMenu from '../../../components/table/TableMoreMenu';
import Iconify from '../../../components/Iconify';
import MoreTable from '../../../components/table/MoreTable';
import MoreTableItem from '../../../components/table/MoreTable/MoreTableItem';
import { hasAccess } from 'src/permission/utiles';
import buildAccess from 'src/permission/buildAccess';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { Download } from '@mui/icons-material';

export const tableData = ({ baseRoute, onDelete, disabled }) => [
  {
    key: 'key',
    header: 'متغیر',
    Component: ({ param }) => param?.key || '-',
  },
  {
    key: 'translate',
    header: 'نام فارسی',
    Component: ({ param }) => param?.translate || '-',
  },
  {
    key: 'value',
    header: 'مقدار',
    Component: ({ param }) => param?.value || '-',
  },
  {
    key: 'edit',
    header: '',
    Component: ({ param }) => (
      <>
        <Link to={`${baseRoute}${Enum.routes.edit}/${param.id}`}>
          <KeyboardBackspaceIcon
            sx={{
              cursor: 'pointer',
            }}
          />
        </Link>
      </>
    ),
    free: true,
  },
];
