// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------
// process.env.REACT_APP_HOST_API_KEY || 'https://api.labbayk.ir';
// process.env.REACT_APP_HOST_API_KEY || 'https://api.paneltest.shop';
export const HOST_API = 'https://api.paneltest.shop'
export const HOST_API_STORAGE = 'https://api.paneltest.shop'
export const PUBLIC_DOMAIN_ADDRESS = 'https://client.paneltest.shop'

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 320,
  DASHBOARD_COLLAPSE_WIDTH: 70,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

export const GENDER_DEFAULT_OP = [
  { label: "مذکر", value: "مذکر" },
  { label: "مونث", value: "مونث" },
]

export const TASHAROF_DEFAULT_OP = [
  { label: "دانشجویان پسر مجرد", value: "دانشجویان پسر مجرد" },
  { label: "دانشجویان دختر مجرد", value: "دانشجویان دختر مجرد" },
  { label: "دانشجویان متاهل", value: "دانشجویان متاهل" },
  // { label: "کارمند", value: "کارمند" },
  { label: "استاد دانشگاه", value: "استاد دانشگاه" },
]

export const PASSPORT_DEFAULT_OP = [
  { label: "دارای گذرنامه با اعتبار بیشتر از 6 ماه", value: "دارای گذرنامه با اعتبار بیشتر از 6 ماه" },
  // { label: "دارای گ  ذرنامه با اعتبار کمتر از 6 ماه", value: "دارای گذرنامه با اعتبار کمتر از 6 ماه" },
  { label: "فاقد گذرنامه", value: "فاقد گذرنامه" },
]

export const DUTY_DEFAULT_OP = [
  { label: "پایان خدمت یا معافیت دائم", value: "پایان خدمت یا معافیت دائم" },
  { label: "معافیت تحصیلی", value: "معافیت تحصیلی" },
  { label: "دانشجوی نظامی", value: "دانشجوی نظامی" },
  { label: "تعهد خدمتی", value: "تعهد خدمتی" },
  { label: "زیر ۱۸ سال", value: "زیر ۱۸ سال" },
]

export const CLIENT_TYPE_OP = [
  { label: "عادی", value: "عادی" },
  { label: "اعزام خاص", value: "اعزام خاص" },
  { label: "نخبگان", value: "نخبگان" },
  { label: "تشکل ها", value: "تشکل ها" },
  { label: "روسای دانشگاه", value: "روسای دانشگاه" },
  { label: "خارج کشور", value: "خارج کشور" },
  { label: "هم اندیشی اساتید", value: "هم اندیشی اساتید" },
  { label: "بسیج اساتید", value: "بسیج اساتید" },
  { label: "جامانده عمره94", value: "جامانده عمره94" },
  { label: "تکمیل ظرفیت", value: "تکمیل ظرفیت" },
  { label: "قرعه کشی سالن", value: "قرعه کشی سالن" },
]

export const TRAVEL_STATUS = {
  0 : {label : "ثبت نام بسته" , value : 0},
  1 : {label : "ثبت نام باز" , value : 1},
  2 : {label : "مشاهده نتایج" , value : 2},
  3 : {label : "لاگین ذخیره و اصلی" , value : 3},
  4 : {label : "لاگین فقط اصلی" , value : 4},
}

export const PASSPORT_SENDING_STATUS = {
  0 : {label : "تحویل نشده" , value : 0 , color: 'default'},
  1 : {label : "تحویل شده" , value : 1 , color: 'success'},
}

export const PASSPORT_SENDING_STATUS_FILTER = {
  0 : {label : "تحویل نشده" , value : "تحویل نشده" , color: 'default'},
  1 : {label : "تحویل شده" , value : "تحویل شده" , color: 'success'},
}

export const PHONE_REGEX = /^(۰۹|09)[0-9\u06F0-\u06F9]{9}$/;
export const NATIONAL_CODE_REGEX = /^[\u06F0-\u06F90-9]{10}$/;
export const EMAIL_REGEX = /^(?=.{1,256})(?=.{1,64}@.{1,255})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const PERSIAN_NUMBER_REGEX = /^[0-9\u06F0-\u06F9]+$/;
export const PERSIAN_CHAR_REGEX = /^[\u0600-\u06FF\s]+$/;
export const PERSIAN_CHAR_REGEX_WITH_NUM = /^[\u0600-\u06FF\s0-9]+$/;

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'rtl',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: false,
};
