import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, InputAdornment, Modal, Stack, TextField, Typography } from '@mui/material';
import { Link as RouterLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axiosInstance from '../../../utils/axios';
import { useMutationCustom, useQueryCustom } from '../../../utils/reactQueryHooks';
import useQueryString from '../../../utils/useQueryString';
import Enum from '../enum';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Iconify from '../../../components/Iconify';
import TableComponent from '../../../components/table/TableComponent';
import { tableData } from './tableInfo';
import useTable from '../../../hooks/useTable';
import { hasAccess } from '../../../permission/utiles';
import api from 'src/services/api';
import WaitingBox from 'src/components/WaitingBox';
import { travelTypeObject } from 'src/enumeration';
import agentSelector from '../../agent/selector';
import { FormProvider } from 'src/components/hook-form';
import { useForm } from 'react-hook-form';
import useDebounce from 'src/hooks/useDebounce';
import persianToEnglishNumber from 'src/utils/persianToEnglishNumber';


const List = ({ access }) => {
  const { t } = useTranslation();
  const queryParams = useParams();
  const queryString = useQueryString();
  const [SearchParams] = useSearchParams();



  const methods = useForm({
    defaultValues: {
      "admin_id": null,
    }
  });

  const {
    reset,
    watch,
    control,
    setValue,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;


  // ---------------------------------------------------------------------
  const getApi = async () =>{
    const stateFilters = CustomFilterObj?.filters

    const newFilters = {};
    for (const key in stateFilters) {
      if (Object.prototype.hasOwnProperty.call(stateFilters, key)) {
        const element = stateFilters[key];
        newFilters[key] = element?.value
      }
    }

    console.log('* * * getting : ', { newFilters, CustomFilterObj, stateFilters });

    return axiosInstance.get(`${Enum?.api?.base}`, {
      params: {
        ...queryString,
        ...(newFilters && (newFilters)),
      },
    });

    // axiosInstance.get(`${Enum.api.base}`, { params: { ...queryString } });
  }

  const deleteApi = async ({ id }) => axiosInstance.delete(`${Enum?.api?.base}/${id}`);
  // ---------------------------------------------------------------------

  const { themeStretch } = useSettings();

  const tableSetting = useTable({ queryString });
  console.log({ tableSetting, queryParams });
  // ------------------------------------------------------- query Get
  const { data: { data = {} } = {}, isLoading , refetch } = useQueryCustom({
    name: `${Enum?.api?.base}_list`,
    url: getApi,
    params: { ...queryString },
  });
  // ------------------------------------------------------- query delete

  const deleteInput = useMutationCustom({
    url: deleteApi,
    name: `${Enum?.api?.base}_delete`,
    invalidQueries: [`${Enum?.api?.base}_list`],
    onSuccess: () => {
      toast.success(t('question.delete.successfully'));
    },
    onError: (error) => {
      console.log({ error });
      const errorTitle = error.response.data.message || t('errorTryAgain');

      const errors = Object.values(error?.response?.data?.errors || {});
      if (errors?.length) {
        errors?.map((x) => {
          return toast.error(x?.[0]);
        });
      } else toast.error(errorTitle);
    },
  });
  const checkAccess = useMemo(() => {
    return {
      // update: hasAccess(access?.update),
      delete: hasAccess(access?.delete),
      create: hasAccess(access?.create),
      confirm: hasAccess(access?.confirm),
    };
  }, [access]);

  const [CustomFilterObj, setCustomFilterObj] = useState({})

  const { debounce } = useDebounce({
    setDebounce: (x) => {
      // setCustomFilterObj({ filters: reqData });
      if (x !== "") {
        setCustomFilterObj(prev => ({
          ...prev,
          filters: {
            ...prev?.filters,
            code_melli: {
              label: "کد ملی",
              value: persianToEnglishNumber(x),
              name: "codeMeli",
              filterName: "code_melli",
              hidden: true
            }
          }
        }));
      } else {
        setCustomFilterObj({ filters: null })
      }
    }
  });

  const customRefetch = (customParams = {}) => {
    console.log({ customParams: customParams?.filters })
    // setCustomFilterObj(customParams?.filters)
    return refetch();
  };
  useEffect(() => {
    customRefetch();
  }, [CustomFilterObj])

  const title = `${Enum.title.name[1]} `;
  const tableOptions = tableData({
    baseRoute: `${Enum.routes.root}`,
    onDelete: (x) => deleteInput.mutate({ id: x?.id }),
    disabled: {
      update: !checkAccess?.update,
      delete: !checkAccess?.delete,
    },
  });

  console.log({ tableOptions, data });

  return (
    <Page title={`${title}`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={title}
          links={[
            { name: 'داشبورد', href: Enum.routes.root },
            { name: title, href: Enum.routes.list },
            { name: 'لیست' },
          ]}
          action={
            checkAccess?.create ? (
              <>
                {/* <Button
                  variant="contained"
                  color={'success'}
                  component={RouterLink}
                  to={`${Enum.routes.root}${Enum.routes.add}`}
                  startIcon={<Iconify icon={'eva:plus-fill'} />}
                >
                  {t('addAuthor', { author: Enum.title.name[0] })}
                </Button> */}
              </>
            ) : (
              ''
            )
          }
        />
        {/* {isLoading ? <WaitingBox /> :
          <>
            
          </>
        } */}
        <TableComponent
          active={{
            // selectable: true,
            rowNumber: true,
          }}
          hiddenSearchText
          topOfHeaderFilter={
            <>

              <FormProvider methods={methods}>

                <Stack
                  direction={"row"}
                  gap={1}
                  sx={{
                    // width: 600
                  }}>
                  <TextField
                    placeholder="جستجوی کد ملی ..."
                    onChange={(e) => debounce(e.target.value)}
                    // defaultValue={search}
                    InputProps={{
                      // className: ' !rounded-l-[50px] !border-gray-50',
                      sx: {
                        borderRadius: '8px',
                        p:1
                      },
                      inputProps: {
                        // className: ' p-[10px]',
                        sx: {
                          padding: '9px',
                        },
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                      ),
                    }}
                  // sx={{mr: 1, fontWeight: 'fontWeightBold'}}
                  />
                  <agentSelector.Element
                    name={"admin_id"}
                    geById={SearchParams.get("admin_id") && SearchParams.get("admin_id")}
                    onChange={(value) => tableSetting.setFilter("admin_id", value?.value)}
                    label={'لیست عوامل'}
                    sx={{
                      width: 400,
                      // '& .MuiInputLabel-root ': {
                      //   "line-height": "1.0375em"
                      // },
                      // '& .MuiOutlinedInput-root': {
                      //   paddingTop: "4px",
                      //   paddingBottom: "4px",
                      // },
                    }}
                    disableClearable={false}
                  />

                </Stack>

              </FormProvider>
            </>
          }
          options={tableOptions}
          data={data?.data}
          tableSetting={tableSetting}
          pagination={{
            totalPage: data?.meta?.last_page,
            total: data?.meta?.total,
          }}
          loading={isLoading}
        />{' '}
      </Container>
    </Page>
  );
};

export default List;
