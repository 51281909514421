import { Box, Grid, Stack, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import addDataInObjectDepth from 'src/utils/addDataInObjectDepth';

import RHFTextField from '../../../../components/hook-form/RHFTextField';
import FormProvider from '../../../../components/hook-form/FormProvider';

import validation from './validation';
// import RHFSelectStatic from '../../../components/hook-form/RHFSelectStatic';
import RHFSelector from '../../../../components/hook-form/RHFSelector';
import RHFDatePicker from '../../../../components/hook-form/RHFDatePicker';
import Enum from '../../../profiles/enum';
import { useMutationCustom, useQueryCustom } from '../../../../utils/reactQueryHooks';
import axiosInstance from '../../../../utils/axios';
import { fDateForApi } from '../../../../utils/formatTime';
import { statusType } from '../../../../enumeration/index';
import persianToEnglishNumber from '../../../../utils/persianToEnglishNumber';
import { RHFCheckbox } from '../../../../components/hook-form/RHFCheckbox';
import DashboardPermissions from './Dashboard';
import TravelsPermissions from './Travels';
import RemoveNullObjectValue from '../../../../utils/RemoveNullObjectValue';
import api from '../../../../services/api';
import SubmitComponent from './SubmitComponent';
import BasePermissions from './Base';

const AddPermissions = () => {
  // const [disabled, setDisabled] = useState(false);

  const { t } = useTranslation();

  // const { control, reset, watch, getValues } = useFormContext();

  const methods = useForm({
    resolver: yupResolver(validation.schema()),
  });

  // const {
  //   reset,
  //   watch,
  //   control,
  //   setValue,
  //   setError,
  //   handleSubmit,
  //   getValues,
  //   formState: { errors, isSubmitting },
  // } = methods;

  // ----------------------------------------------------------------------------- SERVICE
  // const getting = async () => axiosInstance.get(`${api.role.base}/${role?.id}`);
  const getPermissions = async () => axiosInstance.get(`${api.permission.base}`);

  // ------------------------------------------------------- query Get
  // const roleData = useQueryCustom({
  //   name: `get_${api.role.base}`,
  //   url: getting,
  //   enabled: !!role?.id,
  // });

  // ------------------------------------------------------- query Get All Permission
  const allPermissions = useQueryCustom({
    name: `get_${api.permission.base}`,
    url: getPermissions,
  });

  const permissions = useMemo(() => {
    const param = allPermissions?.data?.data;
    const byType = {};
    for (const key in param) {
      if (Object.hasOwnProperty.call(param, key)) {
        const current = param[key];
        if (current.type) {
          //  addDataInObjectDepth(`${current.type}.${current.model}.${current.action}`, current, byType);
          addDataInObjectDepth(`${current.type}.${current.model}.label`, current.translate, byType);
          addDataInObjectDepth(`${current.type}.${current.model}.object.${current.action}`, current, byType);
        } else {
          // addDataInObjectDepth(`${current.model}.${current.action}`, current, byType);
          addDataInObjectDepth(`${current.model}.label`, current.translate, byType);
          addDataInObjectDepth(`${current.model}.object.${current.action}`, current, byType);
        }
      }
    }
    return byType;
  }, [allPermissions]);
  const { Admin, Client, ...otherPermissions } = permissions || {};

  // const onSuccessData = (param) => {
  //   console.log('* * * onSuccessData :', { param });
  //   // param.data
  //   const resetData = {};
  //   param.data?.permission?.forEach((x) => (resetData[x.id] = true));
  //   console.log('* * * onSuccessData :', { resetData });

  //   reset(resetData);
  // };
  // const roleData = useQueryCustom({
  //   name: `${Enum?.api?.base}_get_${queryParams.id}`,
  //   url: getting,
  //   enabled: !!user?.role?.id,
  //   onSuccess: onSuccessData,
  // });
  // ------------------------------------------------------------------------------ Mutation

  // ---------------------------------------

  // const watchStartDate = watch(validation.fieldNames.startDate);
  // console.log({ watchStartDate });
  // const onSubmit = async (e) => {
  //   e.preventDefault();
  //   const values = getValues();
  //   const reqData = RemoveNullObjectValue(values);
  //   const finalData = {
  //     title: role?.title,
  //     permissions: Object.keys(reqData),
  //   };
  //   console.log('* * * onSubmit : ', { values, reqData, finalData });
  //   // const formData = new FormData();
  //   // formData.append(`title`, roleData?.data?.title);
  //   // Object.keys(reqData || {})?.map((x) => {
  //   //   formData.append(`permissions[]`, x);
  //   // });
  //   // mutate(finalData);
  // };

  // useEffect(() => {
  //   if (roleData) onSuccessData(roleData);
  // }, [roleData]);

  console.log({ otherPermissions });

  const disabled = false;
  const loading = false;

  return (
    <Box>
      <Box
        sx={{
          gap: 3,
        }}
      >
        <DashboardPermissions basename={'permissions'} admin={Admin}  {...{ disabled, loading }} />
        <DashboardPermissions basename={'permissions'} client={Client}  {...{ disabled, loading }} />
        {otherPermissions?.Identity ? (
          <BasePermissions
            basename={'permissions'}
            data={otherPermissions.Identity}
            label={'مدارک ارسال شده'}
            {...{ disabled, loading }}
            showHeader={false}
          />
        ) : (
          ''
        )}
        {otherPermissions?.Status ? (
          <BasePermissions
            basename={'permissions'}
            data={otherPermissions.Status}
            label={'وضعیت کاربر'}
            {...{ disabled, loading }}
            showHeader={false}
          />
        ) : (
          ''
        )}
        {otherPermissions?.[0] ? (
          <TravelsPermissions
            basename={'permissions'}
            data={otherPermissions[0]}
            label={'حج عمره'}
            {...{ disabled, loading }}
          />
        ) : (
          ''
        )}
        {otherPermissions?.[1] ? (
          <TravelsPermissions
            basename={'permissions'}
            data={otherPermissions[1]}
            label={'حج تمتع'}
            {...{ disabled, loading }}
          />
        ) : (
          ''
        )}
        {otherPermissions?.[2] ? (
          <TravelsPermissions
            basename={'permissions'}
            data={otherPermissions[2]}
            label={'عتبات عالیات'}
            {...{ disabled, loading }}
          />
        ) : (
          ''
        )}
        {otherPermissions?.[3] ? (
          <TravelsPermissions
            basename={'permissions'}
            data={otherPermissions[3]}
            label={'سفر های زیارتی دیگر'}
            {...{ disabled, loading }}
          />
        ) : (
          ''
        )}
        {otherPermissions?.University ? (
          <BasePermissions
            basename={'permissions'}
            data={otherPermissions.University}
            label={'دانشگاه ها'}
            {...{ disabled, loading }}
          />
        ) : (
          ''
        )}
        {otherPermissions?.Message ? (
          <BasePermissions
            basename={'permissions'}
            data={otherPermissions.Message}
            label={'پیام ها'}
            {...{ disabled, loading }}
          />
        ) : (
          ''
        )}
        {otherPermissions?.Sms ? (
          <BasePermissions
            basename={'permissions'}
            data={otherPermissions.Sms}
            label={'پیامک ها'}
            {...{ disabled, loading }}
          />
        ) : (
          ''
        )}
        {/* {otherPermissions?.Form ? (
          <BasePermissions
            basename={'permissions'}
            data={otherPermissions.Form}
            label={'فرم ها'}
            {...{ disabled, loading }}
          />
        ) : (
          ''
        )} */}
        {otherPermissions?.Chart ? (
          <BasePermissions
            basename={'permissions'}
            data={otherPermissions.Chart}
            label={'گزارش گیری'}
            {...{ disabled, loading }}
          />
        ) : (
          ''
        )}
        {otherPermissions?.Lottery ? (
          <BasePermissions
            basename={'permissions'}
            data={otherPermissions.Lottery}
            label={'قرعه کشی'}
            {...{ disabled, loading }}
          />
        ) : (
          ''
        )}
        {otherPermissions?.Setting ? (
          <BasePermissions
            basename={'permissions'}
            data={otherPermissions.Setting}
            label={'تنظیمات'}
            {...{ disabled, loading }}
          />
        ) : (
          ''
        )}
        {otherPermissions?.Log ? (
          <BasePermissions
            basename={'permissions'}
            data={otherPermissions.Log}
            label={'فعالیت ادمین ها'}
            {...{ disabled, loading }}
          />
        ) : (
          ''
        )}

      </Box>

      {/* <SubmitComponent
        isLoading={isLoading}
        onReset={() => onSuccessData(roleData)}
        roleData={roleData}
        allPermissions={allPermissions}
        disabled={disabled}
      /> */}
    </Box>
  );
};

export default AddPermissions;
