import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Chip, MenuItem, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import Enum from '../enum';
import { hasAccess } from 'src/permission/utiles';
import buildAccess from 'src/permission/buildAccess';
import { fDate, fDateTime } from 'src/utils/formatTime';
import { LoadingButton } from '@mui/lab';
import { Download } from '@mui/icons-material';
import { newDate } from 'src/utils/dating';

const statusObj={
  failed : {label : "ناموفق" , color : "error"},
  fail : {label : "ناموفق" , color : "error"},
  error : {label : "ناموفق" , color : "error"},
  done : {label : "موفق" , color : "success"},
  pending : {label : "درحال انجام" , color : "warning"},
}

export const tableData = ({ baseRoute, onDelete, disabled, download, DownloadExcelLoading }) => [
  {
    key: 'name',
    header: 'گزارش ها',
    Component: ({ param }) => `گزارش روز (${param?.file_name?.created_at && fDateTime(param?.file_name?.created_at)})` || '-',
  },
  {
    key: 'status',
    header: 'وضعیت',
    Component: ({ param }) => <Chip label={statusObj?.[param?.file_name?.status]?.label} color={statusObj?.[param?.file_name?.status]?.color} />,
  },
  {
    key: 'show',
    header: '',
    Component: ({ param }) => (
      param?.file_name?.status === "done" && 
      <>
        {/* <Link to={`${baseRoute}${Enum.routes.show}/${param.id}`}> */}
        <Stack justifyContent={"flex-end"} direction={"row"}>
          <a href={param?.download_url} download>

            <LoadingButton
              // onClick={download}
              variant="outlined"
              color="success"
              // loading={DownloadExcelLoading}
              // component={Link}
              // to={baseUrl + Enum.routes.lottery}
              endIcon={<Download />}
            >
              {'دریافت اکسل'}
            </LoadingButton>
          </a>
        </Stack>
        {/* </Link> */}
      </>
    ),
    free: true,
  },
];
