import * as yup from 'yup';

const validation = {
  fieldNames: {
    status: 'status',
    bankStatus: 'bank_status',
    dutyStatus: 'duty_status',
    identityStatus: 'identity_status',
    sabt_nahayi_status: 'sabt_nahayi_status',
    carevan: 'carevan',
    type: 'type',
    code: 'code',
    description: 'description',
    passport_sending_status: 'passport_sending_status',
  },
  defaultValues:{
    code:"",
    description:""
  },
  schema: () =>
    yup.object().shape({
      [validation.fieldNames.status]: yup.mixed().nullable(),
      [validation.fieldNames.sabt_nahayi_status]: yup.mixed().nullable(),
      [validation.fieldNames.bankStatus]: yup.mixed().nullable(),
      [validation.fieldNames.dutyStatus]: yup.mixed().nullable(),
      [validation.fieldNames.identityStatus]: yup.mixed().nullable(),
      [validation.fieldNames.carevan]: yup.mixed().nullable(),
      [validation.fieldNames.type]: yup.mixed().nullable(),
      [validation.fieldNames.passport_sending_status]: yup.mixed().nullable(),
      [validation.fieldNames.code]: yup.string().nullable(),
      [validation.fieldNames.description]: yup.string().nullable(),
    }),
};

export default validation;
