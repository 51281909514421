/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import { EMAIL_REGEX, NATIONAL_CODE_REGEX, PERSIAN_CHAR_REGEX, PERSIAN_NUMBER_REGEX } from 'src/config';
import errorsText from 'src/utils/errorsText';
import * as Yup from 'yup';

const companionSchema = Yup.object().shape({
    /*name: Yup.string().matches(PERSIAN_CHAR_REGEX,errorsText.persianChar).required(errorsText.blankError()),
    father_name: Yup.string().matches(PERSIAN_CHAR_REGEX,errorsText.persianChar).required(errorsText.blankError()),
    last_name: Yup.string().matches(PERSIAN_CHAR_REGEX,errorsText.persianChar).required(errorsText.blankError()),
    code_meli: Yup.string().matches(NATIONAL_CODE_REGEX, errorsText.invalidValue("کدملی")).required(errorsText.blankError()),
    // phone: Yup.number().typeError(errorsText.numberFormat("تلفن همراه")).required(errorsText.blankError()),
    phone: Yup.string()
        .matches(PERSIAN_NUMBER_REGEX, errorsText.numberFormat("تلفن همراه")) // Accepts Persian and Arabic numerals
        .max(11, errorsText.maxLength(11, "تلفن همراه"))
        .required(errorsText.blankError()),
    gender: Yup.mixed().required(errorsText.blankError()),
    birthDate: Yup.mixed().required(errorsText.blankError()),
    gozarnameh_status: Yup.object().nullable().required(errorsText.blankError()),
    is_Student: Yup.object().nullable().required(errorsText.blankError()),
    need_vam: Yup.object().nullable().required(errorsText.blankError()),
    student_number: Yup.string().nullable().when("is_Student", {
        is: (value) => value?.value === "بله",
        then: Yup.string().typeError(errorsText.blankError()).matches(PERSIAN_NUMBER_REGEX, errorsText.numberFormat("شماره دانشجویی")).required(errorsText.blankError()),
        otherwise: Yup.string().nullable(),
    }),
    provoince_university: Yup.object().nullable().when("is_Student", {
        is: (value) => value?.value === "بله", // Check if gender is "مذکر"
        then: Yup.object().typeError(errorsText.blankError()).required(errorsText.blankError()), // Make duty_system required if gender is "مذکر"
        otherwise: Yup.object().nullable(), // Allow duty_system to be nullable if gender is not "مذکر"
    }),
    type_university: Yup.object().nullable().when("is_Student", {
        is: (value) => value?.value === "بله", // Check if gender is "مذکر"
        then: Yup.object().typeError(errorsText.blankError()).required(errorsText.blankError()), // Make duty_system required if gender is "مذکر"
        otherwise: Yup.object().nullable(), // Allow duty_system to be nullable if gender is not "مذکر"
    }),
    university_code: Yup.object().nullable().when("is_Student", {
        is: (value) => value?.value === "بله", // Check if gender is "مذکر"
        then: Yup.object().typeError(errorsText.blankError()).required(errorsText.blankError()), // Make duty_system required if gender is "مذکر"
        otherwise: Yup.object().nullable(), // Allow duty_system to be nullable if gender is not "مذکر"
    }),
    kunkor: Yup.object().nullable().when("is_Student", {
        is: (value) => value?.value === "بله", // Check if gender is "مذکر"
        then: Yup.object().typeError(errorsText.blankError()).required(errorsText.blankError()), // Make duty_system required if gender is "مذکر"
        otherwise: Yup.object().nullable(), // Allow duty_system to be nullable if gender is not "مذکر"
    }),
    degree: Yup.object().nullable().when("is_Student", {
        is: (value) => value?.value === "بله", // Check if gender is "مذکر"
        then: Yup.object().typeError(errorsText.blankError()).required(errorsText.blankError()), // Make duty_system required if gender is "مذکر"
        otherwise: Yup.object().nullable(), // Allow duty_system to be nullable if gender is not "مذکر"
    }),*/
});


const FormSchema = Yup.object().shape({
    codeMeli: Yup.string().matches(NATIONAL_CODE_REGEX, errorsText.invalidValue("کدملی")).required(errorsText.blankError()),
    phone: Yup.string()
        .matches(PERSIAN_NUMBER_REGEX, errorsText.numberFormat("تلفن همراه")) // Accepts Persian and Arabic numerals
        .max(11, errorsText.maxLength(11, "تلفن همراه"))
        .required(errorsText.blankError()),
    /*name: Yup.string().matches(PERSIAN_CHAR_REGEX,errorsText.persianChar).required(errorsText.blankError()),
    last_name: Yup.string().matches(PERSIAN_CHAR_REGEX,errorsText.persianChar).required(errorsText.blankError()),
    shenasname: Yup.string()
        .matches(PERSIAN_NUMBER_REGEX, errorsText.numberFormat("شماره شناسنامه")) // Accepts Persian and Arabic numerals
        .required(errorsText.blankError()),
    father_name: Yup.string().matches(PERSIAN_CHAR_REGEX,errorsText.persianChar).required(errorsText.blankError()),
    birthDate: Yup.mixed().required(errorsText.blankError()),
    gender: Yup.mixed().required(errorsText.blankError()),
    email: Yup.string()
    .nullable() // Allow null or empty strings
    .test(
      "email-validation",
      errorsText.invalidValue("پست الکترونیک"),
      (value) => {
        if (!value) return true; // Pass if the value is empty
        return EMAIL_REGEX.test(value); // Validate against EMAIL_REGEX if not empty
      }
    ),
    // phone: Yup.number().typeError(errorsText.numberFormat("تلفن همراه")).required(errorsText.blankError()),
    
    marride_status: Yup.object().nullable().required(errorsText.blankError()),
    tashrof_be_sorat: Yup.object().nullable().required(errorsText.blankError()),
    gozarnameh_status: Yup.object().nullable().required(errorsText.blankError()),
    // duty_system: Yup.object().nullable().required(errorsText.blankError()),
    duty_system: Yup.object().nullable().when("gender", {
        is: (value) => value?.value === "مذکر", // Check if gender is "مذکر"
        then: Yup.object().typeError(errorsText.blankError()).required(errorsText.blankError()), // Make duty_system required if gender is "مذکر"
        otherwise: Yup.object().nullable(), // Allow duty_system to be nullable if gender is not "مذکر"
    }),
    mazhab: Yup.object().nullable().required(errorsText.blankError()),
    need_vam: Yup.object().nullable().required(errorsText.blankError()),
    sabegh_tasharof: Yup.object().nullable().required(errorsText.blankError()),
    home_phone: Yup.string().typeError(errorsText.blankError()).matches(PERSIAN_NUMBER_REGEX, errorsText.numberFormat("تلفن ثابت (به همراه کد)"))
        .max(11, errorsText.maxLength(11, "تلفن ثابت (به همراه کد)")),
    has_hamrah: Yup.mixed(),
    hamrah_count: Yup.mixed(),
    // marrid_date: Yup.mixed().required(errorsText.blankError()),
    marrid_date: Yup.string().nullable().when("tashrof_be_sorat", {
        is: (value) => value?.value === "دانشجویان متاهل", // Check if gender is "مذکر"
        then: Yup.string().typeError(errorsText.blankError()).required(errorsText.blankError()), // Make duty_system required if gender is "مذکر"
        otherwise: Yup.string().nullable(), // Allow duty_system to be nullable if gender is not "مذکر"
    }),
    student_marriage: Yup.mixed(),
    provoince_university: Yup.object().nullable().required(errorsText.blankError()),
    type_university: Yup.object().nullable().required(errorsText.blankError()),
    university_code: Yup.object().nullable().required(errorsText.blankError()),
    kunkor: Yup.object().nullable().required(errorsText.blankError()),
    degree: Yup.object().nullable().required(errorsText.blankError()),
    // type_of_employment: Yup.mixed().nullable(),
    student_number: Yup.string().nullable().when("tashrof_be_sorat", {
        is: (value) => value?.value !== "استاد دانشگاه",
        then: Yup.string().typeError(errorsText.blankError()).matches(PERSIAN_NUMBER_REGEX, errorsText.numberFormat("شماره دانشجویی")).required(errorsText.blankError()),
        otherwise: Yup.string().nullable(),
    }),
    type_of_employment_number: Yup.string().nullable().when("tashrof_be_sorat", {
        is: (value) => value?.value === "استاد دانشگاه",
        then: Yup.string().typeError(errorsText.blankError()).matches(PERSIAN_NUMBER_REGEX, errorsText.numberFormat("شماره حکم کارگزینی")).required(errorsText.blankError()),
        otherwise: Yup.string().nullable(),
    }),
    type_of_employment: Yup.object().nullable().when("tashrof_be_sorat", {
        is: (value) => value?.value === "استاد دانشگاه",
        then: Yup.object().typeError(errorsText.blankError()).required(errorsText.blankError()),
        otherwise: Yup.object().nullable(),
    }),
    type_of_employment_state: Yup.object().nullable().when("tashrof_be_sorat", {
        is: (value) => value?.value === "استاد دانشگاه",
        then: Yup.object().typeError(errorsText.blankError()).required(errorsText.blankError()),
        otherwise: Yup.object().nullable(),
    }),
    // type_of_employment_state: Yup.mixed().nullable(),
    // companions: Yup.array().of(companionSchema)
    //     .when('hamrah_count', {
    //         is: (value) => value?.value > 0,
    //         then: Yup.array().min(1, `اطلاعات همراه خود را وارد کنید`)
    //     })
    // companions: Yup.array()
    //     .of(companionSchema)
    //     .when('hamrah_count', (hamrah_count, schema) => {
    //         const count = hamrah_count?.value || 0;
    //         return schema
    //             .min(count, `لطفاً اطلاعات ${count} همراه را وارد کنید`)
    //             .max(count, `تعداد همراه‌ها نباید بیشتر از ${count} باشد`);
    //     })

    companions: Yup.array()
    .of(companionSchema)
    .when('$context', (context, schema) => {
      const { hamrah_count, has_hamrah, tashrof_be_sorat } = context || {};
      const count  = hamrah_count?.value || 0;
      if (has_hamrah?.value === "بله" && (count  > 0) && tashrof_be_sorat?.value?.search("مجرد") === -1) {
        return schema
          .min(
            count,
            `لطفاً اطلاعات ${count} همراه را وارد کنید`
          )
          .max(
            count,
            `تعداد همراه‌ها نباید بیشتر از ${count} باشد`
          );
      }

    //   return schema.max(-1); // Allow no companions if conditions are not met
    }),*/

});

export default FormSchema

