import { Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import RemoveNullObjectValue, { removeFalseValues } from 'src/utils/RemoveNullObjectValue';
import { useTranslation } from 'react-i18next';
import agentRole from 'src/pages/agentRole/selector';
import RHFTextField from '../../../components/hook-form/RHFTextField';
import FormProvider from '../../../components/hook-form/FormProvider';
import validation from './validation';
import { useMutationCustom, useQueryCustom } from '../../../utils/reactQueryHooks';
import axiosInstance from '../../../utils/axios';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import WaitingBox from '../../../components/WaitingBox/index';
import persianToEnglishNumber from '../../../utils/persianToEnglishNumber';
import AddPermissions from './Permissions';
import ProvinceSelector from 'src/components/selector/Province';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminProfile } from 'src/redux/slices/user';
import { PATH_AFTER_LOGIN } from 'src/config';
import api from 'src/services/api';
import useAuth from 'src/hooks/useAuth';
import { PATH_AUTH } from 'src/routes/paths';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

const AdminProfile = ({ onClose }) => {
  const admin = useSelector((store) => store.admin.data);
  const { user, logout } = useAuth();

  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const queryParams = useParams();
  const paramId = queryParams?.id;

  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(validation.schema(paramId)),
    defaultValues : validation.defaultValue
  });

  const {
    reset,
    watch,
    control,
    setValue,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;

  const handleLogout = async () => {
    try {
      await logout();
      // if (!isPublic)
      navigate(PATH_AUTH.login, { replace: true });

      // if (isMountedRef.current) {
      //   handleClose();
      // }
    } catch (error) {
      console.error(error);
      // enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  // ----------------------------------------------------------------------------- SERVICE
  const updating = (params) => axiosInstance.post(`admin/profile`,params);
  // ------------------------------------------------------------------------------ Mutation
  const onSuccessMutating = () => {
    toast.success(`رمز عبور شما با موفقیت تغییر کرد، لطفا مجدد وارد اکانت  خود شوید`);
   
    handleLogout()
  };
  // const onErrorMutating = (error) => {
  //   console.log('* * * onErrorMutating :', { error });
  //   const errorTitle = error.response.data.message || t('errorTryAgain');
  //   const errors = Object.values(error?.response?.data?.errors || {});
  //   if (errors?.length) {
  //     errors?.map((x) => {
  //       return toast.error(x?.[0]);
  //     });
  //   } else toast.error(errorTitle);
  // };
  const { isLoading, mutate } = useMutationCustom({
    url: updating,
    name: `admin_update`,
    onSuccess: onSuccessMutating,
    // onError: onErrorMutating,
  });

  // ---------------------------------------
  // const onSuccess = (resData) => {
  //   console.log('* * * onSuccess', { resData });
  //   const resetData = {
  //     [validation.fieldNames.name]: resData?.data?.[validation.fieldNames.name] || '',
  //     [validation.fieldNames.last_name]: resData?.data?.[validation.fieldNames.last_name] || '',
  //     [validation.fieldNames.code_melli]: resData?.data?.[validation.fieldNames.code_melli] || '',
  //     [validation.fieldNames.email]: resData?.data?.[validation.fieldNames.email] || '',
  //     [validation.fieldNames.password]: resData?.data?.[validation.fieldNames.password],
  //     [validation.fieldNames.shenasname_number]: resData?.data?.[validation.fieldNames.shenasname_number] || '',
  //     [validation.fieldNames.agent_id]: agentRole.convertor.object(resData?.data?.agent),
  //     [validation.fieldNames.province_id]: ProvinceSelector.convertor.object(resData?.data?.province),
  //     [validation.fieldNames.phone]: resData?.data?.[validation.fieldNames.phone] || '',
  //     [validation.fieldNames.permissions]: {},
  //   };

  //   resData.data?.role?.permission?.forEach((x) => {
  //     resetData[validation.fieldNames.permissions][x.id] = true;
  //   });
  //   console.log('* * * onSuccess', { resetData });
  //   reset(resetData);
  // };
  // const dataById = useQueryCustom({
  //   name: `getById_${Enum?.api?.base}_${paramId}`,
  //   url: getById,
  //   onSuccess: onSuccess,
  //   enabled: !!paramId,
  // });

  const watchStartDate = watch(validation.fieldNames.startDate);
  console.log({ watchStartDate });
  const onSubmit = async () => {
    const values = getValues();
    console.log('* * * onSubmit : ', { values });

    const reqData = {
      [validation.fieldNames.old_password]: persianToEnglishNumber(values[validation.fieldNames.old_password]),
      [validation.fieldNames.password]: persianToEnglishNumber(values[validation.fieldNames.password]),
      re_try_password: persianToEnglishNumber(values[validation.fieldNames.confirm_password]),
    };

    console.log('* * * onSubmit : ', { reqData, values });
    mutate(reqData);
  };
  console.log({ queryParams });
  return false ? (
    <WaitingBox />
  ) : (
    <Box
      sx={{
        width: '100%',
        // display: { xs: 'block', md: 'flex' },
        gap: 2,
        bgcolor: 'background.paper',
        borderRadius: 1,
        p: 4,
      }}
    >
      <HeaderBreadcrumbs
        // back={`${Enum.routes.root}`}
        heading={
          "ویرایش حساب کاربری"
          // paramId ? t('editAuthor', { author: Enum.title.name[0] }) : t('addAuthor', { author: Enum.title.name[0] })
        }
      >
        {/* <Typography sx={{ mt: 1 }}>{resQuery?.data?.data?.name}</Typography> */}
      </HeaderBreadcrumbs>
      {/* <Box
        sx={{
          textAlign: 'center',
          mb: '30px',
        }}
        onClick={() => navigate(backUrl)}
      >
        <Typography variant="h5">{'شما در حال ایجاد یک دوره جدید هستید!'}</Typography>
      </Box> */}

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <RHFTextField name={validation.fieldNames.old_password} label={'رمزعبور فعلی'} type={'password'} />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFTextField
                name={validation.fieldNames.password}
                label={'رمز عبور جدید'}
                type={'password'}
                text={
                  paramId
                    ? 'در صورت نیاز به تغییر رمز عبور مقدار جدید را وارد کنید. در غیر این صورت آن را خالی بگزارید.'
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFTextField name={validation.fieldNames.confirm_password} label={'تکرار رمز عبور جدید'} type={'password'} />
            </Grid>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <LoadingButton type="submit" variant="contained" color={'success'} loading={isLoading}>
              {'ثبت اطلاعات'}
            </LoadingButton>
          </Grid>
        </Box>

      </FormProvider>
    </Box>
  );
};

export default AdminProfile;
