import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Button, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { haveHasType, caravanType } from '../../../enumeration';
import { fDate, fDateJalali, fDateTime } from '../../../utils/formatTime';
import Enum from '../enum';
import TableMoreMenu from '../../../components/table/TableMoreMenu';
import Iconify from '../../../components/Iconify';
import MoreTable from '../../../components/table/MoreTable';
import MoreTableItem from '../../../components/table/MoreTable/MoreTableItem';
import { hasAccess } from 'src/permission/utiles';
import buildAccess from 'src/permission/buildAccess';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { Download } from '@mui/icons-material';

export const tableData = ({ baseRoute, onDelete, disabled }) => [
  {
    key: 'name',
    header: 'سفر',
    Component: ({ param }) => param?.name || '-',
  },
  {
    key: 'start_at',
    header: 'تاریخ شروع ثبت نام',
    Component: ({ param }) => (param?.registration_start_at && fDate(param?.registration_start_at)) || '-',
  },
  {
    key: 'finish_at',
    header: 'تاریخ پایان ثبت نام',
    Component: ({ param }) => (param?.registration_finish_at && fDate(param?.registration_finish_at)) || '-',
  },
  {
    key: 'history',
    header: '',
    Component: ({ param }) => (
      hasAccess(buildAccess("Chart").read) && 
      <>
        <a href={`${PATH_DASHBOARD.report.excel.root}/${param.id}`}>
          <Button
            variant="outlined"
            color="success"
            // endIcon={<Download />}
          >
            {'تاریخچه اکسل های این سفر'}
          </Button>
        </a>
      </>
    ),
    free: true,
  },
  {
    key: 'show',
    header: '',
    Component: ({ param }) => (
      hasAccess(buildAccess("Chart").read) &&
      <>
        <Link to={`${baseRoute}${Enum.routes.show}/${param.id}`}>
          <KeyboardBackspaceIcon
            sx={{
              cursor: 'pointer',
            }}
          />
        </Link>
      </>
    ),
    free: true,
  },
];
