import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Button, MenuItem, Tooltip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { haveHasType, caravanType } from '../../../enumeration';
import { fDate, fDateJalali, fDateTime } from '../../../utils/formatTime';
import Enum from '../enum';
import TableMoreMenu from '../../../components/table/TableMoreMenu';
import Iconify from '../../../components/Iconify';
import MoreTable from '../../../components/table/MoreTable';
import MoreTableItem from '../../../components/table/MoreTable/MoreTableItem';
import { hasAccess } from 'src/permission/utiles';
import buildAccess from 'src/permission/buildAccess';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { Download } from '@mui/icons-material';

export const tableData = ({ baseRoute, onDelete, disabled }) => [
  {
    key: 'name',
    header: 'نام ادمین',
    Component: ({ param }) => `${param?.admin?.name || '-'} ${param?.admin?.last_name || ""}`,
    minWidth:100,
  },
  {
    key: 'client',
    header: 'نام کاربر',
    Component: ({ param }) => `${param?.client?.name || '-'} ${param?.client?.last_name || ""}`,
    minWidth:100,
  },
  {
    key: 'description',
    header: 'توضیحات',
    Component: ({ param }) =>
      <>
        <Tooltip title={param?.description || ""} placement="top">
          <Typography
            sx={{
              width: 550,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              fontSize: 12,
            }}
          >
            {param?.description || "-"}
          </Typography>
        </Tooltip>
      </>,
    minWidth:100,
  },
  {
    key: 'created_at',
    header: 'تاریخ',
    Component: ({ param }) => (param?.created_at && fDateTime(param?.created_at)) || '-',
    minWidth:100,
  },
  // {
  //   key: 'history',
  //   header: '',
  //   Component: ({ param }) => (
  //     hasAccess(buildAccess("Chart").read) && 
  //     <>
  //       <a href={`${PATH_DASHBOARD.report.excel.root}/${param.id}`}>
  //         <Button
  //           variant="outlined"
  //           color="success"
  //           // endIcon={<Download />}
  //         >
  //           {'تاریخچه اکسل های این سفر'}
  //         </Button>
  //       </a>
  //     </>
  //   ),
  //   free: true,
  // },
  {
    key: 'show',
    header: '',
    Component: ({ param }) => (
      hasAccess(buildAccess("Chart").read) &&
      <>
        <Link to={`${baseRoute}${Enum.routes.show}/${param.id}`}>
          <KeyboardBackspaceIcon
            sx={{
              cursor: 'pointer',
            }}
          />
        </Link>
      </>
    ),
    free: true,
  },
];
