import { Box, Grid, Typography } from "@mui/material";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import EAgent from "../enum";
import { separateNumberWithComma } from "src/utils";
import ReactApexChart from "react-apexcharts";
import { merge } from "lodash";
import { BaseOptionChart } from "src/components/chart";
import { useParams } from "react-router";
import axiosInstance from "src/utils/axios";
import api from "src/services/api";
import { useQueryCustom } from "src/utils/reactQueryHooks";
import { useState } from "react";
import WaitingBox from "src/components/WaitingBox";

const ShowPage = () => {
    const backUrl = `${EAgent.routes.root}`;
    const queryParams = useParams();
    const [ChartData, setChartData] = useState([])

    const chartOptions1 = {
        chart: {
            type: "pie",
        },
        labels: ["تعداد دانشجویان سرگروه متاهلی", "تعداد دانشجویان مجردی پسر", "تعداد دانشجویان مجردی دختر", "تعداد اساتید"], // Labels for the chart
        dataLabels: {
            enabled: true, // Show data labels
            style: {
                fontSize: "14px",
                fontFamily: "IRANYekan, sans-serif",
            },
            dropShadow: {
                enabled: false, // Disable shadow for cleaner text
            },
            formatter: (val, opts) => {
                // Custom format: Value and Label
                const rawData = opts.w.config.series[opts.seriesIndex]; // Get the raw value (e.g., 100)

                return `${separateNumberWithComma(rawData || 0)}`;
                // return `${val.toFixed(1)}%`;
            },
            textAnchor: "middle", // Keep text aligned
        },
        legend: {
            position: "bottom", // Place the legend at the bottom
            fontFamily: "IRANYekan, sans-serif",
            fontSize: "14px",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 300,
                    },
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    };
    const chartOptions = merge(BaseOptionChart(), {
        labels: ["تعداد دانشجویان سرگروه متاهلی", "تعداد دانشجویان مجردی پسر", "تعداد دانشجویان مجردی دختر", "تعداد اساتید"],
        xaxis: {
            categories: ["تعداد دانشجویان سرگروه متاهلی", "تعداد دانشجویان مجردی پسر", "تعداد دانشجویان مجردی دختر", "تعداد اساتید"],
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    offset: -20, // Adjust position to move outside slices
                },
            },
        },
    });

    const getApi = async () =>
        axiosInstance.get(`${api?.travel.chart}?travel_id=${queryParams?.id}`);

    const handleSuccessData = (resData) => {
        const helpArr = []
        helpArr.push(Math.abs(resData?.data?.marriedClients));
        helpArr.push(Math.abs(resData?.data?.manClients));
        helpArr.push(Math.abs(resData?.data?.womanClients));
        helpArr.push(Math.abs(resData?.data?.ostadClients));
        setChartData(helpArr)
    }

    const { data, isLoading } = useQueryCustom({
        name: `${EAgent?.api?.base}_list_chart_data`,
        url: getApi,
        staleTime: 0, // Data becomes stale immediately
        cacheTime: 0,
        onSuccess: handleSuccessData,
        // params: { ...queryString },
    });

    const getByIdTravel = async () => axiosInstance.get(`${api.travel.base}/${queryParams?.id}`);

    const travelData = useQueryCustom({
        name: `${api.travel.base}_get_chart_${queryParams?.id}`,
        url: getByIdTravel,
        staleTime: 0, // Data becomes stale immediately
        cacheTime: 0,
    });


    console.log({ data })


    return (
        <>

            <Box
                sx={{
                    width: '100%',
                    // display: { xs: 'block', md: 'flex' },
                    gap: 2,
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    p: 4,
                }}
            >
                <HeaderBreadcrumbs
                    heading={`نمودار ثبت نامی لحظه ای ${travelData?.data?.data?.forms?.[0]?.travel?.name || ""}`}
                    back={backUrl}
                />

                {isLoading ? <WaitingBox /> :
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography variant="h2">{separateNumberWithComma(data?.data?.head_count)}</Typography>
                                    <Typography>{'تعداد کل سرگروه های‌ثبت نامی'}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography variant="h2">{separateNumberWithComma(data?.data?.companionCount)}</Typography>
                                    <Typography>{'تعداد همراهان ثبت نام شده'}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography variant="h2">{separateNumberWithComma(data?.data?.allCount)}</Typography>
                                    <Typography>{'تعداد کل'}</Typography>
                                </Box>
                            </Grid>
                            {ChartData?.length > 0 &&
                                <>
                                    <Grid item xs={12} md={12}>
                                        <ReactApexChart
                                            options={chartOptions1}
                                            // series={chartSeries}
                                            series={ChartData}
                                            type="pie"
                                            height={350}
                                        />
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </>
                }



            </Box>
        </>
    );
}

export default ShowPage;