import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Button, Container, Grid, Modal, Typography } from '@mui/material';
import { Link as RouterLink, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../../../utils/axios';
import { useQueryCustom } from '../../../../../utils/reactQueryHooks';
import useQueryString from '../../../../../utils/useQueryString';
import Enum from '../../../enum';
import Page from '../../../../../components/Page';
import HeaderBreadcrumbs from '../../../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../../../routes/paths';
import useSettings from '../../../../../hooks/useSettings';
import Iconify from '../../../../../components/Iconify';
import TableComponent from '../../../../../components/table/TableComponent';
import { tableData } from './tableInfo';
import useTable from '../../../../../hooks/useTable';
import Layout from '../../Layout';
import AddClient from '../Add';

const List = () => {
  const { t } = useTranslation();
  const queryParams = useParams();
  const queryString = useQueryString();

  const getting = async () => axiosInstance.get(`${Enum?.api?.base}/${queryParams.id}`);

  const { themeStretch } = useSettings();

  const tableSetting = useTable({ queryString });
  console.log({ tableSetting, queryParams });
  // ------------------------------------------------------- query Get
  const {
    data: { data = {} } = {},
    error,
    isError,
    isLoading,
    refetch,
    isFetching,
  } = useQueryCustom({
    name: `${Enum?.api?.base}_get_${queryParams.id}`,
    url: getting,
    params: { ...queryString },
  });

  console.log({ data });

  return (
    <Layout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 2,
            backgroundColor: 'grey.main',
            borderRadius: '8px',
          }}
        >
          <Typography>{'اطلاعات فردی'}</Typography>
        </Box>
        <AddClient {...{ isLoading, data: data?.client }} />

        {/* <Routes>
          <Route path={Enum.routes.list} element={<Travels />} />
          <Route path={`/:travel`} element={<Travel />} />
        </Routes> */}
      </Box>
    </Layout>
  );
};

export default List;
