import { Box, Button, Container, Modal, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

import axiosInstance from '../../../../utils/axios';
import { useQueryCustom } from '../../../../utils/reactQueryHooks';
import useQueryString from '../../../../utils/useQueryString';
import Enum from '../../enum';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import Iconify from '../../../../components/Iconify';
import AddComponent from '../../../Course/Add';
import TableComponent from '../../../../components/table/TableComponent';
import { tableData } from './tableInfo';
import useTable from '../../../../hooks/useTable';
import { separateNumberWithComma } from '../../../../utils/index';

const List = () => {
  const queryParams = useParams();
  const [toggleModal, setToggleModal] = useState();
  const queryString = useQueryString();
  const baseUrl = `${Enum.routes.root(queryParams.type, queryParams.travel)}`;
  const getting = async () =>
    axiosInstance.get(`${Enum?.api?.base}/${queryParams.travel}`, { params: { ...queryString } });

  const { themeStretch } = useSettings();

  const tableSetting = useTable({ queryString });
  console.log({ tableSetting, queryParams });
  // ------------------------------------------------------- query Get
  const {
    data: { data = {} } = {},
    error,
    isError,
    isLoading,
    refetch,
    isFetching,
  } = useQueryCustom({
    name: `${Enum?.api?.base}_get_${queryParams.travel}`,
    url: getting,
    params: { ...queryString },
  });

  const handleToggleModal = () => setToggleModal((p) => !p);

  // const data = [
  //   {
  //     name: 'hasan',
  //     lastName: 'jafari',
  //     nationalCode: '23561484155',
  //     phoneNumber: '09111111111',
  //     reason: 'jafari',
  //     createdAt: '1403/04/05',
  //   },
  //   {
  //     name: 'hasan',
  //     lastName: 'jafari',
  //     nationalCode: '23561484155',
  //     phoneNumber: '09111111111',
  //     reason: 'jafari',
  //     createdAt: '1403/04/05',
  //   },
  // ];

  const tableOptions = tableData();
  const title = `مدیریت ${Enum.title.name[0]}`;
  console.log({ tableOptions, data });
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          my: 2,
        }}
      >
        <Typography sx={{ my: 0 }}>{'لیست افرد برنده در قرعه کسی'}</Typography>
        <Typography sx={{ my: 0, display: 'flex', gap: 1, color: 'grey.700', fontSize: '14px' }}>
          <p>{'لیست'}</p>
          <strong>{separateNumberWithComma('5420')}</strong>
          <p>{'نفر از'}</p>
          <strong>{separateNumberWithComma('5420')}</strong>
          <p>{'که در قرعه کشی برنه شدند.'}</p>
        </Typography>
        <Typography sx={{ my: 0, display: 'flex', gap: 1, color: 'grey.700', fontSize: '14px' }}>
          {
            'در صورتی که این لیست مورد تایید است دکمه تایید قرعه کشی را بزنید و در غیر این صورت جهت ادامه برای فیلترهای بیشتر بر روی دکمه ادامه قرعه‌ کشی کلیک کنید'
          }
        </Typography>
      </Box>
      <TableComponent
        active={{
          // selectable: true,
          rowNumber: true,
        }}
        options={tableOptions}
        data={data?.data}
        tableSetting={tableSetting}
        pagination={{
          totalPage: data?.meta?.last_page,
          total: data?.meta?.total,
        }}
        loading={isLoading}
      />{' '}
      <Box
        sx={{
          py: 2,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button
            sx={{
              minWidth: '150px',
              justifyContent: 'space-around',
            }}
            variant=""
            component={Link}
            to={baseUrl + Enum.routes.lottery}
            endIcon={<HighlightOffOutlinedIcon />}
          >
            {'لغو'}
          </Button>

          <Button variant="outlined" color="success" endIcon={<BlurCircularIcon />}>
            {'ادامه قرعه کشی'}
          </Button>

          <Button variant="contained" color="success" endIcon={<BlurCircularIcon />}>
            {'تایید قرعه کشی'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default List;
