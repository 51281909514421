import { useParams } from 'react-router-dom';
import { Route, Routes } from 'react-router';
import Enum from './enum';
import List from './List';
import buildAccess from 'src/permission/buildAccess';
import PermissionRoute from 'src/permission/permissionRoute';

const ExceltPage = () => {
  // const urlParams = useParams();

  const access = buildAccess(Enum.routes.name);
  return (
    <Routes>
      <Route path={`/:travel`} element={<PermissionRoute element={<List access={access} />} permissions={access?.read} />} />
    </Routes>
  );
};

export default ExceltPage;
