import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, Container, Modal, Typography } from '@mui/material';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../utils/axios';
import { useMutationCustom, useQueryCustom } from '../../../utils/reactQueryHooks';
import useQueryString from '../../../utils/useQueryString';
import Enum from '../enum';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Iconify from '../../../components/Iconify';
import TableComponent from '../../../components/table/TableComponent';
import { tableData } from './tableInfo';
import useTable from '../../../hooks/useTable';
import { hasAccess } from '../../../permission/utiles';
import WaitingBox from 'src/components/WaitingBox';
import { acceptFinallyStatus } from 'src/enumeration';
import { Interweave } from 'interweave';

const ShowList = ({ access }) => {
  const { t } = useTranslation();
  const queryParams = useParams();
  const queryString = useQueryString();

  const [MessageDetail, setMessageDetail] = useState({})

  // ---------------------------------------------------------------------
  const getApi = async () =>
    axiosInstance.get(`${Enum?.api?.client}`, { params: { ...queryString, notification_id: queryParams.id } });
  const getSingleApi = async () =>
    axiosInstance.get(`${Enum?.api?.base}/${queryParams.id}`);

  const deleteApi = async ({ id }) => axiosInstance.delete(`${Enum?.api?.base}/${id}`);
  // ---------------------------------------------------------------------

  const { themeStretch } = useSettings();

  const tableSetting = useTable({ queryString });
  console.log({ tableSetting, queryParams });
  // ------------------------------------------------------- query Get
  const { data: { data = {} } = {}, isLoading } = useQueryCustom({
    name: `${Enum?.api?.base}_list`,
    url: getApi,
    params: { ...queryString },
    staleTime: 0, // Data becomes stale immediately
    cacheTime: 0,
  });
  const singleData = useQueryCustom({
    name: `${Enum?.api?.base}_single`,
    url: getSingleApi,
    params: { ...queryString },
    staleTime: 0, // Data becomes stale immediately
    cacheTime: 0,
    onSuccess:(resData) =>{
      console.log({resData});
      setMessageDetail(resData?.data)
    }
  });
  // ------------------------------------------------------- query delete

  const deleteInput = useMutationCustom({
    url: deleteApi,
    name: `${Enum?.api?.base}_delete`,
    invalidQueries: [`${Enum?.api?.base}_list`],
    onSuccess: () => {
      toast.success(t('question.delete.successfully'));
    },
    onError: (error) => {
      console.log({ error });
      const errorTitle = error.response.data.message || t('errorTryAgain');

      const errors = Object.values(error?.response?.data?.errors || {});
      if (errors?.length) {
        errors?.map((x) => {
          return toast.error(x?.[0]);
        });
      } else toast.error(errorTitle);
    },
  });
  const checkAccess = useMemo(() => {
    return {
      // update: hasAccess(access?.update),
      delete: hasAccess(access?.delete),
      create: hasAccess(access?.create),
      confirm: hasAccess(access?.confirm),
    };
  }, [access]);

  const title = `مشاهده ${Enum.title.name[1]} `;
  const tableOptions = tableData({
    baseRoute: `${Enum.routes.root}`,
    onDelete: (x) => deleteInput.mutate({ id: x?.id }),
    disabled: {
      update: !checkAccess?.update,
      delete: !checkAccess?.delete,
    },
  });

  console.log({ tableOptions, data });

  return (
    <Page title={`${title}`}>
      <Container maxWidth={themeStretch ? false : 'lg'} key={`show-notifications-client-list-${queryParams?.id}`}>
        <HeaderBreadcrumbs
          heading={title}
          back={`${PATH_DASHBOARD.notifications.root}/${queryParams?.travelId}/list`}
          links={[
            { name: 'داشبورد', href: Enum.routes.root },
            { name: title, href: Enum.routes.list },
            { name: 'لیست' },
          ]}
        />

        {MessageDetail?.message &&
          <>
            <Card
              sx={{
                border:`1px solid #a4aab0`,
                bgcolor:`#F3F3F3`,
                borderRadius:1.5,
                p:1.5,
                mb:2
              }}
            >
              <Typography sx={{
                fontWeight:700,
                mb:1,
              }}>متن پیام ارسالی</Typography>
              {/*<Typography sx={{color:"#222", fontSize:15, mb:1}}>{MessageDetail?.message}</Typography>*/}
              <Interweave content={MessageDetail?.message || ""} />

              {MessageDetail?.province?.name &&
                <Box sx={{mt:1 , fontSize:14}}>
                  <Typography component={"span"} sx={{fontSize:14}}>ارسال شده برای استان : </Typography>
                  <Typography component={"span"} sx={{fontSize:14}}>{MessageDetail?.province?.name}</Typography>
                </Box>
              }

              {MessageDetail?.team?.name &&
                <Box sx={{mt:1 , fontSize:14}}>
                  <Typography component={"span"} sx={{fontSize:14}}>ارسال شده برای کاروان : </Typography>
                  <Typography component={"span"} sx={{fontSize:14}}>{MessageDetail?.team?.name}</Typography>
                </Box>
              }

              {MessageDetail?.status_num &&
                <Box sx={{mt:1 , fontSize:14}}>
                  <Typography component={"span"} sx={{fontSize:14}}>ارسال شده برای زائران : </Typography>
                  <Typography component={"span"} sx={{fontSize:14}}>{acceptFinallyStatus?.[MessageDetail?.status_num]?.label}</Typography>
                </Box>
              }

              {MessageDetail?.nezam_vazife_status &&
                <Box sx={{mt:1 , fontSize:14}}>
                  <Typography component={"span"} sx={{fontSize:14}}>ارسال شده برای زائران با نظام وظیفه : </Typography>
                  <Typography component={"span"} sx={{fontSize:14}}>{MessageDetail?.nezam_vazife_status}</Typography>
                </Box>
              }

              {MessageDetail?.tasharof_be_soorat &&
                <Box sx={{mt:1 , fontSize:14}}>
                  <Typography component={"span"} sx={{fontSize:14}}>ارسال شده برای زائران تشرف به صورت : </Typography>
                  <Typography component={"span"} sx={{fontSize:14}}>{MessageDetail?.tasharof_be_soorat}</Typography>
                </Box>
              }

              <Box sx={{mt:1 , fontSize:14}}>
                <Typography component={"span"} sx={{fontSize:14}}>ارسال کننده : </Typography>
                <Typography component={"span"} sx={{fontSize:14}}>{MessageDetail?.admin_id?.name || ""} {MessageDetail?.admin_id?.last_name || ""}</Typography>
              </Box>

            </Card>
          </>
        }


        
        {/* {isLoading? <WaitingBox /> : */}
          <>
            <TableComponent
              active={{
                // selectable: true,
                rowNumber: true,
              }}
              options={tableOptions}
              data={data?.data}
              tableSetting={tableSetting}
              pagination={{
                totalPage: data?.meta?.last_page,
                total: data?.meta?.total,
              }}
              loading={isLoading}
            />{' '}
          </>
        {/* } */}
      </Container>
    </Page>
  );
};

export default ShowList;
