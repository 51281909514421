/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import { EMAIL_REGEX, NATIONAL_CODE_REGEX, PERSIAN_CHAR_REGEX, PERSIAN_CHAR_REGEX_WITH_NUM, PERSIAN_NUMBER_REGEX } from 'src/config';
import errorsText from 'src/utils/errorsText';
import * as Yup from 'yup';

const companionSchema = Yup.object().shape({
    name: Yup.string().matches(PERSIAN_CHAR_REGEX, errorsText.persianChar).required(errorsText.blankError()),
    last_name: Yup.string().matches(PERSIAN_CHAR_REGEX, errorsText.persianChar).required(errorsText.blankError()),
    shenasname: Yup.string()
        .matches(PERSIAN_NUMBER_REGEX, errorsText.numberFormat("شماره شناسنامه")) // Accepts Persian and Arabic numerals
        .required(errorsText.blankError()),
    codeMeli: Yup.string().matches(NATIONAL_CODE_REGEX, errorsText.invalidValue("کدملی")).required(errorsText.blankError()),
    father_name: Yup.string().matches(PERSIAN_CHAR_REGEX, errorsText.persianChar).required(errorsText.blankError()),
    birthDate: Yup.mixed().required(errorsText.blankError()),
    phone: Yup.string()
        .matches(PERSIAN_NUMBER_REGEX, errorsText.numberFormat("تلفن همراه")) // Accepts Persian and Arabic numerals
        .max(11, errorsText.maxLength(11, "تلفن همراه"))
        .required(errorsText.blankError()),

    address: Yup.string().matches(PERSIAN_CHAR_REGEX_WITH_NUM, errorsText.persianChar).required(errorsText.blankError()),
    postalCode: Yup.string()
        .matches(PERSIAN_NUMBER_REGEX, errorsText.numberFormat("کدپستی")) // Accepts Persian and Arabic numerals
        .max(10, errorsText.maxLength(10, "کدپستی"))
        .required(errorsText.blankError()),
    homePhone: Yup.string().typeError(errorsText.blankError()).matches(PERSIAN_NUMBER_REGEX, errorsText.numberFormat("تلفن ثابت (به همراه کد)"))
        .max(11, errorsText.maxLength(11, "تلفن ثابت (به همراه کد)")),

    sodorShenasnameh: Yup.string().matches(PERSIAN_CHAR_REGEX, errorsText.persianChar).required(errorsText.blankError()),
    job: Yup.string().matches(PERSIAN_CHAR_REGEX, errorsText.persianChar).required(errorsText.blankError()),

});


const FormSchema = Yup.object().shape({
    name: Yup.string().matches(PERSIAN_CHAR_REGEX, errorsText.persianChar)
        .when(['gender', 'nezan_vazifeh'], {
            is: (gender, nezam_vazifeh) => gender === 'مونث' || nezam_vazifeh !== 'معافیت تحصیلی',
            then: Yup.string(),
            otherwise: Yup.string().required(errorsText.blankError()),
        }),
    last_name: Yup.string().matches(PERSIAN_CHAR_REGEX, errorsText.persianChar).when(['gender', 'nezan_vazifeh'], {
        is: (gender, nezam_vazifeh) => gender === 'مونث' || nezam_vazifeh !== 'معافیت تحصیلی',
        then: Yup.string(),
        otherwise: Yup.string().required(errorsText.blankError()),
    }),
    shenasname: Yup.string()
        .matches(PERSIAN_NUMBER_REGEX, errorsText.numberFormat("شماره شناسنامه")) // Accepts Persian and Arabic numerals
        .when('gender', {
            is: 'مونث',
            then: Yup.string(),
            otherwise: Yup.string().required(errorsText.blankError()),
        }),
    codeMeli: Yup.string().matches(NATIONAL_CODE_REGEX, errorsText.invalidValue("کدملی")).when(['gender', 'nezan_vazifeh'], {
        is: (gender, nezam_vazifeh) => gender === 'مونث' || nezam_vazifeh !== 'معافیت تحصیلی',
        then: Yup.string(),
        otherwise: Yup.string().required(errorsText.blankError()),
    }),
    father_name: Yup.string().matches(PERSIAN_CHAR_REGEX, errorsText.persianChar).when(['gender', 'nezan_vazifeh'], {
        is: (gender, nezam_vazifeh) => gender === 'مونث' || nezam_vazifeh !== 'معافیت تحصیلی',
        then: Yup.string(),
        otherwise: Yup.string().required(errorsText.blankError()),
    }),
    birthDate: Yup.mixed().when('gender', {
        is: 'مونث',
        then: Yup.mixed(),
        otherwise: Yup.mixed().required(errorsText.blankError()),
    }),
    phone: Yup.string()
        .matches(PERSIAN_NUMBER_REGEX, errorsText.numberFormat("تلفن همراه")) // Accepts Persian and Arabic numerals
        .max(11, errorsText.maxLength(11, "تلفن همراه"))
        .when('gender', {
            is: 'مونث',
            then: Yup.string(),
            otherwise: Yup.string().required(errorsText.blankError()),
        }),

    address: Yup.string().matches(PERSIAN_CHAR_REGEX_WITH_NUM, errorsText.persianChar).when(['gender', 'nezan_vazifeh'], {
        is: (gender, nezam_vazifeh) => gender === 'مونث' || nezam_vazifeh !== 'معافیت تحصیلی',
        then: Yup.string(),
        otherwise: Yup.string().required(errorsText.blankError()),
    }),
    postalCode: Yup.string()
        .matches(PERSIAN_NUMBER_REGEX, errorsText.numberFormat("کدپستی")) // Accepts Persian and Arabic numerals
        .max(10, errorsText.maxLength(10, "کدپستی"))
        .when('gender', {
            is: 'مونث',
            then: Yup.string(),
            otherwise: Yup.string().required(errorsText.blankError()),
        }),
    homePhone: Yup.string().typeError(errorsText.blankError()).matches(PERSIAN_NUMBER_REGEX, errorsText.numberFormat("تلفن ثابت (به همراه کد)"))
        .max(11, errorsText.maxLength(11, "تلفن ثابت (به همراه کد)")),

    sodorShenasnameh: Yup.string().matches(PERSIAN_CHAR_REGEX, errorsText.persianChar).when(['gender', 'nezan_vazifeh'], {
        is: (gender, nezam_vazifeh) => gender === 'مونث' || nezam_vazifeh !== 'معافیت تحصیلی',
        then: Yup.string(),
        otherwise: Yup.string().required(errorsText.blankError()),
    }),
    job: Yup.string().matches(PERSIAN_CHAR_REGEX, errorsText.persianChar).when(['gender', 'nezan_vazifeh'], {
        is: (gender, nezam_vazifeh) => gender === 'مونث' || nezam_vazifeh !== 'معافیت تحصیلی',
        then: Yup.string(),
        otherwise: Yup.string().required(errorsText.blankError()),
    }),

    companions: Yup.array()
        .of(companionSchema)
        .when('$context', (context, schema) => {
            const { companionCount } = context || {};
            const count = companionCount || 0;
            if ((count > 0)) {
                return schema
                    .min(
                        count,
                        `لطفاً اطلاعات ${count} همراه را وارد کنید`
                    )
                    .max(
                        count,
                        `تعداد همراه‌ها نباید بیشتر از ${count} باشد`
                    );
            }
        }),

});

export default FormSchema

