import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/routes/paths';
import EProfile from 'src/pages/profiles/enum';
import { Box } from '@mui/material';
import { haveHasType, caravanType } from '../../../../../enumeration';
import { fDate, fDateJalali, fDateTime } from '../../../../../utils/formatTime';
import Enum from '../../../enum';
import MoreTableItem from '../../../../../components/table/MoreTable/MoreTableItem';
import MoreTable from '../../../../../components/table/MoreTable/index';

export const tableData = ({ baseRoute, travel }) => [
  {
    key: 'name',
    header: 'نام و نام خانوادگی',
    Component: ({ param }) =>
      param?.client?.name || param?.client?.last_name ? (
        <Link
          to={`${PATH_DASHBOARD.profile.root}${PATH_DASHBOARD.profile.show}/${param.client.id}/${EProfile.enumTab.object.travels.value}/${param.id}&travel=${param?.travel?.id}`}
        >
          <Box sx={{ color: 'grey.900' }}>{`${param?.client?.name || '-'} ${param?.client?.last_name || '-'} ${
            param?.members > 1 ? `(${param?.members})` : ''
          }`}</Box>
        </Link>
      ) : (
        '-'
      ),
  },
  // {
  //   key: 'lastName',
  //   header: 'نام خانوادگی',
  //   Component: ({ param }) => param?.client?.last_name || '-',
  // },

  {
    key: 'nationalCode',
    header: 'کدملی',
    Component: ({ param }) => param?.client?.code_melli || '-',
  },
  {
    key: 'phone',
    header: 'تلفن همراه',
    Component: ({ param }) => param?.client?.phone || '-',
  },
  {
    key: '',
    header: 'تخت',
    Component: ({ param }) => param?.bed || '-',
  },
  // {
  //   key: 'shenasname_number',
  //   header: 'شماره شناسنامه',
  //   Component: ({ param }) => param?.client?.shenasname_number || '-',
  // },
  // {
  //   key: 'id',
  //   header: 'کد شناسایی/ID',
  //   Component: ({ param }) => param?.client?.id || '-',
  // },
  // {
  //   key: 'createdAt',
  //   header: 'تاریخ',
  //   Component: ({ param }) => fDateTime(param?.client?.created_at) || '-',
  // },
  // {
  //   key: 'actions',
  //   header: '',
  //   Component: ({ param }) => (
  //     <MoreTable>
  //       <Link to={`${baseRoute + Enum.routes.edit}/${param?.id}`}>
  //         <MoreTableItem isEdit />
  //       </Link>
  //       {/* <MoreTableItem isDelete onClick={() => onDelete(param)} /> */}
  //     </MoreTable>
  //   ),
  //   free: true,
  // },
  // {
  //   key: 'show',
  //   header: '',
  //   Component: ({ param }) => (
  //     <Link to={`${baseRoute}${Enum.routes.add}/${param.id}`}>
  //       <KeyboardBackspaceIcon
  //         sx={{
  //           cursor: 'pointer',
  //         }}
  //       />
  //     </Link>
  //   ),
  //   free: true,
  // },
];
