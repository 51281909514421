/* eslint-disable prefer-const */
/* eslint-disable no-unreachable */
/* eslint-disable no-plusplus */
import { useState, useRef, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Box, Button, Typography, Grid, Stack } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { CheckCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../../../utils/axios';
import { useMutationCustom, useQueryCustom } from '../../../../utils/reactQueryHooks';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import useSettings from '../../../../hooks/useSettings';
import WaitingBox from '../../../../components/WaitingBox/index';
import FormProvider from '../../../../components/hook-form/FormProvider';
import api from '../../../../services/api';
import persianToEnglishNumber from '../../../../utils/persianToEnglishNumber';
import { fDateForApi } from '../../../../utils/formatTime';
import ModalLayout from '../../../../components/ModalLayout';
import QuestionComponent from '../../../../components/ui/Question/index';
import { useSelector } from 'react-redux';
import { RHFTextField } from 'src/components/hook-form';
import RHFDatePicker from 'src/components/hook-form/RHFDatePicker';
import RHFRadioGroups from 'src/components/hook-form/RHFRadioGroups';
import RHFSelector from 'src/components/hook-form/RHFSelector';
import ProvinceSelector from 'src/components/selector/Province';
import universityCategorySelector from 'src/pages/universityCategory/selector';
import UniversitySelector from 'src/pages/university/selector';
import { CLIENT_TYPE_OP, DUTY_DEFAULT_OP, GENDER_DEFAULT_OP, PASSPORT_DEFAULT_OP, PASSPORT_SENDING_STATUS, TASHAROF_DEFAULT_OP } from 'src/config';
import axiosInstanceV2 from 'src/utils/axiosV2';
import Iconify from 'src/components/Iconify';
import RemoveNullObjectValue from 'src/utils/RemoveNullObjectValue';
import { LoadingButton } from '@mui/lab';
import createQueryString from 'src/utils/createQueryString';

const StaticFilterForm = ({ onClose, queryString, customRefetch, CustomFilterObj, setCustomFilterObj }) => {

    const ModalLayoutQuestionInputRef = useRef();
    const ModalLayoutSuccessRef = useRef();

    const admin = useSelector((store) => store.admin.data);

    const [SearchParams] = useSearchParams();

    const defaultValues = useMemo(
        () => ({
            id: -1,
            name: CustomFilterObj?.filters?.main_client_name?.value || "",
            last_name: CustomFilterObj?.filters?.main_client_last_name?.value || "",
            shenasname: CustomFilterObj?.filters?.main_client_shenasname_number?.value || "",
            codeMeli: CustomFilterObj?.filters?.main_client_code_melli?.value || "",
            father_name: CustomFilterObj?.filters?.main_client_father_name?.value || "",
            fromBirthDate: CustomFilterObj?.filters?.main_client_birthdate_from?.value ? new Date(CustomFilterObj?.filters?.main_client_birthdate_from?.value) : "",
            toBirthDate: CustomFilterObj?.filters?.main_client_birthdate_to?.value ? new Date(CustomFilterObj?.filters?.main_client_birthdate_to?.value) : "",
            gender: CustomFilterObj?.filters?.main_client_gender?.value && { label: CustomFilterObj?.filters?.main_client_gender?.value, value: CustomFilterObj?.filters?.main_client_gender?.value } || "",
            email: CustomFilterObj?.filters?.main_client_email?.value || "",
            phone: CustomFilterObj?.filters?.main_client_phone?.value || "",
            marride_status: CustomFilterObj?.filters?.main_client_marital_status?.value ? { label: CustomFilterObj?.filters?.main_client_marital_status?.value, value: CustomFilterObj?.filters?.main_client_marital_status?.value } : null,
            tashrof_be_sorat: CustomFilterObj?.filters?.main_client_tasharof_be_soorat?.value ? { label: CustomFilterObj?.filters?.main_client_tasharof_be_soorat?.value, value: CustomFilterObj?.filters?.main_client_tasharof_be_soorat?.value } : null,
            gozarnameh_status: CustomFilterObj?.filters?.main_client_passport_status?.value ? { label: CustomFilterObj?.filters?.main_client_passport_status?.value, value: CustomFilterObj?.filters?.main_client_passport_status?.value } : null,
            duty_system: CustomFilterObj?.filters?.main_client_nezam_vazife_status?.value ? { label: CustomFilterObj?.filters?.main_client_nezam_vazife_status?.value, value: CustomFilterObj?.filters?.main_client_nezam_vazife_status?.value } : null,
            mazhab: CustomFilterObj?.filters?.main_client_religion?.value ? { label: CustomFilterObj?.filters?.main_client_religion?.value, value: CustomFilterObj?.filters?.main_client_religion?.value } : null,
            need_vam: CustomFilterObj?.filters?.main_client_need_vam?.value ? { label: CustomFilterObj?.filters?.main_client_need_vam?.value, value: CustomFilterObj?.filters?.main_client_need_vam?.value } : null,
            sabegh_tasharof: CustomFilterObj?.filters?.main_client_sabeghe_tasharof?.value ? { label: CustomFilterObj?.filters?.main_client_sabeghe_tasharof?.value, value: CustomFilterObj?.filters?.main_client_sabeghe_tasharof?.value } : null,
            home_phone: CustomFilterObj?.filters?.main_client_home_phone?.value || "",
            has_hamrah: CustomFilterObj?.filters?.main_client_has_hamrah?.value ? { label: CustomFilterObj?.filters?.main_client_has_hamrah?.value, value: CustomFilterObj?.filters?.main_client_has_hamrah?.value } : null,
            hamrah_count: CustomFilterObj?.filters?.main_client_hamrah_count?.value ? { label: CustomFilterObj?.filters?.main_client_hamrah_count?.value, value: CustomFilterObj?.filters?.main_client_hamrah_count?.value } : null,
            marrid_date_from: CustomFilterObj?.filters?.main_client_marriage_date_from?.value ? new Date(CustomFilterObj?.filters?.main_client_marriage_date_from?.value) : "",
            marrid_date_to: CustomFilterObj?.filters?.main_client_marriage_date_to?.value ? new Date(CustomFilterObj?.filters?.main_client_marriage_date_to?.value) : "",
            student_marriage: CustomFilterObj?.filters?.main_client_student_marriage?.value ? { label: CustomFilterObj?.filters?.main_client_student_marriage?.value, value: CustomFilterObj?.filters?.main_client_student_marriage?.value } : null,
            provoince_university: CustomFilterObj?.filters?.main_client_university_city?.value ? { label: CustomFilterObj?.filters?.main_client_university_city?.value, value: CustomFilterObj?.filters?.main_client_university_city?.value } : null,
            type_university: CustomFilterObj?.filters?.main_client_university_category?.value ? { label: CustomFilterObj?.filters?.main_client_university_category?.value, value: CustomFilterObj?.filters?.main_client_university_category?.value } : null,
            university_code: CustomFilterObj?.filters?.main_client_university_name?.value ? { label: CustomFilterObj?.filters?.main_client_university_name?.value, value: CustomFilterObj?.filters?.main_client_university_name?.value } : null,
            kunkor: CustomFilterObj?.filters?.main_client_konkoor?.value ? { label: CustomFilterObj?.filters?.main_client_konkoor?.value, value: CustomFilterObj?.filters?.main_client_konkoor?.value } : null,
            degree: CustomFilterObj?.filters?.main_client_education_level?.value ? { label: CustomFilterObj?.filters?.main_client_education_level?.value, value: CustomFilterObj?.filters?.main_client_education_level?.value } : null,
            type_of_employment: CustomFilterObj?.filters?.main_client_hokm_kargozini?.value ? { label: CustomFilterObj?.filters?.main_client_hokm_kargozini?.value, value: CustomFilterObj?.filters?.main_client_hokm_kargozini?.value } : null,
            type_of_employment_state: CustomFilterObj?.filters?.main_client_university_level?.value ? { label: CustomFilterObj?.filters?.main_client_university_level?.value, value: CustomFilterObj?.filters?.main_client_university_level?.value } : null,
            type: CustomFilterObj?.filters?.main_client_type?.value ? { label: CustomFilterObj?.filters?.main_client_type?.value, value: CustomFilterObj?.filters?.main_client_type?.value } : null,
            // passport_sending_status: CustomFilterObj?.filters?.main_client_passport_sending_status?.value ? { label: CustomFilterObj?.filters?.main_client_passport_sending_status?.value, value: CustomFilterObj?.filters?.main_client_passport_sending_status?.value } : null,


            // hamsar
            hamsar_name: CustomFilterObj?.filters?.hamsar_name?.value || "",
            hamsar_last_name: CustomFilterObj?.filters?.hamsar_last_name?.value || "",
            hamsar_code_melli: CustomFilterObj?.filters?.hamsar_code_melli?.value || "",
            hamsar_phone: CustomFilterObj?.filters?.hamsar_phone?.value || "",
            // hamsar_shenasname_number : CustomFilterObj?.filters?.hamsar_shenasname_number?.value || "",
            hamsar_father_name: CustomFilterObj?.filters?.hamsar_father_name?.value || "",
            // hamsar_birthdate : CustomFilterObj?.filters?.hamsar_birthdate?.value ? new Date(CustomFilterObj?.filters?.hamsar_birthdate?.value) : "",
            hamsar_gender: CustomFilterObj?.filters?.hamsar_gender?.value ? { label: CustomFilterObj?.filters?.hamsar_gender?.value, value: CustomFilterObj?.filters?.hamsar_gender?.value } : "",
            hamsar_passport_status: CustomFilterObj?.filters?.hamsar_passport_status?.value ? { label: CustomFilterObj?.filters?.hamsar_passport_status?.value, value: CustomFilterObj?.filters?.hamsar_passport_status?.value } : "",
            hamsar_nezam_vazife_status: CustomFilterObj?.filters?.hamsar_nezam_vazife_status?.value ? { label: CustomFilterObj?.filters?.hamsar_nezam_vazife_status?.value, value: CustomFilterObj?.filters?.hamsar_nezam_vazife_status?.value } : "",
            hamsar_need_vam: CustomFilterObj?.filters?.hamsar_need_vam?.value ? { label: CustomFilterObj?.filters?.hamsar_need_vam?.value, value: CustomFilterObj?.filters?.hamsar_need_vam?.value } : "",
            hamsar_nesbat_to_head: CustomFilterObj?.filters?.hamsar_nesbat_to_head?.value ? { label: CustomFilterObj?.filters?.hamsar_nesbat_to_head?.value, value: CustomFilterObj?.filters?.hamsar_nesbat_to_head?.value } : "",
            hamsar_university_city: CustomFilterObj?.filters?.hamsar_university_city?.value ? { label: CustomFilterObj?.filters?.hamsar_university_city?.value, value: CustomFilterObj?.filters?.hamsar_university_city?.value } : "",
            hamsar_university_category: CustomFilterObj?.filters?.hamsar_university_category?.value ? { label: CustomFilterObj?.filters?.hamsar_university_category?.value, value: CustomFilterObj?.filters?.hamsar_university_category?.value } : "",
            hamsar_university_name: CustomFilterObj?.filters?.hamsar_university_name?.value ? { label: CustomFilterObj?.filters?.hamsar_university_name?.value, value: CustomFilterObj?.filters?.hamsar_university_name?.value } : "",
            hamsar_konkoor: CustomFilterObj?.filters?.hamsar_konkoor?.value ? { label: CustomFilterObj?.filters?.hamsar_konkoor?.value, value: CustomFilterObj?.filters?.hamsar_konkoor?.value } : "",
            hamsar_education_level: CustomFilterObj?.filters?.hamsar_education_level?.value ? { label: CustomFilterObj?.filters?.hamsar_education_level?.value, value: CustomFilterObj?.filters?.hamsar_education_level?.value } : "",
            hamsar_is_student: CustomFilterObj?.filters?.hamsar_is_student?.value ? { label: CustomFilterObj?.filters?.hamsar_is_student?.value, value: CustomFilterObj?.filters?.hamsar_is_student?.value } : "",
        }),

        [CustomFilterObj?.filters]
    );


    const handleQuestionModal = (params) => ModalLayoutQuestionInputRef.current.show((p) => !p, params);
    const handleSuccessModal = (params) => ModalLayoutSuccessRef.current?.show((p) => !p, params);

    const queryParams = useParams();
    const { id, travel } = queryParams;
    const { themeStretch } = useSettings();
    const { t } = useTranslation();
    const [validationState, setValidationState] = useState({});
    const [TravelApi, setTravelApi] = useState();
    const [inputsById, setInputsById] = useState();
    const [beforeRegistrant, setBeforeRegistrant] = useState();
    const [successBeforeRegistrant, setSuccessBeforeRegistrant] = useState();
    const [caravanOptions, setCaravanOptions] = useState();

    const [HideForWemen, setHideForWemen] = useState(false)

    const [companionSectionId, setCompanionSectionId] = useState();

    const [HamrahDataArr, setHamrahDataArr] = useState([]);

    const [TasharofOptionArr, setTasharofOptionArr] = useState(TASHAROF_DEFAULT_OP)

    const methods = useForm({
        // resolver: yupResolver(FormSchema),
        // mode: 'onChange',
        mode: 'all',
        shouldUnregister: false,
        defaultValues: defaultValues
    });

    const {
        reset,
        watch,
        control,
        setValue,
        setError,
        handleSubmit,
        getValues,
        formState: { errors, isSubmitting },
    } = methods;

    // const formValue = watch();

    // const genderWatcher = watch('gender');
    // const mirrageStatusWatcher = watch('marride_status');
    // const tasharofWatcher = watch('tashrof_be_sorat');
    // const hasHamragWatcher = watch('has_hamrah');
    // const hamrahCountWatcher = watch('hamrah_count');
    // const genderWatcher = useWatch({ control, name: 'gender' });
    // const mirrageStatusWatcher = useWatch({ control, name: 'marride_status' });
    // const tasharofWatcher = useWatch({ control, name: 'tashrof_be_sorat' });
    // const hasHamragWatcher = useWatch({ control, name: 'has_hamrah' });
    // const hamrahCountWatcher = useWatch({ control, name: 'hamrah_count' });
    // const companions = useWatch({ control, name: 'companions' });
    // const companions = watch("companions") || [];


    const isError = Object.keys(errors)?.length;

    // ----------------------
    const creating = (params) => axiosInstanceV2.post(api.public.travelRegister.base, params);
    const updating = (params) => axiosInstanceV2.put(`${api.public.travelRegister.base}/${travel}`, params);
    const getById = () => axiosInstanceV2.get(`${api.form.base}/${travel}`);
    const getTravelById = () => axiosInstance.get(`${api.travelRegister.base}/${travel}`);
    // ----------------------

    const location = useLocation();
    const navigate = useNavigate();

    // submit Mutate

    const onSuccessMutating = () => {
        handleSuccessModal();
    };

    // const handleSuccess = () => {
    //     if (isAuthenticated) resQuery.refetch();
    // };
    // const handleSuccessFinally = () => {
    //     if (!isAuthenticated) navigate(PATH_AUTH.login);
    // };

    // submit Mutate (END)

    const changeUrl = (params) => ({
        pathname: location.pathname,
        search: createQueryString(RemoveNullObjectValue({ ...queryString, ...params })).toString(),
    });

    const navigateUrl = (obj) => navigate(changeUrl(obj));

    const { isLoading, mutate } = useMutationCustom({
        url: beforeRegistrant ? updating : creating,
        name: `${api.public.travelRegister.base}_add_first`,
        // invalidQuery: `${Enum?.api?.base}_get_${id}_${travel}`,
        // onSuccess: onSuccessMutating,
        //  onError: onErrorMutating,
    });

    // const getTravelData = useQueryCustom({
    //     url: getTravelById,
    //     name: `${api.public.travelRegister.base}_getById_travel`,
    //     params: { id: travel },
    //     // invalidQuery: `${Enum?.api?.base}_get_${id}_${travel}`,
    // });
    // const firstFormOfTravel = useQueryCustom({
    //     url: getById,
    //     name: `${api.public.travelRegister.base}_getById_first`,
    //     params: { id: travel },
    //     // invalidQuery: `${Enum?.api?.base}_get_${id}_${travel}`,
    //     onSuccess: handleGetUserFormData,
    //     enabled: !!admin?.id
    //     //  onError: onErrorMutating,
    // });

    const handleCheckFIlters = () => {
        let filterObjectId = {};
        let filters;
        try {
            filters = JSON.parse(queryString?.filters || '');
            if (filters) {
                filters?.forEach((x) => (filterObjectId[x.input_id] = x));
            }
        } catch (error) { console.log(error) }
    }


    const onSubmit = async () => {

        const formValue = getValues();

        const toDate = new Date();

        let reqData = {
            // travel_id: travel,
            main_client_name: formValue.name?.trim() !== "" ? { value: formValue.name, label: "نام", name: "name", filterName: "main_client_name" } : undefined,
            main_client_last_name: formValue.last_name?.trim() !== "" ? { value: formValue.last_name, label: "نام خانوادگی", name: "last_name", filterName: "main_client_last_name" } : undefined,
            main_client_code_melli: formValue.codeMeli?.trim() !== "" ? { value: persianToEnglishNumber(formValue.codeMeli), label: "کد ملی", name: "codeMeli", filterName: "main_client_code_melli" } : undefined,
            main_client_phone: formValue.phone?.trim() !== "" ? { value: persianToEnglishNumber(formValue.phone), label: "تلفن همراه", name: "phone", filterName: "main_client_phone" } : undefined,
            main_client_shenasname_number: formValue.shenasname?.trim() !== "" ? { value: persianToEnglishNumber(formValue.shenasname), label: "شماره شناسنامه", name: "shenasname", filterName: "main_client_shenasname_number" } : undefined,
            main_client_father_name: formValue.father_name?.trim() !== "" ? { value: formValue.father_name, label: "پدر", name: "father_name", filterName: "main_client_father_name" } : undefined,
            main_client_birthdate_from: formValue.fromBirthDate ? { value: persianToEnglishNumber(fDateForApi(formValue.fromBirthDate)), label: "از تاریخ تولد", name: "fromBirthDate", filterName: "main_client_birthdate_from", type: "DATE", afterDelete: !formValue.toBirthDate, afterDeleteKey: "main_client_birthdate_to" } : (formValue.toBirthDate) ? { value: persianToEnglishNumber(fDateForApi(toDate.setFullYear(toDate.getFullYear() - 200))), label: "تا تاریخ تولد", name: "toBirthDate", filterName: "main_client_birthdate_to", type: "DATE", hidden: true } : undefined,
            main_client_birthdate_to: formValue.toBirthDate ? { value: persianToEnglishNumber(fDateForApi(formValue.toBirthDate)), label: "تا تاریخ تولد", name: "toBirthDate", filterName: "main_client_birthdate_to", type: "DATE", afterDelete: !formValue.fromBirthDate, afterDeleteKey: "main_client_birthdate_from" } : (formValue.fromBirthDate) ? { value: persianToEnglishNumber(fDateForApi(toDate.setFullYear(toDate.getFullYear() + 200))), label: "از تاریخ تولد", name: "fromBirthDate", filterName: "main_client_birthdate_from", type: "DATE", hidden: true } : undefined,
            main_client_gender: formValue.gender?.value ? { value: formValue.gender?.value, label: "جنسیت", name: "gender", filterName: "main_client_gender" } : undefined,
            main_client_email: formValue.email?.trim() !== "" ? { value: formValue.email, label: "پست الکترونیک", name: "email", filterName: "main_client_email" } : undefined,
            main_client_marital_status: formValue.marride_status?.value ? { value: formValue.marride_status?.value, label: "وضعیت تاهل", name: "marride_status", filterName: "main_client_marital_status" } : undefined,
            main_client_tasharof_be_soorat: formValue.tashrof_be_sorat?.value ? { value: formValue.tashrof_be_sorat?.value, label: "تشرف به صورت", name: "tashrof_be_sorat", filterName: "main_client_tasharof_be_soorat" } : undefined,
            main_client_religion: formValue.mazhab?.value ? { value: formValue.mazhab?.value, label: "مذهب", name: "mazhab", filterName: "main_client_religion" } : undefined,
            main_client_home_phone: formValue.home_phone?.trim() !== "" ? { value: persianToEnglishNumber(formValue.home_phone), label: "شماره همراه", name: "home_phone", filterName: "main_client_home_phone" } : undefined,
            main_client_passport_status: formValue.gozarnameh_status?.value ? { value: formValue.gozarnameh_status?.value, label: "وضعیت گذرنامه", name: "gozarnameh_status", filterName: "main_client_passport_status" } : undefined,
            main_client_nezam_vazife_status: formValue.duty_system?.value ? { value: formValue.duty_system?.value, label: "وضعیت نظام وظیفه", name: "duty_system", filterName: "main_client_nezam_vazife_status" } : undefined,
            main_client_need_vam: formValue.need_vam?.value ? { value: formValue.need_vam?.value, label: "متقاضی وام", name: "need_vam", filterName: "main_client_need_vam" } : undefined,
            main_client_sabeghe_tasharof: formValue.sabegh_tasharof?.value ? { value: formValue.sabegh_tasharof?.value, label: "سابقه تشرف", name: "sabegh_tasharof", filterName: "main_client_sabeghe_tasharof" } : undefined,
            main_client_marriage_date_from: formValue.marrid_date ? { value: persianToEnglishNumber(fDateForApi(formValue.marrid_date_from)), label: "از تاریخ عقد", name: "marrid_date_from", filterName: "main_client_marriage_date_from", type: "DATE", afterDelete: !formValue.marrid_date_to, afterDeleteKey: "main_client_marriage_date_to" } : (formValue.marrid_date_to) ? { value: persianToEnglishNumber(fDateForApi(toDate.setFullYear(toDate.getFullYear() - 200))), label: "از تاریخ عقد", name: "fromBirthDate", filterName: "main_client_marriage_date_from", type: "DATE", hidden: true } : undefined,
            main_client_marriage_date_to: formValue.marrid_date ? { value: persianToEnglishNumber(fDateForApi(formValue.marrid_date_to)), label: "تا تاریخ عقد", name: "marrid_date_to", filterName: "main_client_marriage_date_to", type: "DATE", afterDelete: !formValue.marrid_date_from, afterDeleteKey: "main_client_marriage_date_from" } : (formValue.marrid_date_from) ? { value: persianToEnglishNumber(fDateForApi(toDate.setFullYear(toDate.getFullYear() + 200))), label: "از تاریخ عقد", name: "fromBirthDate", filterName: "main_client_marriage_date_to", type: "DATE", hidden: true } : undefined,
            main_client_student_marriage: formValue.student_marriage?.value ? { value: formValue.student_marriage?.value, label: "ازدواج دانشجویی", name: "student_marriage", filterName: "main_client_student_marriage" } : undefined,
            main_client_has_hamrah: formValue.has_hamrah?.value ? { value: formValue.has_hamrah?.value, label: "اعزام با همراه", name: "has_hamrah", filterName: "main_client_has_hamrah" } : undefined,
            main_client_hamrah_count: formValue.hamrah_count?.value ? { value: persianToEnglishNumber(formValue.hamrah_count?.value), label: "تعداد همراه", name: "hamrah_count", filterName: "main_client_hamrah_count" } : undefined,
            main_client_university_city: formValue.provoince_university?.label ? { value: formValue.provoince_university?.label, label: "استان محل تحصیل", name: "provoince_university", filterName: "main_client_university_city" } : undefined,
            main_client_university_category: formValue.type_university?.label ? { value: formValue.type_university?.label, label: "نوع دانشگاه", name: "type_university", filterName: "main_client_university_category" } : undefined,
            main_client_university_name: formValue.university_code?.label ? { value: formValue.university_code?.label, label: "کد دانشگاه", name: "university_code", filterName: "main_client_university_name" } : undefined,
            main_client_konkoor: formValue.kunkor?.value ? { value: formValue.kunkor?.value, label: "کنکور", name: "kunkor", filterName: "main_client_konkoor" } : undefined,
            main_client_education_level: formValue.degree?.value ? { value: formValue.degree?.value, label: "مقطع تحصیلی", name: "degree", filterName: "main_client_education_level" } : undefined,
            // main_client_is_student: (formValue.tashrof_be_sorat?.value === "استاد دانشگاه" || formValue.tashrof_be_sorat?.value === "کارمند") ? "خیر" : "بله",
            main_client_hokm_kargozini: formValue?.type_of_employment?.value ? { value: formValue.type_of_employment?.value, label: "نوع حکم کارگزینی", name: "type_of_employment", filterName: "main_client_hokm_kargozini" } : undefined,
            main_client_university_level: formValue?.type_of_employment_state?.value ? { value: formValue.type_of_employment_state?.value, label: "مرتبه دانشگاهی", name: "type_of_employment_state", filterName: "main_client_university_level" } : undefined,
            main_client_type: formValue?.type?.value ? { value: formValue.type?.value, label: "نوع زائر", name: "type", filterName: "main_client_type" } : undefined,

            // hamsar
            hamsar_name: formValue.hamsar_name?.trim() !== "" ? { value: formValue.hamsar_name, label: "نام همراه", name: "hamsar_name", filterName: "hamsar_name" } : undefined,
            hamsar_last_name: formValue.hamsar_last_name?.trim() !== "" ? { value: formValue.hamsar_last_name, label: "نام خانوادگی همراه", name: "hamsar_last_name", filterName: "hamsar_last_name" } : undefined,
            hamsar_code_melli: formValue.hamsar_code_melli?.trim() !== "" ? { value: persianToEnglishNumber(formValue.hamsar_code_melli), label: "کد ملی همراه", name: "hamsar_code_melli", filterName: "hamsar_code_melli" } : undefined,
            hamsar_phone: formValue.hamsar_phone?.trim() !== "" ? { value: persianToEnglishNumber(formValue.hamsar_phone), label: "تلفن همراه همراه", name: "hamsar_phone", filterName: "hamsar_phone" } : undefined,
            hamsar_father_name: formValue.hamsar_father_name?.trim() !== "" ? { value: formValue.hamsar_father_name, label: "پدر همراه", name: "hamsar_father_name", filterName: "hamsar_father_name" } : undefined,
            // hamsar_birthdate_from: formValue.fromBirthDate ? {value : persianToEnglishNumber(fDateForApi(formValue.fromBirthDate)) , label :"از تاریخ تولد" , name : "fromBirthDate" , filterName : "hamsar_birthdate_from" , type : "DATE" , afterDelete : !formValue.toBirthDate , afterDeleteKey:"hamsar_birthdate_to" } : (formValue.toBirthDate) ? {value : persianToEnglishNumber(fDateForApi(toDate.setFullYear(toDate.getFullYear() - 200))) , label :"تا تاریخ تولد" , name : "toBirthDate" , filterName : "hamsar_birthdate_to" , type : "DATE" , hidden : true} : undefined,
            // hamsar_birthdate_to: formValue.toBirthDate ? {value : persianToEnglishNumber(fDateForApi(formValue.toBirthDate)) , label :"تا تاریخ تولد" , name : "toBirthDate" , filterName : "hamsar_birthdate_to" , type : "DATE" , afterDelete : !formValue.fromBirthDate , afterDeleteKey:"hamsar_birthdate_from"} :(formValue.fromBirthDate) ? {value : persianToEnglishNumber(fDateForApi(toDate.setFullYear(toDate.getFullYear() + 200))) , label :"از تاریخ تولد" , name : "fromBirthDate" , filterName : "hamsar_birthdate_from" , type : "DATE" , hidden : true} : undefined,
            hamsar_gender: formValue.hamsar_gender?.value ? { value: formValue.hamsar_gender?.value, label: "جنسیت همراه", name: "hamsar_gender", filterName: "hamsar_gender" } : undefined,
            hamsar_passport_status: formValue.hamsar_passport_status?.value ? { value: formValue.hamsar_passport_status?.value, label: "وضعیت گذرنامه همراه", name: "hamsar_passport_status", filterName: "hamsar_passport_status" } : undefined,
            hamsar_nezam_vazife_status: formValue.hamsar_nezam_vazife_status?.value ? { value: formValue.hamsar_nezam_vazife_status?.value, label: "وضعیت نظام وظیفه همراه", name: "hamsar_nezam_vazife_status", filterName: "hamsar_nezam_vazife_status" } : undefined,
            hamsar_need_vam: formValue.hamsar_need_vam?.value ? { value: formValue.hamsar_need_vam?.value, label: "متقاضی وام همراه", name: "hamsar_need_vam", filterName: "hamsar_need_vam" } : undefined,
            hamsar_nesbat_to_head: formValue.hamsar_nesbat_to_head?.value ? { value: formValue.hamsar_nesbat_to_head?.value, label: "نسبت با متقاضی", name: "hamsar_nesbat_to_head", filterName: "hamsar_nesbat_to_head" } : undefined,
            hamsar_student_marriage: formValue.student_marriage?.value ? { value: formValue.student_marriage?.value, label: "ازدواج دانشجویی همراه", name: "student_marriage", filterName: "hamsar_student_marriage" } : undefined,
            hamsar_university_city: formValue.hamsar_university_city?.label ? { value: formValue.hamsar_university_city?.label, label: "استان محل تحصیل همراه", name: "hamsar_university_city", filterName: "hamsar_university_city" } : undefined,
            hamsar_university_category: formValue.hamsar_university_category?.label ? { value: formValue.hamsar_university_category?.label, label: "نوع دانشگاه همراه", name: "hamsar_university_category", filterName: "hamsar_university_category" } : undefined,
            hamsar_university_name: formValue.hamsar_university_name?.label ? { value: formValue.hamsar_university_name?.label, label: "کد دانشگاه همراه", name: "hamsar_university_name", filterName: "hamsar_university_name" } : undefined,
            hamsar_konkoor: formValue.hamsar_konkoor?.value ? { value: formValue.hamsar_konkoor?.value, label: "کنکور همراه", name: "hamsar_konkoor", filterName: "hamsar_konkoor" } : undefined,
            hamsar_education_level: formValue.hamsar_education_level?.value ? { value: formValue.hamsar_education_level?.value, label: "مقطع تحصیلی همراه", name: "hamsar_education_level", filterName: "hamsar_education_level" } : undefined,
            hamsar_is_student: formValue?.hamsar_is_student?.value ? { value: formValue.hamsar_is_student?.value, label: "آیا همراه دانشجو است؟", name: "hamsar_is_student", filterName: "hamsar_is_student" } : undefined,
        }

        reqData = RemoveNullObjectValue(reqData);

        if (
            reqData?.hamsar_name ||
            reqData?.hamsar_last_name ||
            reqData?.hamsar_code_melli ||
            reqData?.hamsar_phone ||
            reqData?.hamsar_father_name ||
            reqData?.hamsar_gender ||
            reqData?.hamsar_passport_status ||
            reqData?.hamsar_nezam_vazife_status ||
            reqData?.hamsar_need_vam ||
            reqData?.hamsar_student_marriage ||
            reqData?.hamsar_university_city ||
            reqData?.hamsar_university_category ||
            reqData?.hamsar_university_name ||
            reqData?.hamsar_konkoor ||
            reqData?.hamsar_education_level ||
            reqData?.hamsar_is_student
        ) {
            // reqData.hamsar_nesbat_to_head = {
            //     value : "همس1ر",
            //     hidden : true
            // };
        } else {
            // delete reqData.hamsar_nesbat_to_head
        }


        setCustomFilterObj({ filters: reqData });
        onClose();

    }

    return (
        <>

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                    }}
                >
                    <Grid sx={{}} container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <RHFTextField
                                name={"name"}
                                label={"نام"}

                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField
                                name={"last_name"}
                                label={"نام خانوادگی"}

                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField
                                name={"shenasname"}
                                label={"شماره شناسنامه"}
                                type={"numbers"}
                                disableComma
                                withZero

                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField
                                name={"codeMeli"}
                                label={"کدملی"}
                                type={"numbers"}
                                disableComma
                                withZero

                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField
                                name={"father_name"}
                                label={"نام پدر"}

                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFDatePicker
                                name={"fromBirthDate"}
                                label={"ازتاریخ تولد"}
                                pickerProps={{
                                    maxDate: new Date(),
                                }}

                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFDatePicker
                                name={"toBirthDate"}
                                label={"تا تاریخ تولد"}
                                pickerProps={{
                                    maxDate: new Date(),
                                }}

                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFRadioGroups
                                options={GENDER_DEFAULT_OP}
                                name={"gender"}
                                label={"جنسیت"}

                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField
                                name={"email"}
                                label={"پست الکترونیک"}

                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField
                                name={"phone"}
                                label={"تلفن همراه"}

                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFSelector
                                options={
                                    [
                                        { label: "مجرد", value: "مجرد" },
                                        { label: "متاهل", value: "متاهل" },
                                    ]
                                }
                                name={"marride_status"}
                                label={"وضعیت تاهل"}
                                disableClearable={false}

                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFSelector
                                options={TasharofOptionArr}
                                name={"tashrof_be_sorat"}
                                label={"تشرف به صورت"}
                                disableClearable={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFSelector
                                options={PASSPORT_DEFAULT_OP}
                                name={"gozarnameh_status"}
                                label={"وضعیت گذرنامه"}
                                disableClearable={false}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <RHFDatePicker
                                name={"marrid_date_from"}
                                label={"ازتاریخ عقد"}
                                pickerProps={{
                                    maxDate: new Date(),
                                }}

                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <RHFDatePicker
                                name={"marrid_date_to"}
                                label={"تا تاریخ عقد"}
                                pickerProps={{
                                    maxDate: new Date(),
                                }}

                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFSelector
                                options={[
                                    { label: "بله", value: "بله" },
                                    { label: "خیر", value: "خیر" }
                                ]}
                                name={"student_marriage"}
                                label={"ازدواج دانشجویی"}
                                disableClearable={false}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <RHFSelector
                                options={DUTY_DEFAULT_OP}
                                name={"duty_system"}
                                label={"وضعیت نظام وظیفه"}
                                disableClearable={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFSelector
                                options={[
                                    { label: "تشیع", value: "تشیع" },
                                    { label: "تسنن", value: "تسنن" }
                                ]}
                                name={"mazhab"}
                                label={"مذهب"}
                                disableClearable={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFSelector
                                options={[
                                    { label: "هستم", value: "هستم" },
                                    { label: "نیستم", value: "نیستم" }
                                ]}
                                name={"need_vam"}
                                label={
                                    <>
                                        <Typography component={"span"}>متقاضی دریافت</Typography>
                                        <Typography component={"span"} sx={{ color: 'red' }}> وام</Typography>
                                    </>
                                }
                                disableClearable={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFSelector
                                options={[
                                    { label: "اعزام با دانشگاهیان", value: "اعزام با دانشگاهیان" },
                                    { label: "اعزام غیر دانشگاهی", value: "اعزام غیر دانشگاهی" },
                                    { label: "نداشته ام", value: "نداشته ام" },
                                ]}
                                name={"sabegh_tasharof"}
                                label={"سابقه تشرف"}
                                disableClearable={false}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <RHFTextField
                                name={"home_phone"}
                                label={"تلفن ثابت (به همراه کد)"}

                            />
                        </Grid>


                        <Grid item xs={12} md={6}>
                            <RHFSelector
                                options={CLIENT_TYPE_OP}
                                name={"type"}
                                label={"نوع زائر"}
                            />
                        </Grid>
                        {/* <Grid item xs={12} md={6}>
                            <RHFSelector
                                name={"passport_sending_status"}
                                label={'وضعیت تحویل گذرنامه'}
                                options={Object.values(PASSPORT_SENDING_STATUS)}
                            />
                        </Grid> */}
                        <Grid item xs={12} md={6}>
                            <RHFSelector
                                options={[
                                    { label: "بله", value: "بله" },
                                    { label: "خیر", value: "خیر" }
                                ]}
                                name={"has_hamrah"}
                                label={"اعزام با همراه"}
                                disableClearable={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFSelector
                                options={[
                                    { label: "1", value: "1" },
                                    { label: "2", value: "2" },
                                    { label: "3", value: "3" },
                                    { label: "4", value: "4" }
                                ]}
                                name={"hamrah_count"}
                                label={"تعداد همراه"}
                                disableClearable={false}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <ProvinceSelector.Element
                                name={"provoince_university"}
                                label={"استان محل تحصیل"}

                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <universityCategorySelector.Element
                                name={"type_university"}
                                label={"نوع دانشگاه"}

                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <UniversitySelector.Element
                                name={"university_code"}
                                // provinceId={getValues("provoince_university")?.value || undefined}
                                // categoryId={getValues("type_university")?.value || undefined}
                                province_name={getValues("provoince_university")?.label || undefined}
                                category_name={getValues("type_university")?.label || undefined}
                                label={"کد دانشگاه"}

                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {/* <Stack direction={"row"} gap={1} alignItems={"center"} sx={{mb:1}}>
                                            <Typography sx={{fontSize:15 , color:"#707070"}}>وضعیت شرکت در کنکور های سراسری سال جاری</Typography>
                                        </Stack> */}
                            <RHFSelector
                                options={[
                                    { label: "شرکت نکرده ام", value: "شرکت نکرده ام" },
                                    { label: "کاردانی", value: "کاردانی" },
                                    { label: "کارشناسی ارشد ناپیوسته", value: "کارشناسی ارشد ناپیوسته" },
                                    { label: "دوره دکتری", value: "دوره دکتری" },
                                    { label: "امتحان جامع علوم پایه پزشکی", value: "امتحان جامع علوم پایه پزشکی" },
                                    { label: "کاردانی به کارشناسی", value: "کاردانی به کارشناسی" },
                                ]}
                                name={"kunkor"}
                                label={"کنکور (وضعیت شرکت در کنکور های سراسری سال جاری)"}
                                disableClearable={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFSelector
                                options={[
                                    { label: "کاردانی", value: "کاردانی" },
                                    { label: "کارشناسی", value: "کارشناسی" },
                                    { label: "کارشناسی ارشد", value: "کارشناسی ارشد" },
                                    { label: "دکتری", value: "دکتری" },
                                ]}
                                name={"degree"}
                                label={"مقطع تحصیلی"}
                                disableClearable={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFSelector
                                options={[
                                    { label: "هيئت علمي رسمي تمام وقت", value: "هيئت علمي رسمي تمام وقت" },
                                    { label: "هيئت علمي نيمه وقت", value: "هيئت علمي نيمه وقت" },
                                    { label: "حق التدريس", value: "حق التدريس" },
                                    { label: "پيماني", value: "پيماني" },
                                    { label: "رسمي-آزمايشي", value: "رسمي-آزمايشي" },
                                    { label: "ساير احکام", value: "ساير احکام" },
                                ]}
                                name={"type_of_employment"}
                                label={"نوع حکم کارگزینی"}
                                disableClearable={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFSelector
                                options={[
                                    { label: "استاد", value: "استاد" },
                                    { label: "استادیار", value: "استادیار" },
                                    { label: "دانشیار", value: "دانشیار" },
                                    { label: "حق التدریس", value: "حق التدریس" },
                                    { label: "مربی", value: "مربی" },
                                ]}
                                name={"type_of_employment_state"}
                                label={"مرتبه دانشگاهی"}
                                disableClearable={false}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Box sx={{ p: '10px', backgroundColor: 'grey.main', borderRadius: '8px' }}>{'جستجو بر اساس اطلاعات همراه'}</Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField
                                name={"hamsar_name"}
                                label={"نام"}

                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField
                                name={"hamsar_last_name"}
                                label={"نام خانوادگی"}

                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField
                                name={"hamsar_code_melli"}
                                label={"کدملی"}
                                type={"numbers"}
                                disableComma
                                withZero

                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFTextField
                                name={"hamsar_father_name"}
                                label={"نام پدر"}

                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFRadioGroups
                                options={GENDER_DEFAULT_OP}
                                name={"hamsar_gender"}
                                label={"جنسیت"}

                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <RHFTextField
                                name={"hamsar_phone"}
                                label={"تلفن همراه"}

                            />
                        </Grid>

                        <Grid item xs="12" md={6}>
                            <RHFSelector
                                options={
                                    [
                                        { label: "همسر", value: "همسر" },
                                        { label: "فرزند", value: "فرزند" },
                                    ]
                                }
                                name={`hamsar_nesbat_to_head`}
                                label={"نسبت با متقاضی"}
                                disableClearable={false}
                            />
                        </Grid>


                        <Grid item xs={12} md={6}>
                            <RHFSelector
                                options={[
                                    { label: "بله", value: "بله" },
                                    { label: "خیر", value: "خیر" }
                                ]}
                                name={"hamsar_is_student"}
                                label={"آیا همراه دانشجو است؟"}
                                disableClearable={false}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <RHFSelector
                                options={[
                                    { label: "هستم", value: "هستم" },
                                    { label: "نیستم", value: "نیستم" }
                                ]}
                                name={"hamsar_need_vam"}
                                label={
                                    <>
                                        <Typography component={"span"}>متقاضی دریافت</Typography>
                                        <Typography component={"span"} sx={{ color: 'red' }}> وام</Typography>
                                    </>
                                }
                                disableClearable={false}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <RHFSelector
                                options={DUTY_DEFAULT_OP}
                                name={"hamsar_nezam_vazife_status"}
                                label={"وضعیت نظام وظیفه"}
                                disableClearable={false}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <RHFSelector
                                options={PASSPORT_DEFAULT_OP}
                                name={"hamsar_passport_status"}
                                label={"وضعیت گذرنامه"}
                                disableClearable={false}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <ProvinceSelector.Element
                                name={"hamsar_university_city"}
                                label={"استان محل تحصیل"}

                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <universityCategorySelector.Element
                                name={"hamsar_university_category"}
                                label={"نوع دانشگاه"}

                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <UniversitySelector.Element
                                name={"hamsar_university_name"}
                                // provinceId={getValues("provoince_university")?.value || undefined}
                                // categoryId={getValues("type_university")?.value || undefined}
                                province_name={getValues("hamsar_university_city")?.label || undefined}
                                category_name={getValues("hamsar_university_category")?.label || undefined}
                                label={"کد دانشگاه"}

                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {/* <Stack direction={"row"} gap={1} alignItems={"center"} sx={{mb:1}}>
                                            <Typography sx={{fontSize:15 , color:"#707070"}}>وضعیت شرکت در کنکور های سراسری سال جاری</Typography>
                                        </Stack> */}
                            <RHFSelector
                                options={[
                                    { label: "شرکت نکرده ام", value: "شرکت نکرده ام" },
                                    { label: "کاردانی", value: "کاردانی" },
                                    { label: "کارشناسی ارشد ناپیوسته", value: "کارشناسی ارشد ناپیوسته" },
                                    { label: "دوره دکتری", value: "دوره دکتری" },
                                    { label: "امتحان جامع علوم پایه پزشکی", value: "امتحان جامع علوم پایه پزشکی" },
                                    { label: "کاردانی به کارشناسی", value: "کاردانی به کارشناسی" },
                                ]}
                                name={"hamsar_konkoor"}
                                label={"کنکور (وضعیت شرکت در کنکور های سراسری سال جاری)"}
                                disableClearable={false}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <RHFSelector
                                options={[
                                    { label: "کاردانی", value: "کاردانی" },
                                    { label: "کارشناسی", value: "کارشناسی" },
                                    { label: "کارشناسی ارشد", value: "کارشناسی ارشد" },
                                    { label: "دکتری", value: "دکتری" },
                                ]}
                                name={"hamsar_education_level"}
                                label={"مقطع تحصیلی"}
                                disableClearable={false}
                            />
                        </Grid>



                    </Grid>

                    <Box
                        sx={{
                            display: 'flex',
                            mt: 3,
                            gap: 3,
                            justifyContent: 'center',
                        }}
                    >
                        <LoadingButton
                            // type="click"
                            variant="outlined"
                            color="error"
                            onClick={() => {
                                // navigateUrl({ filters: undefined });
                                setCustomFilterObj({ filters: {} })
                                onClose();
                            }}
                        >
                            {'پاک کردن'}
                        </LoadingButton>
                        <LoadingButton
                            // type="click"
                            variant="outlined"
                            color="success"
                            onClick={onClose}
                        >
                            {'بستن'}
                        </LoadingButton>

                        <LoadingButton type="submit" variant="contained" color={'success'}>
                            {'انجام گزارش'}
                        </LoadingButton>
                    </Box>

                </Box>

            </FormProvider>
        </>
    );
}

export default StaticFilterForm;