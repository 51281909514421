import {
  Box,
  Grid,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { RHFCheckbox } from '../../../../components/hook-form/RHFCheckbox';
import RowItem from './RowItem';

const DashboardPermissions = ({ admin, client, basename, ...props }) => {
  // const { watch, setValue } = useFormContext();

  // const allSupportedName = ['create', 'read', 'update', 'delete', 'confirm'];
  // // ------------------------------------------------------
  // const WAdminCreate = watch(basename + '.' + admin?.object?.create?.id?.toString());
  // const WAdminRead = watch(basename + '.' + admin?.object?.read?.id?.toString());
  // const WAdminUpdate = watch(basename + '.' + admin?.object?.update?.id?.toString());
  // const WAdminDelete = watch(basename + '.' + admin?.object?.delete?.id?.toString());
  // const WAdminConfirm = watch(basename + '.' + admin?.object?.confirm?.id?.toString());
  // const allAdminConfirmed = WAdminCreate && WAdminRead && WAdminUpdate && WAdminUpdate && WAdminDelete && WAdminConfirm;
  // // ------------------------------------------------------
  // const WClientCreate = watch(basename + '.' + client?.object?.create?.id?.toString());
  // const WClientRead = watch(basename + '.' + client?.object?.read?.id?.toString());
  // const WClientUpdate = watch(basename + '.' + client?.object?.update?.id?.toString());
  // const WClientDelete = watch(basename + '.' + client?.object?.delete?.id?.toString());
  // const WClientConfirm = watch(basename + '.' + client?.object?.confirm?.id?.toString());
  // const allClientConfirmed =
  //   WClientCreate && WClientRead && WClientUpdate && WClientUpdate && WClientDelete && WClientConfirm;
  // // ------------------------------------------------------
  // console.log({ admin, allAdminConfirmed });
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: 150 }}>{'داشبورد'}</TableCell>
          <TableCell sx={{ width: 50 }}>{'همه'}</TableCell>
          <TableCell sx={{ width: 50 }}>{'ایجاد کردن'}</TableCell>
          <TableCell sx={{ width: 50 }}>{'دیدن'}</TableCell>
          <TableCell sx={{ width: 50 }}>{'ویرایش'}</TableCell>
          <TableCell sx={{ width: 50 }}>{'حذف'}</TableCell>
          {/* <TableCell sx={{ width: 50 }}>{'تایید'}</TableCell> */}
        </TableRow>
      </TableHead>
      {admin?.object ? (
        <TableBody>
          <RowItem x={admin} basename={basename} {...props} />
        </TableBody>
      ) : (
        ''
      )}

      {client?.object ? (
        <TableBody>
          <RowItem x={client} basename={basename} {...props} />
        </TableBody>
      ) : (
        ''
      )}
    </Table>
  );
};

export default DashboardPermissions;
